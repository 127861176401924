import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveMenu,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { notifyError, notifyWarning } from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { DatePicker } from "antd";
import moment from "moment";
import { TbUserCheck } from "react-icons/tb";
const ChartTopLeft = () => {
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();

  const getData = async () => {
    setLoading(true);
    setDataSource([]);
    try {
      let { data } = await api.get(
        `${API_URL}api/v1/reports/client-registrations-report`,
        {
          reg_date_from: value
            ? value[0].format("YYYY-MM-DD")
            : moment().add(-1, "M").format("YYYY-MM-DD"),
          reg_date_to: value
            ? value[1].format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        }
      );

      let date1 = value
        ? moment(new Date(value[0].format("YYYY-MM-DD"))).valueOf()
        : moment().add(-1, "M").valueOf();
      let date2 = value
        ? moment(new Date(value[1].format("YYYY-MM-DD"))).valueOf()
        : moment().valueOf();
      // To calculate the time difference of two dates
      let Difference_In_Time = date2 - date1;

      let Difference_In_Days = Math.round(
        Difference_In_Time / (1000 * 3600 * 24)
      );

      if (Difference_In_Days <= 60) {
        let firstDate = value
          ? moment(new Date(value[0].format("YYYY-MM-DD")))
              .format("YYYY-MM-DD")
              .slice(8, 10)
          : moment().add(-1, "M").format("YYYY-MM-DD").slice(8, 10);
        let yearMonth = value
          ? moment(new Date(value[0].format("YYYY-MM-DD")))
              .format("YYYY-MM-DD")
              .slice(0, 8)
          : moment().add(-1, "M").format("YYYY-MM-DD").slice(0, 8);
        let nextMonth = value
          ? moment(new Date(value[0].format("YYYY-MM-DD")))
              .add(1, "M")
              .format("YYYY-MM-DD")
              .slice(0, 8)
          : moment().format("YYYY-MM-DD").slice(0, 8);
        let nextTwoMonth = value
          ? moment(new Date(value[0].format("YYYY-MM-DD")))
              .add(2, "M")
              .format("YYYY-MM-DD")
              .slice(0, 8)
          : moment().format("YYYY-MM-DD").slice(0, 8);
        let fakeDataDate = [];
        let countDayInMonth = value
          ? moment(new Date(value[0].format("YYYY-MM-DD"))).daysInMonth()
          : moment().add(-1, "M").daysInMonth();

        let countDayNextOneMonth = value
          ? moment(new Date(value[0].format("YYYY-MM-DD")))
              .add(1, "M")
              .daysInMonth()
          : moment().daysInMonth();

        let countDayNextTwoMonth = value
          ? moment(new Date(value[0].format("YYYY-MM-DD")))
              .add(2, "M")
              .daysInMonth()
          : moment().add(1, "M").daysInMonth();

        let countTotal = 0;
        for (let i = 0; i < Difference_In_Days; i++) {
          let date = Number(firstDate) + i;
          if (date <= countDayInMonth) {
            if (Number(date) < 10) {
              fakeDataDate.push({
                amount: 0,
                reg_date: yearMonth + "0" + Number(date),
              });
            } else {
              fakeDataDate.push({
                amount: 0,
                reg_date: yearMonth + Number(date),
              });
            }

            countTotal++;
          } else {
            break;
          }
        }
        if (countTotal <= Difference_In_Days) {
          let countNextMonth = countTotal;
          for (let i = 1; i <= Difference_In_Days - countNextMonth + 1; i++) {
            if (i <= countDayNextOneMonth) {
              if (i < 10) {
                fakeDataDate.push({ amount: 0, reg_date: nextMonth + "0" + i });
              } else {
                fakeDataDate.push({ amount: 0, reg_date: nextMonth + i });
              }
              countTotal++;
            } else {
              break;
            }
          }
        }

        if (countTotal <= Difference_In_Days) {
          let countNextTwoMonth = countTotal;

          for (
            let i = 1;
            i <= Difference_In_Days - countNextTwoMonth + 1;
            i++
          ) {
            if (i <= countDayNextTwoMonth) {
              if (i < 10) {
                fakeDataDate.push({
                  amount: 0,
                  reg_date: nextTwoMonth + "0" + i,
                });
              } else {
                fakeDataDate.push({ amount: 0, reg_date: nextTwoMonth + i });
              }
              countTotal++;
            } else {
              break;
            }
          }
        }
        let newfakeDate = [...data.client_registrations];

        for (let i = 0; i < fakeDataDate.length; i++) {
          let check = newfakeDate.find(
            (x) => x.reg_date === fakeDataDate[i].reg_date
          );
          if (check) {
            fakeDataDate[i].amount = check?.amount;
          } else {
            if (moment().format("YYYY-MM-DD") === fakeDataDate[i].reg_date) {
              fakeDataDate = fakeDataDate.filter(
                (x) => x.reg_date !== moment().format("YYYY-MM-DD")
              );
            }
          }
        }
        setDataSource(
          fakeDataDate.map((x) => {
            return [moment(x.reg_date, "YYYY-MM-DD").add(7, "h"), x.amount];
          })
        );
      } else {
        let fakeDate = [...data.client_registrations].map((x) => {
          return [moment(x.reg_date, "YYYY-MM-DD").add(7, "h"), x.amount];
        });
        setDataSource(fakeDate);
      }

      setLoading(false);

      setTotal(data.totals.amount);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [value]);
  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return current && current > moment().endOf("day");
    }
    const tooLate =
      dates[0] &&
      (current.diff(dates[0], "days") > 365 || current > moment().endOf("day"));
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 365;
    return tooEarly || tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  return (
    <div style={{ background: "#fff", padding: "8px", borderRadius: "8px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            marginLeft: "16px",
          }}
        >
          <TbUserCheck size={24} />
          &nbsp;Số lượng khách đăng kí mới
        </p>
        <DatePicker.RangePicker
          style={{ width: "240px" }}
          value={hackValue || value}
          disabledDate={disabledDate}
          placeholder={[
            moment().add(-1, "M").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ]}
          onCalendarChange={(val) => setDates(val)}
          onChange={(val) => setValue(val)}
          onOpenChange={onOpenChange}
          inputReadOnly
        />
      </div>
      <Chart
        type="area"
        width="100%"
        height={350}
        series={[{ name: "Khách mới:", data: dataSource }]}
        options={{
          noData: {
            text: loading ? "Đang tải dữ liệu" : "Không có dữ liệu",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "14px",
              fontFamily: undefined,
            },
          },
          title: {
            align: "center",
            text: total + " khách",
            style: {
              fontSize: "16px",
            },
          },
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: false,
            },
            toolbar: {
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                customIcons: [],
              },
            },
            stroke: {
              show: true,

              width: 1,
            },
          },
          stroke: {
            show: true,

            width: 1,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
            style: "hollow",
          },
          xaxis: {
            type: "datetime",
            labels: {
              format: "dd/MM",
            },
          },

          tooltip: {
            x: {
              format: "dd/MM/yyyy",
            },
            y: {
              formatter: undefined,
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },
          fill: {
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100],
            },
          },
          selection: "one_month",
        }}
      />
    </div>
  );
};

export default ChartTopLeft;
