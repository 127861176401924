import { Button, Form, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { API_URL } from "../../services/api/config";
import { api } from "../../services/api/api.index.js";
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiSolidError } from "react-icons/bi";
import { notifyError, notifySuccess } from "../../components/notification";
import OverlaySpinner from "../../components/overlaySpinner/OverlaySpinner";
import {
localGetMenu,
  localRemoveMenu,
  localGetRefreshToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../utils/localStorage";
import axios from "axios";
import CheckCustomer from "../checkCustomer/CheckCustomer";
import CheckTradeID from "../checkTradeID/CheckTradeID";
import CheckDiscount from "../checkDiscount/CheckDiscount";
import { UserContext } from "../../context/context.jsx";
const Partner = () => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(UserContext);

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find(
      (x) => x?.fe_route === "/partner-affiliation"
    );
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);

  return (
    pageMenu.list && (
      <div className="userSystem">
        <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
        <div
          style={{
            display: "flex",
            width: "100%",
            borderBottom: "1px solid rgb(117, 218, 239)",
            flexWrap: "wrap",
            fontSize: "14px",
          }}
        >
          <div
            style={
              tab === 1
                ? {
                    transform: "translateY(0.5px)",
                    height: "32px",
                    border: "solid 1px rgb(117, 218, 239)",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 4px",
                    borderBottom: "solid 1px #fff",
                  }
                : { padding: "2px 4px" }
            }
          >
            <div
              style={
                tab !== 1
                  ? {
                      border: "1px solid rgb(189,189,189)",
                      background: "rgb(243,243,243)",
                      borderRadius: "3px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 8px",
                      cursor: "pointer",
                    }
                  : { padding: "0 8px" }
              }
              onClick={() => {
                setTab(1);
              }}
            >
              Kiểm tra phân bổ email
            </div>
          </div>
          <div
            style={
              tab === 2
                ? {
                    transform: "translateY(0.5px)",
                    height: "32px",
                    border: "solid 1px rgb(117, 218, 239)",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 4px",
                    borderBottom: "solid 1px #fff",
                  }
                : { padding: "2px 4px" }
            }
          >
            <div
              style={
                tab !== 2
                  ? {
                      border: "1px solid rgb(189,189,189)",
                      background: "rgb(243,243,243)",
                      borderRadius: "3px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 8px",
                      cursor: "pointer",
                    }
                  : { padding: "0 8px" }
              }
              onClick={() => {
                setTab(2);
              }}
            >
              Kiểm tra phân bổ tài khoản giao dịch
            </div>
          </div>
          <div
            style={
              tab === 3
                ? {
                    transform: "translateY(0.5px)",
                    height: "32px",
                    border: "solid 1px rgb(117, 218, 239)",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 4px",
                    borderBottom: "solid 1px #fff",
                  }
                : { padding: "2px 4px" }
            }
          >
            <div
              style={
                tab !== 3
                  ? {
                      border: "1px solid rgb(189,189,189)",
                      background: "rgb(243,243,243)",
                      borderRadius: "3px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 8px",
                      cursor: "pointer",
                    }
                  : { padding: "0 8px" }
              }
              onClick={() => {
                setTab(3);
              }}
            >
              Kiểm tra % chiết khấu hoa hồng cho khách
            </div>
          </div>
        </div>
        {tab === 1 && <CheckCustomer />}
        {tab === 2 && <CheckTradeID />}
        {tab === 3 && <CheckDiscount />}
      </div>
    )
  );
};

export default Partner;
