import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveMenu,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { notifyError, notifyWarning } from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { DatePicker } from "antd";
import moment from "moment";
import { TbNetwork } from "react-icons/tb";
const ChartTopLeft = () => {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [total, setTotal] = useState([0, 0]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState(null);

  const getData = async () => {
    setLoading(true);
    setData1([]);
    setData2([]);

    try {
      let { data } = await api.get(`${API_URL}api/v1/reports/summary`, {
        from_date: value
          ? value[0].format("YYYY-MM-DD")
          : moment().add(-1, "M").format("YYYY-MM-DD"),
        to_date: value
          ? value[1].format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      });
      let fakeData1 = [...data].map((x) => {
        return Number(x.total_client_form_answer?.toFixed(0)).toFixed(0);
      });
      let fakeData2 = [...data].map((x) => {
        return Number(x.total_client_visit_page?.toFixed(0)).toFixed(0);
      });
      let fakeData3 = [...data].map((x) => {
        let myDate = x.date;
        myDate = myDate.split("-");
        let newDate = new Date(
          parseInt(myDate[2]),
          parseInt(myDate[1]) - 1,
          parseInt(myDate[0])
        );
        newDate.setHours(newDate.getHours() + 7);
        return newDate.getTime();
      });
      setLoading(false);
      let fakeTotal1 = 0;
      for (let i = 0; i < fakeData1.length; i++) {
        fakeTotal1 = Number(fakeTotal1) + Number(fakeData1[i]);
      }
      let fakeTotal2 = 0;
      for (let i = 0; i < fakeData2.length; i++) {
        fakeTotal2 = Number(fakeTotal2) + Number(fakeData2[i]);
      }
      setLoading(false);
      setTotal([fakeTotal1, fakeTotal2]);
      setData1(fakeData1);
      setData2(fakeData2);
      setDateData(fakeData3);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [value]);

  const disabledDate = (current) => {
    return (
      current &&
      (current < moment().add(-365, "d").endOf("day") ||
        current > moment().endOf("day"))
    );
  };

  return (
    <div style={{ background: "#fff", padding: "8px", borderRadius: "8px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            marginLeft: "16px",
          }}
        >
          <TbNetwork size={24} />
          &nbsp;Số lượt truy cập
        </p>
        <DatePicker.RangePicker
          style={{ width: "240px" }}
          value={value}
          placeholder={[
            moment().add(-1, "M").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ]}
          onChange={(val) => {
            setValue(val);
          }}
          changeOnBlur
        />
      </div>
      <Chart
        type="area"
        width="100%"
        height={350}
        series={[
          {
            name: "Lượt truy cập",
            data: data2,
          },
          {
            name: "Lượt nhập form",
            data: data1,
          },
        ]}
        options={{
          noData: {
            text: loading ? "Đang tải dữ liệu" : "Không có dữ liệu",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "14px",
              fontFamily: undefined,
            },
          },
          title: {
            align: "center",
            text: total[1] + " lượt truy cập - " + total[0] + " lượt nhập form",
            style: {
              fontSize: "16px",
            },
          },
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: false,
            },
            toolbar: {
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                customIcons: [],
              },
            },
            stroke: {
              show: true,

              width: 1,
            },
          },
          stroke: {
            show: true,

            width: 1,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
            style: "hollow",
          },
          xaxis: {
            type: "datetime",
            categories: dateData,
            labels: {
              format: "dd/MM",
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yyyy",
            },
            y: {
              formatter: undefined,
              title: {
                formatter: (seriesName) => seriesName + ":",
              },
            },
          },
          fill: {
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100],
            },
          },
        }}
      />
    </div>
  );
};

export default ChartTopLeft;
