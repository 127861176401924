import {
  DatePicker,
  Button,
  Form,
  Input,
  Switch,
  Checkbox,
  Table,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { columnsData } from "./data";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import dayjs from "dayjs";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OverlaySpinner from "../../../components/overlaySpinner/OverlaySpinner";
import {
  localGetMenu,
  localRemoveMenu,
  localGetRefreshToken,
  localSaveRefreshToken,
  localSaveToken,
  localRemoveToken,
  localRemoveRefreshToken,
} from "../../../utils/localStorage";
import axios from "axios";
import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { geneUniqueKey } from "../../../utils/helpers/functions/textUtils";
import { UserContext } from "../../../context/context";
import moment from "moment";
const { RangePicker } = DatePicker;
const CreateForm = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState("");
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const [groups, setGroups] = useState([]);

  let navigate = useNavigate();

  const [items, setItems] = useState([]);

  // const getItems = async () => {
  //   try {
  //     let { data } = await api.get(`${API_URL}api/v1/forms/items`, {});
  //     setItems(data);
  //     getData();
  //   } catch (e) {
  //     notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
  //   }
  // };

  const getData = async () => {
    setLoading(true);

    try {
      let { data } = await api.get(`${API_URL}api/v1/forms/${params.id}`, {});
      form.setFieldsValue({
        name: data?.name,
        code: data?.code,
        utm_source: data?.utm_source,

        status: data?.status === "ACTIVE" ? true : false,
        date: data?.date,
        groups: data?.groups_info.map((x) => {
          return x?._id;
        }),
        end_form_answer_at: dayjs(data?.end_form_answer_at),
      });

      setDataSource(data?.description);

      let fakeData = [...data?.items].map((x, index) => {
        return { ...x, key: index + 1 };
      });
      setItems(fakeData);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishFormCreate = async (value) => {
    setLoading(true);

    try {
      let { data } = await api.put(`${API_URL}api/v1/forms/${params.id}`, {
        description: editorRef.current.getContent(),
        name: value?.name,
        code: value?.code,
        status: value?.status ? "ACTIVE" : "INACTIVE",
        items: items,
        utm_source: value?.utm_source,
        groups: value?.groups,
        end_form_answer_at: value?.end_form_answer_at.format("YYYY-MM-DD"),
      });
      notifySuccess("Cập nhật form thành công");
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormCreate();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const getGroup = async () => {
    try {
      let { data } = await api.get(
        `${API_URL}api/v1/groups?exclude_group_form=true`,
        {}
      );
      setGroups(
        data.map((x) => {
          return { value: x?.id, label: x?.name };
        })
      );
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getGroup();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const { state, dispatch } = useContext(UserContext);

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/forms");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_DETAIL"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, details: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);

  useEffect(() => {
    pageMenu.details && getData();
  }, [pageMenu]);
  useEffect(() => {
    pageMenu.details && getGroup();
  }, [pageMenu]);
  const editorRef = useRef(null);

  const handleChange = (type, code, value, child_id, child_type) => {
    if (type === 9) {
      let fakeData = [...items].map((x) =>
        x.code === code ? { ...x, code: value, name: value } : x
      );
      setItems(fakeData);
    }
    if (type === 1) {
      let fakeData = [...items].map((x) =>
        x.code === code ? { ...x, label: value } : x
      );
      setItems(fakeData);
    }
    if (type === 2) {
      let fakeData = [...items].map((x) =>
        x.code === code ? { ...x, type: value } : x
      );
      setItems(fakeData);
    }
    if (type === 3) {
      let fakeData = [...items].map((x) =>
        x.code === code ? { ...x, required: value } : x
      );
      setItems(fakeData);
    }
    if (type === 4) {
      let fakeData = [...items].map((x) =>
        x.code === code ? { ...x, status: value ? "ACTIVE" : "INACTIVE" } : x
      );
      setItems(fakeData);
    }
    if (type === 5) {
      let fakeData = [...items].map((x) =>
        x.code === code
          ? {
              ...x,
              options: [
                ...x.options,
                { label: "", value: "", child_id: geneUniqueKey() },
              ],
            }
          : x
      );
      setItems(fakeData);
    }
    if (type === 6) {
      let fakeData = [...items].map((x) =>
        x.code === code
          ? {
              ...x,
              options: [...x.options].map((y) =>
                y?.child_id === child_id
                  ? child_type === 1
                    ? { ...y, label: value }
                    : { ...y, value: value }
                  : y
              ),
            }
          : x
      );

      setItems(fakeData);
    }
    if (type === 7) {
      let fakeData = [...items].map((x) =>
        x.code === code
          ? {
              ...x,
              options: [...x.options].filter((y) => y?.child_id !== child_id),
            }
          : x
      );
      setItems(fakeData);
    }
    if (type === 8) {
      let fakeData = [...items].filter((x) => x.code !== code);

      setItems(fakeData);
    }
  };

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 99,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  className="haveDrag"
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setItems((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  // const addField = () => {
  //   setItems((previous) => [
  //     ...previous,
  //     {
  //       id: geneUniqueKey(),
  //       label: "",
  //       required: false,
  //       status: "INACTIVE",
  //       type: "text",
  //       code: "",
  //       name: "",
  //       key: previous.length + 2,
  //       options: [],
  //     },
  //   ]);
  // };

  return (
    pageMenu.details && (
      <Form
        form={form}
        onFinish={handleFinishFormCreate}
        layout="vertical"
        className="formCreate"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
        <div style={{ width: "calc(28% - 24px)" }}>
          <Form.Item
            name="name"
            label="Tên biểu mẫu:"
            required
            style={{ marginBottom: "12px" }}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="code"
            label="Mã code:"
            required
            style={{ marginBottom: "12px" }}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="utm_source"
            label="Utm source:"
            required
            style={{ marginBottom: "12px" }}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="groups"
            label="Nhóm"
            style={{ marginBottom: "12px" }}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              options={groups}
              placeholder="Chọn nhóm"
            />
          </Form.Item>
          <Form.Item
            name="end_form_answer_at"
            label="Ngày chốt doanh thu"
            style={{ marginBottom: "12px" }}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="status"
            label="Trạng thái hoạt động:"
            style={{ marginBottom: "12px" }}
          >
            <Switch />
          </Form.Item>
        </div>

        <div style={{ width: "72%" }}>
          <div style={{ marginBottom: "12px" }}>
            <p style={{ fontSize: "14px", marginBottom: "4px" }}>Nội dung:</p>
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={dataSource}
              apiKey="j3su5e7yfilsrpsbi8u6m0wjdipy9i5lp9wopyvoqm1b1k6v"
              init={{
                height: 300,
                menubar: true,
                plugins:
                  "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
                menubar: "file edit view insert format tools table help",
                toolbar:
                  "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",

                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              // rowKey array
              items={items.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                bordered
                className="newpadding"
                components={{
                  body: {
                    row: Row,
                  },
                }}
                rowKey="key"
                columns={columnsData({ handleChange })}
                dataSource={items}
                footer={false}
                // showHeader={false}
                pagination={false}
              />
            </SortableContext>
          </DndContext>
          {/* <Button style={{ width: "100%" }} onClick={() => addField()}>
          Thêm field
        </Button> */}
          {pageMenu.update && (
            <div
              className="checkCustomer__content__submit"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                style={{ width: "100%", marginBottom: "24px" }}
                onClick={() => form.submit()}
              >
                Cập nhật biểu mẫu
              </Button>
            </div>
          )}
        </div>
      </Form>
    )
  );
};

export default CreateForm;
