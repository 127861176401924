import { DatePicker, Button, Form, Input, Select, Table, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { columnsData, columnsDataModal } from "./data";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { AiOutlineSearch, AiOutlineReload } from "react-icons/ai";
import moment from "moment";
import {
  localGetMenu,
  localRemoveMenu,
  localGetRefreshToken,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate, useParams } from "react-router-dom";
import OverlaySpinner from "../../../components/overlaySpinner/OverlaySpinner";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import PanigationAntStyled from "../../../components/layout/PanigationAntStyled";
import { newValidate } from "../../../utils/helpers/functions/textUtils.js";
import { UserContext } from "../../../context/context.jsx";
const { RangePicker } = DatePicker;
const ClientDetails = () => {
  const [dataSource, setDataSource] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const [formSearchModal] = Form.useForm();
  const params = useParams();
  const [modalId, setModalId] = useState(undefined);
  const [dataModal, setDataModal] = useState(undefined);
  const [supports, setSupports] = useState(false);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [formCreate] = Form.useForm();
  const [subFilter, setSubFilter] = useState({
    page: 1,
    limit: 10,
    from_date: undefined,
    to_date: undefined,
  });
  const [extraData, setExtraData] = useState({
    total_volumn_lots: 0,
    total_reward_usd: 0,
  });
  const [totalSub, setTotalSub] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    q: undefined,
    form_date: undefined,
    to_date: undefined,
    page: 1,
    limit: 10,
  });
  const getData = async () => {
    setLoading(true);
    try {
      let { data } = await api.get(`${API_URL}api/v1/clients/${params.id}`, {});
      // setDataSource(data?.data);
      // setTotal(data?.metadata?.totalItems);
      let fakeGroups = [...data.groups_info].map((x) => {
        return x?._id;
      });
      form.setFieldsValue({
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        gender: data?.gender,
        dob: data?.dob ? Number(data?.dob) : undefined,
        groups: fakeGroups,
        zalo_phone: data?.zalo_phone,
        zalo_name: data?.zalo_name,
        telegram_username: data?.telegram_username,
        note: data?.note,
        supporter_note: data?.supporter_note,
        supporter: data?.supporter_info?.id,
        last_trade_client_account: data?.last_trade_client_account
          ? data?.last_trade_client_account
          : "Không có",
        last_trade_at: data?.last_trade_at
          ? moment(data?.last_trade_at).format("DD-MM-YYYY")
          : "-",
        reward_usd: data?.reward_usd,
        count_trade_account: data?.count_trade_account,
        volumn_lots: data?.volumn_lots,
        trade_account_for_contest: data?.trade_account_for_contest,
        trade_app: data?.trade_app,
        trade_server: data?.trade_server,
        passview: data?.passview,
      });
      formSearchModal.setFieldsValue({
        email: data?.email,
      });
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getDataTrade = async () => {
    setLoading(true);
    try {
      let data = await api.get(
        `${API_URL}api/v1/clients/${params.id}/trade-accounts`,
        { ...filter }
      );
      setDataSource(data?.data);
      setLoading(false);
      setTotalAccounts(data?.metadata?.totalItems);
      setExtraData(data?.extraData?.summary);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishFormSearch = (e) => {
    setFilter({
      client_account: e?.q,
      from_date: e?.date ? e?.date[0]?.format("YYYY-MM-DD") : undefined,
      to_date: e?.date ? e?.date[1]?.format("YYYY-MM-DD") : undefined,
      page: 1,
      limit: 10,
    });
  };

  const onChangePagingAccount = (page, size) => {
    setFilter({
      ...filter,
      page: page,
      limit: size,
    });
  };

  const onChangePagingSub = async (page, size) => {
    setSubFilter({
      ...subFilter,
      page: page,
      limit: size,
    });
    try {
      let data = await api.get(
        `${API_URL}api/v1/trade-accounts/${modalId}/histories`,
        {
          page: page,
          limit: size,
          from_date: subFilter.from_date,
          to_date: subFilter?.to_date,
        }
      );
      setDataModal(data?.data);
      setLoading(false);
      setTotalSub(data?.metadata?.totalItems);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleFinishFormCreate = async (value) => {
    setLoading(true);
    try {
      let { data } = await api.put(`${API_URL}api/v1/clients/${params.id}`, {
        name: value?.name,
        phone: value?.phone?.trim(),
        gender: value?.gender,
        dob: value?.dob ? Number(value?.dob) : undefined,
        groups: value?.groups,
        zalo_phone: value?.zalo_phon?.trim(),
        zalo_name: value?.zalo_name,
        telegram_username: value?.telegram_username,
        note: value?.note,
        supporter: value?.supporter,
        trade_account_for_contest: value?.trade_account_for_contest,
        passview: value?.passview,
        trade_app: value?.trade_app,
        trade_server: value?.trade_server,
      });
      setOpenModal(false);
      setLoading(false);
      formCreate.resetFields();
      notifySuccess("Cập nhật người dùng thành công!");
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormCreate(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getSupport = async () => {
    setLoading(true);
    try {
      let { data } = await api.get(`${API_URL}api/v1/users`, {
        status: "ACTIVE",
      });

      let groupsFake = [];
      groupsFake = [...data].map((x) => {
        return { label: x.name, value: x.id };
      });
      setSupports(groupsFake);
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getDataGroups = async () => {
    setLoading(true);
    try {
      let { data } = await api.get(`${API_URL}api/v1/groups`, {
        status: "ACTIVE",
      });
      let groupsFake = [];
      groupsFake = [...data].map((x) => {
        return { label: x.name, value: x.id };
      });
      setGroups(groupsFake);
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleOpenModal = async (record) => {
    setModalId(record?.client_account);
    setOpenModal(true);
    formSearchModal.setFieldsValue({
      client_account: record?.client_account,
      email: form.getFieldValue("email"),
    });
    try {
      let data = await api.get(
        `${API_URL}api/v1/trade-accounts/${record?.client_account}/histories`,
        {
          page: 1,
          limit: 10,
        }
      );
      setDataModal(data?.data);
      setTotalSub(data?.metadata?.totalItems);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishFormSearchModal = async (value) => {
    setLoading(true);
    setSubFilter({
      ...subFilter,
      page: 1,
      from_date: value?.date ? value?.date[0].format("YYYY-MM-DD") : undefined,
      to_date: value?.date ? value?.date[1].format("YYYY-MM-DD") : undefined,
    });
    try {
      let data = await api.get(
        `${API_URL}api/v1/trade-accounts/${modalId}/histories`,
        {
          page: 1,
          limit: 10,
          from_date: value?.date
            ? value?.date[0].format("YYYY-MM-DD")
            : undefined,
          to_date: value?.date
            ? value?.date[1].format("YYYY-MM-DD")
            : undefined,
        }
      );
      setDataModal(data?.data);
      setLoading(false);
      setTotalSub(data?.metadata?.totalItems);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const { state, dispatch } = useContext(UserContext);

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/clients");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_DETAIL"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, details: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);

  useEffect(() => {
    pageMenu.details && getData();
    window.scrollTo(0, 0);
  }, [pageMenu]);
  useEffect(() => {
    pageMenu.details && getDataTrade();
  }, [pageMenu, filter]);
  useEffect(() => {
    pageMenu.details && getDataGroups();
  }, [pageMenu]);

  useEffect(() => {
    pageMenu.details && getSupport();
  }, [pageMenu]);
  return (
    pageMenu.details && (
      <>
        <OverlaySpinner open={loading} text />
        <Modal
          open={openModal}
          onCancel={(e) => {
            setOpenModal(false);
            formSearchModal.resetFields();
            setSubFilter({ page: 1, limit: 10 });
          }}
          footer={null}
          centered
          width={720}
        >
          <Form
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
            layout="vertical"
            form={formSearchModal}
            onFinish={handleFinishFormSearchModal}
          >
            <Form.Item
              name="email"
              label="Email"
              style={{ width: "calc((100% - 24px) /2)" }}
            >
              <Input disabled style={{ color: "#000" }} />
            </Form.Item>
            <Form.Item
              name="client_account"
              label="Tài khoản giao dịch"
              style={{ width: "calc((100% - 24px) /2)" }}
            >
              <Input disabled style={{ color: "#000" }} />
            </Form.Item>
            <Form.Item
              name="date"
              label="Thời gian giao dịch"
              style={{ width: "540px", marginTop: "-8px" }}
            >
              <RangePicker />
            </Form.Item>
            <Button
              onClick={() => formSearchModal.submit()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <AiOutlineSearch size={20} />
              &nbsp;Tìm kiếm
            </Button>
          </Form>
          <Table
            bordered
            className="newpadding"
            rowKey="_id"
            columns={columnsDataModal({})}
            dataSource={dataModal}
            pagination={false}
          />
          <PanigationAntStyled
            style={{ padding: "0 0 24px 0" }}
            current={subFilter.page}
            pageSize={subFilter.limit}
            showSizeChanger
            onChange={onChangePagingSub}
            showTotal={() => `Tổng ${totalSub}`}
            total={totalSub}
          />
        </Modal>
        <Form
          className="clients-details"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "60px",
            padding: "16px",
          }}
          layout="vertical"
          form={form}
          onFinish={handleFinishFormCreate}
        >
          <div style={{ width: "calc(75% - 16px)" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "24px" }}>Nhân viên chăm sóc:</div>
              <Form.Item
                name="supporter"
                style={{ width: "240px", margin: "0" }}
              >
                <Select allowClear options={supports} placeholder="Tất cả" />
              </Form.Item>
            </div>
            <h4 style={{ margin: "16px 0" }}>Thông tin giao dịch</h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Form.Item
                name="volumn_lots"
                label="Tổng khối lượng giao dịch (lots)"
                style={{ width: "calc((100% - 24px) /4)" }}
              >
                <Input disabled style={{ color: "#000" }} />
              </Form.Item>
              <Form.Item
                name="reward_usd"
                label="Tổng lợi nhuận (USD)"
                style={{ width: "calc((100% - 24px) /4)" }}
              >
                <Input disabled style={{ color: "#000" }} />
              </Form.Item>
              <Form.Item
                name="last_trade_at"
                label="Lần giao dịch gần nhất"
                style={{ width: "calc((100% - 24px) /4)" }}
              >
                <Input disabled style={{ color: "#000" }} />
              </Form.Item>
              <Form.Item
                name="last_trade_client_account"
                label="Tài khoản giao dịch gần nhất"
                style={{ width: "calc((100% - 24px) /4)" }}
              >
                <Input disabled style={{ color: "#000" }} />
              </Form.Item>
              <Form.Item
                name="count_trade_account"
                label="Số lượng tài khoản giao dịch"
                style={{ width: "calc((100% - 24px) /4)" }}
              >
                <Input disabled style={{ color: "#000" }} />
              </Form.Item>
            </div>
            <Form
              layout="vertical"
              form={formSearch}
              onFinish={handleFinishFormSearch}
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <Form.Item
                name="q"
                label="Tìm kiếm"
                style={{ width: "calc((100% - 264px) /3)", marginRight: "8px" }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="date"
                label="Thời gian giao dịch"
                style={{
                  width: "calc(((100% - 264px) /3) *2)",
                  marginRight: "8px",
                }}
              >
                <DatePicker.RangePicker />
              </Form.Item>
              <Button
                className="clients__search__button"
                onClick={() => formSearch.submit()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "8px",
                }}
              >
                <AiOutlineSearch size={20} />
                &nbsp;Tìm kiếm
              </Button>
              <Button
                className="clients__search__button"
                onClick={() => {
                  formSearch.resetFields();
                  setFilter({
                    page: 1,
                    limit: 10,
                    q: undefined,
                    from_date: undefined,
                    to_date: undefined,
                  });
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiOutlineReload size={20} />
                &nbsp;Refresh
              </Button>
            </Form>

            <Table
              bordered
              className="newpadding"
              rowKey="_id"
              columns={columnsData({ handleOpenModal })}
              dataSource={dataSource}
              pagination={false}
            />

            <PanigationAntStyled
              style={{ padding: "0 0 24px 0" }}
              current={filter.page}
              pageSize={filter.limit}
              showSizeChanger
              onChange={onChangePagingAccount}
              showTotal={() => `Tổng ${totalAccounts}`}
              total={totalAccounts}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
                color: "rgb(56, 114, 174)",
                marginTop: "-60px",
                paddingRight:"8px"
              }}
            >
              <div
                style={{
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                Tổng khối lượng giao dịch:&nbsp;
                {extraData?.total_volumn_lots
                  ? extraData?.total_volumn_lots + " lots"
                  : "-"}
              </div>

              <div
                style={{
                  width: "100%",

                  fontWeight: "bold",
                }}
              >
                Tổng lợi nhuận:&nbsp;
                {extraData?.total_reward_usd
                  ? extraData?.total_reward_usd + " USD"
                  : "-"}
              </div>
            </div>
          </div>
          <div style={{ width: "calc(25%)" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4>Thông tin khách hàng</h4>
              {pageMenu.update && (
                <div
                  className="checkCustomer__content__submit"
                  style={{ marginTop: "0" }}
                >
                  <button>Lưu</button>
                </div>
              )}
            </div>
            <Form.Item
              label="Họ tên"
              name="name"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Vui lòng không bỏ trống!" }]}
            >
              <Input placeholder="Nhập họ tên" />
            </Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "-8px",
              }}
            >
              <Form.Item
                label="Giới tính"
                name="gender"
                style={{ width: "calc(50% - 16px)" }}
              >
                <Select
                  allowClear
                  options={[
                    { label: "Nam", value: "MALE" },
                    { label: "Nữ", value: "FEMALE" },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Năm sinh"
                name="dob"
                style={{ width: "calc(50% - 16px)" }}
                rules={[
                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        if (!value || (value > 1900 && value < 2023)) {
                          resolve();
                        } else {
                          reject("Vui lòng nhập đúng năm sinh");
                        }
                      });
                    },
                  },
                ]}
              >
                <Input placeholder="Nhập năm sinh" />
              </Form.Item>
            </div>
            <Form.Item
              label="Email đăng ký tài khoản Exness"
              name="email"
              style={{ width: "100%", marginTop: "-8px" }}
            >
              <Input
                placeholder="Nhập email"
                disabled
                style={{ color: "#000" }}
              />
            </Form.Item>

            <Form.Item
              label="Số điện thoại"
              name="phone"
              style={{ width: "100%", marginTop: "-8px" }}
              rules={[
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      if (!value || newValidate(value.trim())) {
                        resolve();
                      } else {
                        reject("Vui lòng nhập đúng định dạng số điện thoại1");
                      }
                    });
                  },
                },
              ]}
            >
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
            <Form.Item
              label="Số điện thoại - Zalo"
              name="zalo_phone"
              style={{ width: "100%", marginTop: "-8px" }}
              rules={[
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      if (!value || newValidate(value.trim())) {
                        resolve();
                      } else {
                        reject("Vui lòng nhập đúng định dạng số điện thoại");
                      }
                    });
                  },
                },
              ]}
            >
              <Input placeholder="Nhập số điện thoại zalo" />
            </Form.Item>
            <Form.Item
              label="Tên zalo"
              name="zalo_name"
              style={{ width: "100%", marginTop: "-8px" }}
            >
              <Input placeholder="Nhập tên zalo" />
            </Form.Item>
            <Form.Item
              label="Username telegram"
              name="telegram_username"
              style={{ width: "100%", marginTop: "-8px" }}
            >
              <Input placeholder="Nhập username telegram" />
            </Form.Item>
            <Form.Item
              label="Ghi chú"
              name="note"
              style={{ width: "100%", marginTop: "-8px" }}
            >
              <TextArea placeholder="Nhập ghi chú" />
            </Form.Item>
            <Form.Item
              label="Nhóm"
              name="groups"
              style={{ width: "100%", marginTop: "-8px" }}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={groups}
                placeholder="Chọn nhóm"
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "-8px",
              }}
            >
              <Form.Item
                label="ID tài khoản đăng kí thi"
                name="trade_account_for_contest"
                style={{ width: "calc(50% - 16px)", marginTop: "-8px" }}
              >
                <Input placeholder="Nhập ID tài khoản đăng kí thi" />
              </Form.Item>
              <Form.Item
                label="Mật khẩu passview"
                name="passview"
                style={{ width: "calc(50% - 16px)", marginTop: "-8px" }}
              >
                <Input placeholder="Nhập mật khẩu passview" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "-8px",
              }}
            >
              <Form.Item
                label="Nền tảng giao dịch"
                name="trade_app"
                style={{ width: "calc(50% - 16px)", marginTop: "-8px" }}
              >
                <Input placeholder="Nhập nền tảng giao dịch" />
              </Form.Item>
              <Form.Item
                label="Máy chủ giao dịch"
                name="trade_server"
                style={{ width: "calc(50% - 16px)", marginTop: "-8px" }}
              >
                <Input placeholder="Nhập máy chủ giao dịch" />
              </Form.Item>
            </div>
          </div>
        </Form>
      </>
    )
  );
};

export default ClientDetails;
