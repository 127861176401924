import {
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Table,
  Modal,
  Switch,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { columnsData } from "./data";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { AiOutlineReload, AiOutlineSearch } from "react-icons/ai";
import { FiPlusCircle } from "react-icons/fi";

import { BiSave } from "react-icons/bi";
import moment from "moment";
import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveMenu,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import OverlaySpinner from "../../../components/overlaySpinner/OverlaySpinner";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import { UserContext } from "../../../context/context";
import PanigationAntStyled from "../../../components/layout/PanigationAntStyled";
const { RangePicker } = DatePicker;
const GroupManager = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedId, setSelectedId] = useState(undefined);

  const [status, setStatus] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);

  const [form] = Form.useForm();
  const [formCreate] = Form.useForm();
  const [formUpdate] = Form.useForm();

  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    q: undefined,
    status: undefined,
    role: undefined,
    from_date: undefined,
    to_date: undefined,
    page: 1,
    limit: 10,
  });

  const onChangePaging = (page, pageSize) => {
    setFilter({
      ...filter,

      page: page,
      limit: pageSize,
    });
  };

  const getData = async () => {
    setLoading(true);

    try {
      let data = await api.get(`${API_URL}api/v1/groups`, {
        ...filter,
      });
      setTotal(data?.metadata?.totalItems);
      setDataSource(data?.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishForm = (e) => {
    setFilter({
      ...filter,
      page: 1,
      q: e?.q?.length > 0 ? e?.q?.trim() : undefined,
      status: e?.status,
      role: e?.role,
      from_date: e?.date ? moment(e?.date[0])?.format("YYYY-MM-DD") : undefined,
      to_date: e?.date ? moment(e?.date[1])?.format("YYYY-MM-DD") : undefined,
    });
  };

  const handleFinishFormCreate = async (value) => {
    try {
      let { data } = await api.post(`${API_URL}api/v1/groups`, {
        name: value?.name,
        description: value?.description,
        code: value?.code,

        status: status ? "ACTIVE" : "INACTIVE",
      });
      setOpenModal(false);
      formCreate.resetFields();
      notifySuccess("Tạo nhóm mới thành công!");
      getData();
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormCreate(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishFormUpdate = async (value) => {
    try {
      let { data } = await api.put(`${API_URL}api/v1/groups/${selectedId}`, {
        name: value?.name,
        description: value?.description,
        code: value?.code,

        status: statusUpdate ? "ACTIVE" : "INACTIVE",
      });
      setOpenModalUpdate(false);
      formUpdate.resetFields();
      setSelectedId(undefined);
      setStatusUpdate(false);

      notifySuccess("Cập nhật thành công!");
      getData();
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormCreate(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleChangeStatus = async (value, record) => {
    setLoading(true);
    try {
      let { data } = await api.put(`${API_URL}api/v1/groups/${record.id}`, {
        status: value ? "ACTIVE" : "INACTIVE",
      });
      let newList = [...dataSource].map((x) =>
        x.id === record.id ? { ...x, status: value ? "ACTIVE" : "INACTIVE" } : x
      );
      setDataSource(newList);
      notifySuccess("Đổi trạng thái thành công!");
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleChangeStatus(value, record);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleUpdate = (record) => {
    setOpenModalUpdate(true);
    setStatusUpdate(record?.status === "ACTIVE" ? true : false);
    setSelectedId(record?.id);
    formUpdate.setFieldsValue({
      name: record?.name,
      code: record?.code,
      description: record?.description,
    });
  };

  const { state, dispatch } = useContext(UserContext);

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/groups");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);

  useEffect(() => {
    pageMenu.list && getData();
  }, [pageMenu, filter]);

  return (
    pageMenu.list && (
      <div className="userSystem">
        <OverlaySpinner text="Vui lòng đợi ..." open={loading} />

        <Modal
          open={openModalUpdate}
          onCancel={(e) => {
            setOpenModalUpdate(false);
            setSelectedId(undefined);
            setStatusUpdate(false);
            formUpdate.resetFields();
          }}
          footer={null}
          centered
        >
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Cập nhật nhóm
          </div>
          <Form
            layout="vertical"
            form={formUpdate}
            onFinish={handleFinishFormUpdate}
            style={{ marginTop: "16px" }}
            initialValues={{ status: true }}
          >
            <Form.Item
              label="Tên nhóm"
              name="name"
              placeholder="Nhập tên nhóm"
              style={{ width: "100%" }}
              required
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mã nhóm"
              name="code"
              placeholder="Nhập mã nhóm"
              style={{ width: "100%" }}
              required
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mô tả nhóm"
              name="description"
              placeholder="Nhập mô tả"
              style={{ width: "100%" }}
            >
              <TextArea />
            </Form.Item>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div name="status" style={{ width: "100%" }}>
                <Switch
                  checked={statusUpdate}
                  onChange={(e) => setStatusUpdate(e)}
                />
                &nbsp;Trạng thái hoạt động
              </div>
              <Button
                className="userSystem__search__button"
                style={{
                  marginTop: "-24px",
                  background: "rgb(56, 114, 174)",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => formUpdate.submit()}
              >
                <BiSave size={20} />
                &nbsp; Cập nhật
              </Button>
            </div>
          </Form>
        </Modal>

        <Modal
          open={openModal}
          onCancel={(e) => {
            setOpenModal(false);
            formCreate.resetFields();
          }}
          footer={null}
          centered
        >
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Tạo mới nhóm
          </div>
          <Form
            layout="vertical"
            form={formCreate}
            onFinish={handleFinishFormCreate}
            style={{ marginTop: "16px" }}
            initialValues={{ status: true }}
          >
            <Form.Item
              label="Tên nhóm"
              name="name"
              placeholder="Nhập tên nhóm"
              style={{ width: "100%" }}
              required
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mã nhóm"
              name="code"
              placeholder="Nhập mã nhóm"
              style={{ width: "100%" }}
              required
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mô tả nhóm"
              name="description"
              placeholder="Nhập mô tả"
              style={{ width: "100%" }}
            >
              <TextArea />
            </Form.Item>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div name="status" style={{ width: "100%" }}>
                <Switch checked={status} onChange={(e) => setStatus(e)} />
                &nbsp;Trạng thái hoạt động
              </div>
              <Button
                className="userSystem__search__button"
                style={{
                  marginTop: "-24px",
                  background: "rgb(56, 114, 174)",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => formCreate.submit()}
              >
                <BiSave size={20} />
                &nbsp; Tạo mới
              </Button>
            </div>
          </Form>
        </Modal>
        <Form
          className="userSystem__search"
          layout="vertical"
          form={form}
          onFinish={handleFinishForm}
        >
          <Form.Item
            label="Tìm kiếm"
            name="q"
            className="userSystem__search__input"
            style={{
              width: pageMenu.create
                ? "calc(100% - 878px)"
                : "calc(100% - 750px)",
            }}
          >
            <Input placeholder="Nhập tên nhóm, code" />
          </Form.Item>

          <Form.Item
            label="Trạng thái"
            name="status"
            className="userSystem__search__select"
          >
            <Select
              allowClear
              options={[
                { value: "ACTIVE", label: "Hoạt động" },
                { value: "INACTIVE", label: "Dừng hoạt động" },
              ]}
              placeholder="Tất cả"
            />
          </Form.Item>

          <Form.Item
            label="Ngày tạo"
            name="date"
            className="userSystem__search__date"
            style={{ width: "368px" }}
          >
            <RangePicker />
          </Form.Item>

          <Button
            className="userSystem__search__button"
            onClick={() => form.submit()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AiOutlineSearch size={20} />
            &nbsp;Tìm kiếm
          </Button>
          <Button
            className="clients__search__button"
            onClick={() => {
              form.resetFields();
              setFilter({
                ...filter,
                q: undefined,
                status: undefined,
                role: undefined,
                from_date: undefined,
                to_date: undefined,
                page: 1,
                limit: 10,
              });
            }}
          >
            <AiOutlineReload size={20} />
            &nbsp;Refresh
          </Button>
          {pageMenu.create && (
            <Button
              className="userSystem__search__button"
              onClick={() => setOpenModal(true)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FiPlusCircle size={20} />
              &nbsp;Tạo mới
            </Button>
          )}
        </Form>
        <Table
          bordered
          className="newpadding"
          rowKey="_id"
          columns={columnsData({ handleChangeStatus, handleUpdate }, pageMenu)}
          dataSource={dataSource?.slice(
            (filter?.page - 1) * filter?.limit,
            filter?.page * filter?.limit
          )}
          pagination={false}
        />
        <PanigationAntStyled
          style={{ padding: "0 0 24px 0" }}
          current={filter.page}
          pageSize={filter.limit}
          showSizeChanger
          onChange={onChangePaging}
          showTotal={() => `Tổng ${total}`}
          total={total}
        />
      </div>
    )
  );
};

export default GroupManager;
