import React, { useContext, useEffect, useState } from "react";
import ChartBottom from "./components/ChartBottom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/context";
import { localGetMenu } from "../../utils/localStorage";

const DashboardUsers = () => {
  const navigate = useNavigate();
  const { state } = useContext(UserContext);

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/report-users");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);
  return (
    pageMenu.list && (
      <div
        className="userSystem"
      >
        <div>
          <ChartBottom />
        </div>
      </div>
    )
  );
};

export default DashboardUsers;
