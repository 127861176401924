import React from "react";

const Bang = () => {
  return (
    <div
      style={{
        width: "300px",
        height: "420px",
        background: "rgb(35,170,90)",
        padding: "20px",
        color: "white",
        fontSize: "20px",
      }}
    >
      <div style={{ fontWeight: "bold", textAlign: "center" }}>
        BÚN THỊT NƯỚNG
      </div>
      <div style={{ fontWeight: "bold", textAlign: "center" }}>ĐÔ THÀNH</div>
      <div
        style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}
      >
        ~~~~~
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Bún thịt nướng đầy đủ</div>
        <div>30k</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Bún thịt nướng</div>
        <div>28k</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Bún nem nướng</div>
        <div>28k</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Bún chả giò</div>
        <div>28k</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Bún thịt nướng + nem nướng</div>
        <div>28k</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Bún thịt nướng + chả giò</div>
        <div>28k</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Bún nem nướng + chả giò</div>
        <div>28k</div>
      </div>

      <div
        style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}
      >
        ~~~~~
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Thịt nướng</div>
        <div>15k</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Nem nướng</div>
        <div>6k</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Chả giò</div>
        <div>6k</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Trà đá</div>
        <div>2k</div>
      </div>
      <div
        style={{
          fontStyle: "italic",

          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          fontSize: "14px",
          marginBottom: "4px",
        }}
      >
        <div>Bún thêm miễn phí</div>
        <div></div>
      </div>
      <div style={{ textAlign: "center", fontSize: "14px" }}>
        ---------------
      </div>
      <div
        style={{
          fontStyle: "italic",
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "14px",
        }}
      >
        Chúc quý khách ngon miệng
      </div>
    </div>
  );
};

export default Bang;
