/* eslint-disable */

import { Popover, Switch } from "antd";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";

export const columnsData = (
  { handleChangeStatus, handleOpenModal, handleOpenDelete },
  pageMenu
) => {
  return [
    {
      title: "Thông tin",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => pageMenu.details && handleOpenModal(record)}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Mức chiết khấu (%)",
      dataIndex: "level",
      key: "level",
      align: "right",
      render: (text, record, index) => {
        return <div style={{ color: "rgb(56, 114, 174)" }}>{text} %</div>;
      },
    },
    {
      title: "Người tạo",
      dataIndex: "created_by_user_info",
      key: "created_by_user_info",
      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)" }}>
            {record?.created_by_user_info?.name}
          </div>
        );
      },
    },
    {
      title: "Trạng thái hoạt động",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        return (
          <Switch
            checked={text === "ACTIVE" ? true : false}
            disabled={!pageMenu.update}
            onChange={(e) => pageMenu.update && handleChangeStatus(e, record)}
          />
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record, index) => {
        return (
          <Popover
            placement="bottom"
            content={
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {pageMenu.update && (
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => handleOpenModal(record)}
                  >
                    Chỉnh sửa
                  </div>
                )}
                {pageMenu.update && (
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => handleOpenDelete(record)}
                  >
                    Xóa
                  </div>
                )}

                {/* <div
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    padding: "2px 4px",
                  }}
                  className="hoverGray"
                  onClick={() => handleSync(record.id)}
                >
                  Đồng bộ
                </div> */}
              </div>
            }
            trigger="click"
          >
            <PiDotsThreeOutlineVerticalBold
              size={24}
              style={{ cursor: "pointer" }}
            />
          </Popover>
        );
      },
    },
  ];
};
