/* eslint-disable */

import { Input, Popover, Switch } from "antd";
import moment from "moment";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  BiSolidError,
  BiSolidErrorCircle,
  BiSolidDownArrow,
  BiSolidUpArrow,
} from "react-icons/bi";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
import { notifyWarning } from "../../../components/notification";

export const columnsData = (
  {
    state,
    handleOpenModal,
    handleSync,
    handleChangeStatus,
    handleChangeStatusPart,
    handleAssign,
    handleSort,
    filter,
  },
  pageMenu
) => {
  return [
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Họ tên</div>
          <div style={{ width: "20px" }} onClick={() => handleSort(1)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "name" && filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "name" && filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 200,
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              cursor: pageMenu.details ? "pointer" : "unset",
            }}
            onClick={() => pageMenu.details && handleOpenModal(record, 1)}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Email</div>
          <div style={{ width: "20px" }} onClick={() => handleSort(2)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "email" && filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "email" && filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "email",
      key: "email",
      width: 300,
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{ wordBreak: "break-word", cursor: "pointer" }}
              onClick={() => pageMenu.details && handleOpenModal(record, 5)}
            >
              {text}&nbsp;&nbsp;
            </p>
            {record?.affiliation ? (
              <AiFillCheckCircle color="rgb(57,174,145)" size={24} />
            ) : (
              <BiSolidErrorCircle color="red" size={24} />
            )}
          </div>
        );
      },
    },
    {
      title: "ID giao dịch",
      dataIndex: "trade_account_for_contest",
      key: "trade_account_for_contest",
      width: 150,

      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {record.trade_account_for_contest ? (
              <>
                <div
                  style={{
                    color: !record?.checked_ref_trade_account_at && "red",
                  }}
                >
                  {record.trade_account_for_contest
                    ? record.trade_account_for_contest
                    : "-"}
                </div>
                &nbsp;&nbsp;
                {record?.ref_trade_account ? (
                  <AiFillCheckCircle color="rgb(57,174,145)" size={24} />
                ) : (
                  <BiSolidErrorCircle color="red" size={24} />
                )}
              </>
            ) : (
              <>
                <div style={{ color: "rgb(56, 114, 174)" }}>
                  {record.trade_account_for_contest
                    ? record.trade_account_for_contest
                    : "-"}
                </div>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Doanh thu (USD)</div>
          <div style={{ width: "20px" }} onClick={() => handleSort(9)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "total_reward_usd" &&
                  filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "total_reward_usd" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "total_reward_usd",
      key: "total_reward_usd",
      width: 150,
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              cursor: pageMenu.details ? "pointer" : "unset",
            }}
            onClick={() => pageMenu.details && handleOpenModal(record, 2)}
          >
            {record.total_reward_usd
              ? record.total_reward_usd?.toFixed(2)
              : "0"}
          </div>
        );
      },
    },
    {
      title: "Số điện thoại - Zalo",
      dataIndex: "email",
      key: "email",
      width: 150,

      render: (text, record, index) => {
        return (
          <>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record.phone ? record.phone : "-"}
            </div>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record.zalo_phone ? record.zalo_phone : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: (
        // <div
        //   style={{
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "space-between",
        //   }}
        // >
        <div>Ghi chú</div>
        /* <div style={{ width: "20px" }} onClick={() => handleSort(3)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "form_info" && filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "form_info" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div> */
        // </div>
      ),
      dataIndex: "form_info",
      key: "form_info",
      width: 200,

      render: (text, record, index) => {
        return (
          <>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record?.supporter_note ? record?.supporter_note : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Ngày điền</div>
          <div style={{ width: "20px" }} onClick={() => handleSort(4)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "created_at" &&
                  filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "created_at" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "created_at",
      key: "created_at",
      width: 110,

      render: (text, record, index) => {
        return (
          <>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record.created_at
                ? moment(record.created_at).format("DD-MM-YYYY HH:mm:ss")
                : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Xuất Excel</div>
          <div style={{ width: "20px" }} onClick={() => handleSort(8)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "exported_at" &&
                  filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "exported_at" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "exported_status",
      key: "exported_at",
      width: 130,

      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)" }}>
            {record?.exported_at
              ? moment(record.exported_at).format("DD-MM-YYYY HH:mm:ss")
              : "Chưa xuất excel"}
          </div>
        );
      },
    },
    // {
    //   title: "Vai trò",
    //   dataIndex: "role",
    //   key: "role",
    //   width: "15%",
    //   render: (text, record, index) => {
    //     return (
    //
    //     );
    //   },
    // },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Trạng thái xử lý</div>
          <div style={{ width: "20px" }} onClick={() => handleSort(5)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "status" && filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "status" && filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "status",
      key: "status",
      width: 150,

      render: (text, record, index) => {
        return (
          <div
            style={{
              width: "120px",
              height: "32px",
              background:
                record.status === "NEW"
                  ? "rgb(188, 188, 188)"
                  : record.status === "PROCESSING"
                  ? "rgb(117, 218, 239)"
                  : "rgb(35, 216, 53)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "16px",
              color: "#fff",
            }}
          >
            {record.status === "NEW"
              ? "Mới"
              : record.status === "PROCESSING"
              ? "Đang xử lí"
              : "Hoàn thành"}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Trạng thái tham gia</div>
          {/* <div style={{ width: "20px" }} onClick={() => handleSort(5)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "reward_usd" &&
                  filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "reward_usd" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div> */}
        </div>
      ),
      dataIndex: "participation_status",
      width: 150,
      key: "participation_status",
      render: (text, record, index) => {
        return (
          <div
            style={{
              width: "120px",
              height: "32px",
              background:
                record.participation_status === "ACTIVE"
                  ? "rgb(37, 150, 190)"
                  : "rgb(218, 64, 69)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "16px",
              color: "#fff",
            }}
          >
            {record.participation_status === "ACTIVE"
              ? "Tham gia"
              : "Ngưng tham gia"}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Biểu mẫu</div>
          <div style={{ width: "20px" }} onClick={() => handleSort(3)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "form_info" && filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "form_info" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "form_info",
      key: "form_info",
      width: 300,

      render: (text, record, index) => {
        return (
          <>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record?.form_info?.name ? record?.form_info?.name : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Nhân viên chăm sóc</div>
          <div style={{ width: "20px" }} onClick={() => handleSort(6)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "supporter_info" &&
                  filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "supporter_info" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "supporter_info",
      key: "supporter_info",
      width: 180,

      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)" }}>
            {record?.supporter_info?.name ? record?.supporter_info?.name : "-"}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Lần cập nhật cuối</div>
          <div style={{ width: "20px" }} onClick={() => handleSort(10)}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "updated_at" &&
                  filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "updated_at" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      width: 180,

      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)" }}>
            <div>
              {record?.updated_at
                ? moment(record?.updated_at).format("DD-MM-YYYY HH:mm:ss")
                : "-"}
            </div>
            <div>{record?.updated_by_user_info?.name}</div>
          </div>
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "status",
      key: "status",
      align: "center",
      fixed: "right",
      width: 80,
      render: (text, record, index) => {
        return (
          <Popover
            placement="bottom"
            content={
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {pageMenu.update && (
                  <div
                    style={{
                      width: "100%",
                      cursor:
                        state?.user?._id === record?.supporter_info?._id
                          ? "pointer"
                          : "not-allowed",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() =>
                      state?.user?._id === record?.supporter_info?._id &&
                      handleChangeStatus(record.id)
                    }
                  >
                    Chuyển trạng thái xử lý
                  </div>
                )}
                {pageMenu.update && (
                  <div
                    style={{
                      width: "100%",
                      cursor:
                        state?.user?._id === record?.supporter_info?._id
                          ? "pointer"
                          : "not-allowed",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() =>
                      state?.user?._id === record?.supporter_info?._id &&
                      handleChangeStatusPart(record)
                    }
                  >
                    Đổi trạng thái tham gia
                  </div>
                )}
                {pageMenu.update && (
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => handleAssign(1, record)}
                  >
                    Phân công cho tôi
                  </div>
                )}

                {pageMenu.update && (
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => handleAssign(2, record)}
                  >
                    Phân công cho người khác
                  </div>
                )}
                {/* <div
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    padding: "2px 4px",
                  }}
                  className="hoverGray"
                  onClick={() => handleSync(record.id)}
                >
                  Đồng bộ
                </div> */}
              </div>
            }
            trigger="click"
          >
            <PiDotsThreeOutlineVerticalBold
              size={24}
              style={{ cursor: "pointer" }}
            />
          </Popover>
        );
      },
    },
  ];
};

export const columnsDataAssign = (
  { onChangeValue },
  selectedValueAssign,
  dataSourceAssign
) => {
  return [
    {
      title: "STT",
      dataIndex: "user_info",
      key: "user_info",

      render: (text, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Tên",
      dataIndex: "user_info",
      key: "user_info",
      width: 200,
      render: (text, record, index) => {
        return <div>{record?.user_info?.name}</div>;
      },
    },
    {
      title: "Đã chăm sóc (%)",
      dataIndex: "before_assign_form_answer_percentage",
      key: "before_assign_form_answer_percentage",
      align: "right",
      render: (text, record, index) => {
        return (
          <div>
            {!record?.before_assign_form_answer_percentage
              ? "0"
              : text?.toFixed(1)}
            %
          </div>
        );
      },
    },
    {
      title: "Số lượng đã chăm sóc",
      dataIndex: "before_assign_form_answer_count",
      key: "before_assign_form_answer_count",
      align: "right",

      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },

    {
      title: "Phân bổ (%)",
      dataIndex: "after_assign_form_answer_percentage",
      key: "after_assign_form_answer_percentage",
      align: "right",
      render: (text, record, index) => {
        return (
          <div>
            {!record?.after_assign_form_answer_percentage
              ? "0"
              : text?.toFixed(1)}
            %
          </div>
        );
      },
    },
    {
      title: "Số lượng đã phân bổ",
      dataIndex: "after_assign_form_answer_count",
      key: "after_assign_form_answer_count",
      align: "right",
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "Điều chỉnh số lượng",
      dataIndex: "input_assign_form_answer_count",
      width: 120,
      key: "input_assign_form_answer_count",
      render: (text, record, index) => {
        return (
          <div>
            <Input
              style={{ textAlign: "right" }}
              type="number"
              min={0}
              defaultValue={record.input_assign_form_answer_count}
              onChange={(e) =>
                e.target.value >= 0
                  ? onChangeValue(
                      e.target.value,
                      record._id,
                      1,
                      selectedValueAssign,
                      dataSourceAssign
                    )
                  : notifyWarning("Vui lòng nhập số phù hợp")
              }
            />
          </div>
        );
      },
    },
    {
      title: "Điều chỉnh % (ưu tiên)",
      dataIndex: "input_assign_form_answer_percentage",
      key: "input_assign_form_answer_percentage",
      width: 120,
      render: (text, record, index) => {
        return (
          <div>
            <Input
              style={{ textAlign: "right" }}
              type="number"
              min={0}
              max={100}
              defaultValue={record.input_assign_form_answer_percentage}
              onChange={(e) =>
                e.target.value >= 0 && e.target.value <= 100
                  ? onChangeValue(
                      e.target.value,
                      record._id,
                      2,
                      selectedValueAssign,
                      dataSourceAssign
                    )
                  : notifyWarning("Vui lòng chỉ nhập 0 - 100%")
              }
            />
          </div>
        );
      },
    },
  ];
};

export const columnsDataAssign2 = (
  { onChangeValue },
  selectedValueAssign,
  dataSourceAssign
) => {
  return [
    {
      title: "STT",
      dataIndex: "user_info",
      key: "user_info",
      render: (text, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Tên",
      dataIndex: "user_info",
      key: "user_info",
      render: (text, record, index) => {
        return <div>{record?.user_info?.name}</div>;
      },
    },
    {
      title: "Đã chăm sóc (%)",
      dataIndex: "before_assign_form_answer_percentage",
      key: "before_assign_form_answer_percentage",
      align: "right",
      render: (text, record, index) => {
        return (
          <div>
            <div>
              {!record?.before_assign_form_answer_percentage
                ? "0"
                : text?.toFixed(1)}
              %
            </div>
          </div>
        );
      },
    },
    {
      title: "Số lượng đã chăm sóc",
      dataIndex: "before_assign_form_answer_count",
      key: "before_assign_form_answer_count",
      align: "right",

      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },

    {
      title: "Phân bổ (%)",
      dataIndex: "after_assign_form_answer_percentage",
      key: "after_assign_form_answer_percentage",
      align: "right",
      render: (text, record, index) => {
        return (
          <div>
            {!record?.after_assign_form_answer_percentage
              ? "0"
              : text?.toFixed(1)}
            %
          </div>
        );
      },
    },
    {
      title: "Số lượng đã phân bổ",
      dataIndex: "after_assign_form_answer_count",
      key: "after_assign_form_answer_count",
      align: "right",
      render: (text, record, index) => {
        return <div>{text}</div>;
      },
    },
  ];
};

export const columnsDataModal = ({ handleChangeStatus, handleOpenModal }) => {
  return [
    {
      title: "Ngày giao dịch",
      dataIndex: "reward_date",
      key: "reward_date",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {record?.reward_date
              ? moment(record?.reward_date).format("DD-MM-YYYY")
              : "-"}
          </div>
        );
      },
    },

    {
      title: "Khối lượng giao dịch (lots)",
      dataIndex: "volumn_lots",
      key: "volumn_lots",
      align: "right",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Lợi nhuận (USD)",
      dataIndex: "reward_usd",
      key: "reward_usd",
      align: "right",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
            }}
          >
            {text}
          </div>
        );
      },
    },
  ];
};

export const columnsDataHistoryStatus = ({}) => {
  return [
    {
      title: "Trạng thái trước đó",
      dataIndex: "old_status",
      key: "old_status",
      width: "25%",
      render: (text, record, index) => {
        return (
          <div
            style={{
              width: "120px",
              height: "32px",
              background:
                record.old_status === "NEW"
                  ? "rgb(188, 188, 188)"
                  : record.old_status === "PROCESSING"
                  ? "rgb(117, 218, 239)"
                  : "rgb(35, 216, 53)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "16px",
              color: "#fff",
            }}
          >
            {record?.old_status === "COMPLETE"
              ? "Hoàn tất"
              : record?.old_status === "PROCESSING"
              ? "Đang xử lý"
              : "Mới"}
          </div>
        );
      },
    },
    {
      title: "Trạng thái sau đó",
      dataIndex: "new_status",
      key: "new_status",
      width: "25%",
      render: (text, record, index) => {
        return (
          <div
            style={{
              width: "120px",
              height: "32px",
              background:
                record.new_status === "NEW"
                  ? "rgb(188, 188, 188)"
                  : record.new_status === "PROCESSING"
                  ? "rgb(117, 218, 239)"
                  : "rgb(35, 216, 53)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "16px",
              color: "#fff",
            }}
          >
            {record?.new_status === "COMPLETE"
              ? "Hoàn tất"
              : record?.new_status === "PROCESSING"
              ? "Đang xử lý"
              : "Mới"}
          </div>
        );
      },
    },
    {
      title: "Nhân viên thao tác",
      dataIndex: "updated_by_user",
      key: "updated_by_user",
      width: "25%",

      render: (text, record, index) => {
        return <div>{record?.updated_by_user?.name}</div>;
      },
    },
    {
      title: "Ngày thao tác",
      dataIndex: "changed_at",
      key: "changed_at",
      align: "right",
      width: "25%",

      render: (text, record, index) => {
        return (
          <div>
            {record?.changed_at
              ? moment(record?.changed_at).format("DD-MM-YYYY HH:mm:ss")
              : "-"}
          </div>
        );
      },
    },
  ];
};

export const columnsDataHistorySupporter = ({}) => {
  return [
    {
      title: "Nhân viên chăm sóc cũ",
      dataIndex: "source",
      key: "source",
      width: "25%",
      render: (text, record, index) => {
        return <div>{record?.source?.name || "-"}</div>;
      },
    },
    {
      title: "Nhân viên chăm sóc mới",
      dataIndex: "dest",
      key: "dest",
      width: "25%",
      render: (text, record, index) => {
        return <div>{record?.dest?.name}</div>;
      },
    },
    {
      title: "Nhân viên thao tác",
      dataIndex: "updated_by_user",
      key: "updated_by_user",
      width: "25%",

      render: (text, record, index) => {
        return <div>{record?.updated_by_user?.name}</div>;
      },
    },
    {
      title: "Ngày thao tác",
      dataIndex: "changed_at",
      key: "changed_at",
      align: "right",
      width: "25%",

      render: (text, record, index) => {
        return (
          <div>
            {record?.changed_at
              ? moment(record?.changed_at).format("DD-MM-YYYY HH:mm:ss")
              : "-"}
          </div>
        );
      },
    },
  ];
};

export const columnsDataHistoryParticipant = ({}) => {
  return [
    {
      title: "Trạng thái trước đó",
      dataIndex: "old_status",
      key: "old_status",
      width: "25%",
      render: (text, record, index) => {
        return (
          <div
            style={{
              width: "120px",
              height: "32px",
              background:
                record.old_status === "ACTIVE"
                  ? "rgb(37, 150, 190)"
                  : "rgb(218, 64, 69)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "16px",
              color: "#fff",
            }}
          >
            {record?.old_status === "ACTIVE" ? "Tham gia" : "Ngừng tham gia"}
          </div>
        );
      },
    },
    {
      title: "Trạng thái sau đó",
      dataIndex: "new_status",
      key: "new_status",
      width: "25%",
      render: (text, record, index) => {
        return (
          <div
            style={{
              width: "120px",
              height: "32px",
              background:
                record.new_status === "ACTIVE"
                  ? "rgb(37, 150, 190)"
                  : "rgb(218, 64, 69)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "16px",
              color: "#fff",
            }}
          >
            {record?.new_status === "ACTIVE" ? "Tham gia" : "Ngừng tham gia"}
          </div>
        );
      },
    },
    {
      title: "Nhân viên thao tác",
      dataIndex: "updated_by_user",
      key: "updated_by_user",
      width: "25%",

      render: (text, record, index) => {
        return <div>{record?.updated_by_user?.name}</div>;
      },
    },
    {
      title: "Ngày thao tác",
      dataIndex: "changed_at",
      key: "changed_at",
      align: "right",
      width: "25%",

      render: (text, record, index) => {
        return (
          <div>
            {record?.changed_at
              ? moment(record?.changed_at).format("DD-MM-YYYY HH:mm:ss")
              : "-"}
          </div>
        );
      },
    },
  ];
};
export const columnsText = [
  {
    title: "Full Name",
    width: 100,
    dataIndex: "name",
    key: "name",
    fixed: "left",
  },
  {
    title: "Age",
    width: 100,
    dataIndex: "age",
    key: "age",
    fixed: "left",
    sorter: true,
  },
  { title: "Column 1", dataIndex: "address", key: "1" },
  { title: "Column 2", dataIndex: "address", key: "2" },
  { title: "Column 3", dataIndex: "address", key: "3" },
  { title: "Column 4", dataIndex: "address", key: "4" },
  { title: "Column 5", dataIndex: "address", key: "5" },
  { title: "Column 6", dataIndex: "address", key: "6" },
  { title: "Column 7", dataIndex: "address", key: "7" },
  { title: "Column 8", dataIndex: "address", key: "8" },
  {
    title: "Action",
    key: "operation",
    fixed: "right",
    width: 100,
    render: () => <a>action</a>,
  },
];

export const dataText = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "2",
    name: "Jim Green",
    age: 40,
    address: "London Park",
  },
];
