import {
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Table,
  Modal,
  Switch,
  Checkbox,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { columnsData } from "./data";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { AiOutlineSearch } from "react-icons/ai";
import { PiUserCirclePlusFill } from "react-icons/pi";
import { BiSave } from "react-icons/bi";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import OverlaySpinner from "../../../components/overlaySpinner/OverlaySpinner";
import {
  localGetMenu,
  localRemoveMenu,
  localGetRefreshToken,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import axios from "axios";
import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { geneUniqueKey } from "../../../utils/helpers/functions/textUtils";
import { UserContext } from "../../../context/context";
const { RangePicker } = DatePicker;
const CreateForm = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");

  const [groupsSelected, setGroupsSelected] = useState(undefined);
  const [utmSource, setUtmSource] = useState("");
  const [groups, setGroups] = useState([]);
  const [status, setStatus] = useState(false);
  let navigate = useNavigate();

  const [items, setItems] = useState([]);

  const getData = async () => {
    setLoading(true);

    try {
      let { data } = await api.get(`${API_URL}api/v1/forms/items`, {});
      let fakeData = [...data].map((x, index) => {
        return { ...x, key: index + 1 };
      });
      setItems(fakeData);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getGroup = async () => {
    try {
      let { data } = await api.get(
        `${API_URL}api/v1/groups?exclude_group_form=true`,
        {}
      );
      setGroups(
        data.map((x) => {
          return { value: x?.id, label: x?.name };
        })
      );
      console.log(
        data.map((x) => {
          return { value: x?.id, label: x?.name };
        })
      );
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishFormCreate = async (e) => {
    if (!name || name.length === 0) {
      notifyError("Vui lòng nhập tên biểu mẫu");
      return;
    }
    if (!utmSource || utmSource.length === 0) {
      notifyError("Vui lòng nhập utm source");
      return;
    }
    if (!code || code.length === 0) {
      notifyError("Vui lòng nhập mã code");
      return;
    }
    setLoading(true);

    try {
      let { data } = await api.post(`${API_URL}api/v1/forms`, {
        description: editorRef.current.getContent(),
        name: name,
        code: code,
        utm_source: utmSource,
        status: status ? "ACTIVE" : "INACTIVE",
        items: items,
        groups: groupsSelected,
      });
      notifySuccess("Tạo form thành công");
      setLoading(false);

      navigate(`/forms`);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormCreate();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleChange = (type, code, value, child_id, child_type) => {
    console.log(type, value, code);
    if (type === 9) {
      let fakeData = [...items].map((x) =>
        x.code === code ? { ...x, code: value, name: value } : x
      );
      setItems(fakeData);
    }
    if (type === 1) {
      let fakeData = [...items].map((x) =>
        x.code === code ? { ...x, label: value } : x
      );
      setItems(fakeData);
    }
    if (type === 2) {
      let fakeData = [...items].map((x) =>
        x.code === code ? { ...x, type: value } : x
      );
      setItems(fakeData);
    }
    if (type === 3) {
      let fakeData = [...items].map((x) =>
        x.code === code ? { ...x, required: value } : x
      );
      setItems(fakeData);
    }
    if (type === 4) {
      let fakeData = [...items].map((x) =>
        x.code === code ? { ...x, status: value ? "ACTIVE" : "INACTIVE" } : x
      );
      setItems(fakeData);
    }
    if (type === 5) {
      let fakeData = [...items].map((x) =>
        x.code === code
          ? {
              ...x,
              options: [
                ...x.options,
                { label: "", value: "", child_id: geneUniqueKey() },
              ],
            }
          : x
      );
      setItems(fakeData);
    }
    if (type === 6) {
      let fakeData = [...items].map((x) =>
        x.code === code
          ? {
              ...x,
              options: [...x.options].map((y) =>
                y?.child_id === child_id
                  ? child_type === 1
                    ? { ...y, label: value }
                    : { ...y, value: value }
                  : y
              ),
            }
          : x
      );
      setItems(fakeData);
    }
    if (type === 7) {
      let fakeData = [...items].map((x) =>
        x.code === code
          ? {
              ...x,
              options: [...x.options].filter((y) => y?.child_id !== child_id),
            }
          : x
      );
      setItems(fakeData);
    }
    if (type === 8) {
      let fakeData = [...items].filter((x) => x.code !== code);

      setItems(fakeData);
    }
  };

  const { state, dispatch } = useContext(UserContext);

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/forms");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "CREATE"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, create: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);

  useEffect(() => {
    pageMenu.create && getData();
  }, [pageMenu]);
  useEffect(() => {
    pageMenu.create && getGroup();
  }, [pageMenu]);
  const editorRef = useRef(null);

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 99,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  className="haveDrag"
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setItems((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  // const addField = () => {
  //   setItems((previous) => [
  //     ...previous,
  //     {
  //       id: geneUniqueKey(),
  //       label: "",
  //       required: false,
  //       status: "INACTIVE",
  //       type: "text",
  //       code: "",
  //       name: "",
  //       key: previous.length + 2,
  //       options: [],
  //     },
  //   ]);
  // };
  return (
    pageMenu.create && (
      <div
        className="formCreate"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
        <div style={{ width: "calc(28% - 24px)" }}>
          <p style={{ fontSize: "14px", marginBottom: "4px" }}>
            Tên biểu mẫu:<span style={{ color: "red" }}>*</span>
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Input
              value={name}
              style={{ width: "100%" }}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <p style={{ fontSize: "14px", margin: "16px 0 4px" }}>
            Mã code:<span style={{ color: "red" }}>*</span>
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Input
              value={code}
              style={{ width: "100%" }}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>

          <p style={{ fontSize: "14px", margin: "16px 0 4px" }}>
            Utm source:<span style={{ color: "red" }}>*</span>
          </p>
          <div>
            <Input
              value={utmSource}
              style={{ width: "100%" }}
              onChange={(e) => setUtmSource(e.target.value)}
            />
          </div>
          <p style={{ fontSize: "14px", margin: "16px 0 4px" }}>Nhóm:</p>
          <div>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              optionFilterProp="children"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              allowClear
              value={groupsSelected}
              options={groups}
              onChange={(e) => setGroupsSelected(e)}
              placeholder="Chọn nhóm"
            />
          </div>
          <div style={{ width: "100%", marginTop: "12px" }}>
            <span style={{ fontSize: "14px", marginRight: "12px" }}>
              Trạng thái hoạt động:
            </span>
            <Switch checked={status} onChange={(e) => setStatus(e)} />
          </div>
        </div>

        <div style={{ width: "72%" }}>
          <div style={{ marginBottom: "12px" }}>
            <p
              style={{ fontSize: "14px", marginBottom: "4px" }}
              onClick={() => {
                console.log("update new");
              }}
            >
              Nội dung:
            </p>
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue=""
              apiKey="j3su5e7yfilsrpsbi8u6m0wjdipy9i5lp9wopyvoqm1b1k6v"
              init={{
                height: 300,
                menubar: true,
                plugins:
                  "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                menubar: "file edit view insert format tools table help",
                toolbar:
                  "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",

                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              // rowKey array
              items={items.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                className="newpadding"
                bordered
                components={{
                  body: {
                    row: Row,
                  },
                }}
                rowKey="key"
                columns={columnsData({ handleChange })}
                dataSource={items}
                footer={false}
                // showHeader={false}
                pagination={false}
              />
            </SortableContext>
          </DndContext>
          {/* <Button style={{ width: "100%" }} onClick={() => addField()}>
          Thêm field
        </Button> */}
          {/* {items.map((x, index) => (
          <div
            key={index}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "40px",
              marginTop: "16px",
            }}
          >
            <div
              style={{
                width: "calc(100% - 100px)",
                border: "1px solid rgb(218, 220, 224)",
                borderRadius: "8px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 24px",
                fontSize: "14px",
              }}
            >
              {x.label}
            </div>
            <div
              style={{
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              <Checkbox
                onClick={(e) => changeStatus(e.target.checked, x.code)}
                className="checkbox-size"
                style={{ marginRight: "4px" }}
                checked={x.status === "ACTIVE" ? true : false}
              />
              Hiển thị
            </div>
          </div>
        ))} */}
          <div
            className="checkCustomer__content__submit"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{ width: "100%", marginBottom: "24px" }}
              onClick={() => handleFinishFormCreate()}
            >
              Tạo biểu mẫu
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

export default CreateForm;
