import React, { useEffect, useState } from "react";
import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveMenu,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { notifyError, notifyWarning } from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { DatePicker, Select, Table } from "antd";
import moment from "moment";
import { columnsData } from "./data";
import PanigationAntStyled from "../../../components/layout/PanigationAntStyled";
const ChartBottom = () => {
  const [dataSource, setDataSource] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedForm, setSelectedForm] = useState(undefined);
  const [selectedRoles, setSelectedRoles] = useState(undefined);
  const [flagRole, setFlagRole] = useState(false);
  const [flagForm, setFlagForm] = useState(false);

  const [value, setValue] = useState(null);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 1000,
  });
  const getData = async () => {
    setLoading(true);
    try {
      let { data } = await api.get(
        `${API_URL}api/v1/reports/employees-monitoring`,
        {
          from_date: value
            ? value[0].format("YYYY-MM-DD")
            : moment().add(-1, "M").format("YYYY-MM-DD"),
          to_date: value
            ? value[1].format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          form: selectedForm,
          page: filter.page,
          limit: filter.limit,
          apply_role: selectedRoles,
        }
      );

      setLoading(false);
      setTotal(data.length);
      setDataSource(data);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const getFormData = async () => {
    try {
      let { data } = await api.get(`${API_URL}api/v1/forms`, {
        // status: "ACTIVE",
      });
      setDataForm(
        [...data].map((x) => {
          return { label: x.name, value: x.id };
        })
      );
      setSelectedForm(data[0]?.id);
      setFlagForm(true);
    } catch (e) {
      setFlagForm(true);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getFormData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const onChangePaging = (page, pageSize) => {
    setFilter({
      page: page,
      limit: pageSize,
    });
  };

  const getRoles = async () => {
    try {
      let { data } = await api.get(`${API_URL}api/v1/roles/global-system`, {
        status: "ACTIVE",
      });
      let rolesFake = [];
      rolesFake = [...data].map((x) => {
        return { label: x.name, value: x.key };
      });
      setRoles(rolesFake);

      setSelectedRoles(rolesFake?.find((x) => x.value === "SALE")?.value);
      setFlagRole(true);
    } catch (e) {
      setFlagRole(true);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getRoles();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    flagRole && flagForm && getData();
  }, [value, selectedForm, filter, selectedRoles]);
  useEffect(() => {
    getFormData();
    getRoles();
  }, []);
  const disabledDate = (current) => {
    return (
      current &&
      (current < moment().add(-365, "d").endOf("day") ||
        current > moment().endOf("day"))
    );
  };

  return (
    <div
      style={{
        background: "#fff",
        padding: "8px",
        borderRadius: "8px",
        marginTop: "16px",
      }}
    >
      <p
        style={{
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          fontWeight: "bold",
          
        }}
      >
        Bảng theo dõi khối lượng công việc của nhân viên CSKH
      </p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "8px",
          marginTop:"8px"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "886px",
          }}
        >
          <Select
            allowClear
            options={roles}
            placeholder="Tất cả"
            value={selectedRoles}
            onChange={(value) => setSelectedRoles(value)}
            style={{ width: "150px" }}
          />
          <Select
            allowClear
            options={dataForm}
            placeholder="Tất cả"
            value={selectedForm}
            onChange={(value) => setSelectedForm(value)}
            style={{ width: "480px" }}
          />
          <DatePicker.RangePicker
            style={{ width: "240px" }}
            value={value}
            disabledDate={disabledDate}
            onChange={(val) => {
              setValue(val);
            }}
            changeOnBlur
            placeholder={[
              moment().add(-1, "M").format("YYYY-MM-DD"),
              moment().format("YYYY-MM-DD"),
            ]}
          />
        </div>
      </div>
      <Table
        bordered
        style={{ minHeight: "600px" }}
        className="custom__column1 newpadding"
        rowKey="_id"
        loading={loading}
        columns={columnsData({})}
        dataSource={dataSource}
        pagination={false}
      />
      {/* <PanigationAntStyled
        style={{ padding: "24px 0 24px 0" }}
        current={filter.page}
        pageSize={filter.limit}
        showSizeChanger
        onChange={onChangePaging}
        showTotal={() => `Tổng ${total}`}
        total={total}
      /> */}
    </div>
  );
};

export default ChartBottom;
