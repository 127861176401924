/* eslint-disable */

import { Switch } from "antd";
import moment from "moment";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";

export const columnsData = ({ handleChangeStatus, handleUpdate }, pageMenu) => {
  return [
    {
      title: "Tên nhóm",
      dataIndex: "name",
      key: "name",
      width: "27%",
      render: (text, record, index) => {
        return (
          <div
            style={{ color: "rgb(56, 114, 174)", fontWeight: "bold",cursor:"pointer" }}
            onClick={() => pageMenu.update && handleUpdate(record)}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Mã nhóm",
      dataIndex: "code",
      key: "code",
      width: "16%",
      render: (text, record, index) => {
        return <div style={{ color: "rgb(56, 114, 174)" }}>{text}</div>;
      },
    },
    {
      title: "Người tạo",
      dataIndex: "created_by_user_info",
      key: "created_by_user_info",
      width: "16%",
      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)" }}>
            {record?.created_by_user_info?.name}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_at",
      key: "created_at",
      width: "16%",
      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)" }}>
            {record?.created_at &&
              moment(record?.created_at).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      title: "Trạng thái hoạt động",
      dataIndex: "status",
      key: "status ",
      width: "15%",
      render: (text, record, index) => {
        return (
          <Switch
            checked={text === "ACTIVE" ? true : false}
            disabled={!pageMenu.update}
            onChange={(e) => pageMenu.update && handleChangeStatus(e, record)}
          />
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "status",
      key: "status",
      width: "10%",
      align: "center",
      render: (text, record, index) => {
        return (
          <PiDotsThreeOutlineVerticalBold
            size={24}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
  ];
};
