import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveMenu,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { notifyError, notifyWarning } from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { DatePicker, Select } from "antd";
import moment from "moment";

const ChartMultiBar = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [filter, setFilter] = useState({
    limit: 10,
  });
  const getData = async () => {
    try {
      let { data } = await api.get(
        `${API_URL}api/v1/reports/top-reward-clients`,
        {
          limit: filter.limit,
          from_date: value
            ? value[0].format("YYYY-MM-DD")
            : moment().add(-1, "M").format("YYYY-MM-DD"),
          to_date: value
            ? value[1].format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        }
      );
      setDataSource(data);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [value, filter]);

  const disabledDate = (current) => {
    return (
      current &&
      (current < moment().add(-365, "d").endOf("day") ||
        current > moment().endOf("day"))
    );
  };
  return (
    <div style={{ position: "relative", padding: "8px", background: "#fff" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          position: "relative",
          top: "32px",
          zIndex: 102,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "14px" }}>Hiển thị:&nbsp;&nbsp;</p>
          <Select
            defaultValue={10}
            onChange={(e) => setFilter({ ...filter, limit: e })}
            value={filter.limit}
            style={{ width: "60px" }}
            options={[
              {
                value: 10,
                label: "10",
              },
              {
                value: 20,
                label: "20",
              },
              {
                value: 30,
                label: "30",
              },
            ]}
          />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <DatePicker.RangePicker
          style={{ width: "240px" }}
          value={value}
          placeholder={[
            moment().add(-1, "M").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ]}
          onChange={(val) => {
            setValue(val);
          }}
          changeOnBlur
        />
      </div>
      {dataSource && dataSource.length > 0 && (
        <div
          style={{
            position: "absolute",
            height:
              dataSource?.length === 0
                ? "0px"
                : filter.limit === 10
                ? "391px"
                : filter.limit === 20
                ? "891px"
                : "1391px",
            width: "120px",
            top: "93px",
            zIndex: "100",
          }}
        >
          {dataSource.map((x, index) => (
            <div
              style={{
                height:
                  filter.limit === 10
                    ? "39px"
                    : filter.limit === 20
                    ? "44.5px"
                    : "46.35px",
                width: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              <p
                className="hoverChangeColor"
                style={{
                  fontSize: "12px",
                  marginRight:
                    filter.limit === 10
                      ? "6px"
                      : filter.limit === 20
                      ? "3px"
                      : "0px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/clients/${x.client_id}`)}
              >
                {x.client_name}
              </p>
            </div>
          ))}
        </div>
      )}
      <Chart
        type="bar"
        width="100%"
        height={
          dataSource?.length === 0
            ? 500
            : filter.limit === 10
            ? 500
            : filter.limit === 20
            ? 1000
            : 1500
        }
        series={[
          {
            name: "Lợi nhuận (USD)",
            data: dataSource
              ? dataSource.map((x) => {
                  return x.reward_usd;
                })
              : [],
          },
          {
            name: "Khối lượng giao dịch (lot)",
            data: dataSource
              ? dataSource.map((x) => {
                  return x.volum_lots;
                })
              : [],
          },
        ]}
        options={{
          colors: ["rgb(46,83,127)", "rgb(122,203,254)"],

          title: {
            text: "Bảng xếp hạng khách hàng lớn",
            style: {
              fontSize: "16px",
            },
          },
          grid: {
            borderColor: "#C4C4C4",
          },
          noData: {
            text: loading ? "Đang tải dữ liệu" : "Không có dữ liệu",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "14px",
              fontFamily: undefined,
            },
          },

          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: dataSource
              ? dataSource.map((x) => {
                  return x.client_name;
                })
              : [],
          },
          yaxis: {},
          chart: {
            events: {
              mounted: (chart) => {
                chart.windowResizeHandler();
              },
            },
            fontFamily: "open sans, Helvetica, Arial, sans-serif",
            toolbar: {
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                customIcons: [],
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ChartMultiBar;
