import {
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Table,
  Modal,
  Switch,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { columnsData } from "./data";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { AiOutlineSearch } from "react-icons/ai";
import { FiPlusCircle } from "react-icons/fi";

import { BiSave } from "react-icons/bi";
import moment from "moment";
import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveMenu,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import OverlaySpinner from "../../../components/overlaySpinner/OverlaySpinner";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import { UserContext } from "../../../context/context";
const RebateManager = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(false);
  const [status, setStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [form] = Form.useForm();
  const [formCreate] = Form.useForm();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    q: undefined,
    status: undefined,
    role: undefined,
    from_date: undefined,
    to_date: undefined,
  });
  const getData = async () => {
    try {
      let { data } = await api.get(`${API_URL}api/v1/rebate-levels`, {
        ...filter,
      });
      setDataSource(data);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishForm = (e) => {
    console.log(e);
    setFilter({
      q: e?.q?.length > 0 ? e?.q?.trim() : undefined,
      status: e?.status,
      role: e?.role,
      from_date: e?.date ? moment(e.date[0]).format("YYYY-MM-DD") : undefined,
      to_date: e?.date ? moment(e.date[1]).format("YYYY-MM-DD") : undefined,
    });
  };

  const handleFinishFormCreate = async (value) => {
    console.log(value);
    try {
      let { data } = await api.post(`${API_URL}api/v1/rebate-levels`, {
        name: value?.name,
        description: value?.description,
        level: Number(value?.level),

        status: status ? "ACTIVE" : "INACTIVE",
      });
      setOpenModal(false);
      setType(false);
      setStatus(false);
      formCreate.resetFields();
      notifySuccess("Tạo mức chiết khấu thành công!");
      getData();
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormCreate(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishFormUpdate = async (value) => {
    try {
      let { data } = await api.put(
        `${API_URL}api/v1/rebate-levels/${selectedRecord.id}`,
        {
          name: value?.name,
          description: value?.description,
          level: Number(value?.level),

          status: status ? "ACTIVE" : "INACTIVE",
        }
      );
      setOpenModal(false);
      setType(false);
      setStatus(false);
      setSelectedRecord(undefined);
      formCreate.resetFields();
      notifySuccess("Cập nhật mức chiết khấu thành công!");
      getData();
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormUpdate(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13 || event.key == "Enter") {
      form.submit();
    }
  };

  const handleOpenModal = (record) => {
    setSelectedRecord(record);
    formCreate.setFieldsValue({
      name: record?.name,
      level: record?.level,
      status: record?.status,
      description: record?.description,
    });
    setStatus(record?.status);
    setOpenModal(true);
    setType(true);
  };

  const handleOpenDelete = (record) => {
    setSelectedRecord(record);
    setOpenModalDelete(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      let { data } = await api.delete(
        `${API_URL}api/v1/rebate-levels/${selectedRecord.id}`
      );

      notifySuccess("Xóa thành công!");
      setLoading(false);
      getData();
      setOpenModalDelete(false);
      setSelectedRecord(undefined);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleDelete();
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleChangeStatus = async (value, record) => {
    setLoading(true);
    try {
      let { data } = await api.put(
        `${API_URL}api/v1/rebate-levels/${record.id}`,
        {
          status: value ? "ACTIVE" : "INACTIVE",
        }
      );
      let newList = [...dataSource].map((x) =>
        x.id === record.id ? { ...x, status: value ? "ACTIVE" : "INACTIVE" } : x
      );
      setDataSource(newList);
      notifySuccess("Đổi trạng thái thành công!");
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleChangeStatus(value, record);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const { state, dispatch } = useContext(UserContext);

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/rebate-level");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);

  useEffect(() => {
    pageMenu.list && getData();
  }, [pageMenu, filter]);

  return (
    pageMenu.list && (
      <div
        className="userSystem"
        onKeyDown={handleKeyUp}
        tabIndex="0"
        style={{ outline: "none" }}
      >
        <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
        <Modal
          open={openModal}
          onCancel={(e) => {
            setOpenModal(false);
            formCreate.resetFields();
            setStatus(false);
            setType(false);
            setSelectedRecord(undefined);
          }}
          footer={null}
          centered
        >
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {!type ? "Tạo mới mức chiết khấu" : "Chỉnh mức chiết khấu"}
          </div>
          <Form
            layout="vertical"
            form={formCreate}
            onFinish={type ? handleFinishFormUpdate : handleFinishFormCreate}
            style={{ marginTop: "16px" }}
            initialValues={{ status: true }}
          >
            <Form.Item
              label="Thông tin mức chiết khấu"
              name="name"
              placeholder="Nhập thông tin mức chiết khấu"
              style={{ width: "100%" }}
              required
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mức chiết khấu (%)"
              name="level"
              placeholder="Nhập mức chiết khấu"
              style={{ width: "100%" }}
              required
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Mô tả"
              name="description"
              placeholder="Nhập mô tả"
              style={{ width: "100%" }}
            >
              <TextArea />
            </Form.Item>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "100%" }}>
                <Switch checked={status} onChange={(e) => setStatus(e)} />
                &nbsp;Trạng thái hoạt động
              </div>
              <Button
                className="userSystem__search__button"
                style={{
                  marginTop: "-24px",
                  background: "rgb(56, 114, 174)",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => formCreate.submit()}
              >
                <BiSave size={20} />
                &nbsp; Lưu
              </Button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={openModalDelete}
          onCancel={(e) => {
            setOpenModalDelete(false);
            setSelectedRecord(undefined);
          }}
          onOk={() => {
            handleDelete();
          }}
          centered
        >
          <div style={{ fontWeight: "bold", color: "red" }}>
            Bạn chắc chắn muốn xóa: "{selectedRecord?.name}"
          </div>
        </Modal>
        <Form
          className="userSystem__search"
          layout="vertical"
          form={form}
          onFinish={handleFinishForm}
        >
          <Form.Item
            label="Tìm kiếm"
            name="q"
            className="userSystem__search__input"
            style={{
              width: pageMenu.create
                ? "calc(100% - 384px)"
                : "calc(100% - 256px)",
            }}
          >
            <Input placeholder="Nhập thông tin mức chiết khấu" />
          </Form.Item>

          <Form.Item
            label="Trạng thái"
            name="status"
            className="userSystem__search__select"
          >
            <Select
              allowClear
              options={[
                { value: "ACTIVE", label: "Hoạt động" },
                { value: "INACTIVE", label: "Dừng hoạt động" },
              ]}
              placeholder="Tất cả"
            />
          </Form.Item>

          <Button
            className="userSystem__search__button"
            onClick={() => form.submit()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AiOutlineSearch size={20} />
            &nbsp;Tìm kiếm
          </Button>
          {pageMenu.create && (
            <Button
              className="userSystem__search__button"
              onClick={() => setOpenModal(true)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FiPlusCircle size={20} />
              &nbsp;Tạo mới
            </Button>
          )}
        </Form>
        <Table
          bordered
          className="newpadding"
          rowKey="_id"
          columns={columnsData(
            { handleChangeStatus, handleOpenModal, handleOpenDelete },
            pageMenu
          )}
          dataSource={dataSource}
          pagination={false}
        />
      </div>
    )
  );
};

export default RebateManager;
