import {
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Table,
  Modal,
  Switch,
  Popover,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { columnsData } from "./data";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { AiOutlineSearch, AiOutlineReload } from "react-icons/ai";
import { FiPlusCircle } from "react-icons/fi";

import { BiSave } from "react-icons/bi";
import moment, { isMoment } from "moment";
import {
  localGetMenu,
  localRemoveMenu,
  localGetRefreshToken,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import OverlaySpinner from "../../../components/overlaySpinner/OverlaySpinner";
import axios from "axios";
import {
  exportExcel,
  validateEmail,
} from "../../../utils/helpers/functions/textUtils";
import PanigationAntStyled from "../../../components/layout/PanigationAntStyled";
import { newValidate } from "../../../utils/helpers/functions/textUtils";
import SvgExcel from "../../../assets/svg/SvgExcel";
import SvgFile from "../../../assets/svg/SvgFile";
import { UserContext } from "../../../context/context";
const { RangePicker } = DatePicker;
const Clients = () => {
  const [dataSource, setDataSource] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [form] = Form.useForm();
  const [supports, setSupports] = useState(false);
  const [total, setTotal] = useState(0);
  const [formCreate] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openImport, setOpenImport] = useState(false);
  const [fileTest, setFileTest] = useState(undefined);
  const [openDownloadSample, setOpenDownloadSample] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);

  const [filter, setFilter] = useState({
    q: undefined,
    groups: undefined,
    supporter: undefined,
    affiliation: undefined,
    from_volumn_lots: undefined,
    to_volumn_lots: undefined,
    from_last_trade_at: undefined,
    to_last_trade_at: undefined,
    sort_by: undefined,
    sort_type: undefined,
    is_last_trade_at: undefined,
    from_reward_usd: undefined,
    to_reward_usd: undefined,
    page: 1,
    limit: 10,
  });
  const getData = async () => {
    setLoading(true);

    try {
      let data = await api.get(`${API_URL}api/v1/clients`, {
        ...filter,
      });
      let fakeData = [
        ...data?.data?.map((x) => {
          return {
            ...x,
            expand: false,
          };
        }),
      ];
      setDataSource(fakeData);
      setTotal(data?.metadata?.totalItems);
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishForm = (e) => {
    setFilter({
      ...filter,
      page: 1,
      q: e?.q?.length > 0 ? e?.q?.trim() : undefined,
      groups: e?.groups?.length > 0 ? e?.groups?.toString() : undefined,
      supporter: e?.supporter,
      affiliation: e?.affiliation,
      from_volumn_lots: e?.from_volumn_lots,
      to_volumn_lots: e?.to_volumn_lots,
      from_last_trade_at: e?.from_last_trade_at
        ? e?.from_last_trade_at?.format("YYYY-MM-DD")
        : e?.to_last_trade_at
        ? "2007-01-01"
        : undefined,
      to_last_trade_at: e?.to_last_trade_at
        ? e?.to_last_trade_at?.format("YYYY-MM-DD")
        : e?.from_last_trade_at
        ? moment().format("YYYY-MM-DD")
        : undefined,
      is_last_trade_at: e?.is_last_trade_at,
      from_reward_usd: e?.from_reward_usd,
      to_reward_usd: e?.to_reward_usd,
    });
  };

  const handleFinishFormCreate = async (value) => {
    setLoading(true);
    let userId = undefined;
    try {
      let dataUser = await api.get(`${API_URL}api/v1/users/profile`, {});
      userId = dataUser?.data?.id;
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormCreate(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
    if (!userId) {
      notifyError("Không lấy được thông tin của support");
      return;
    }
    try {
      let { data } = await api.post(`${API_URL}api/v1/clients`, {
        name: value?.name,
        email: value?.email,
        phone: value?.phone?.trim(),
        gender: value?.gender,
        supporter: userId,
        // dob: value?.dob ? moment(value?.dob).format("YYYY-MM-DD") : undefined,
        dob: value?.dob ? Number(value?.dob) : undefined,
        groups: value?.group,
        zalo_phone: value?.zalo_phone?.trim(),
        zalo_name: value?.zalo_name,
        telegram_username: value?.telegram_username,
      });
      setLoading(false);
      setOpenModal(false);
      formCreate.resetFields();
      notifySuccess("Tạo người dùng mới thành công!");
      getData();
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormCreate(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleChangeStatus = async (value, record) => {
    setLoading(true);
    try {
      let { data } = await api.put(`${API_URL}api/v1/users/${record.id}`, {
        status: value ? "ACTIVE" : "INACTIVE",
      });
      let newList = [...dataSource].map((x) =>
        x.id === record.id ? { ...x, status: value ? "ACTIVE" : "INACTIVE" } : x
      );
      setDataSource(newList);
      notifySuccess("Đổi trạng thái thành công!");
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleChangeStatus(value, record);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleSortByVolume = () => {
    let fake = { ...filter };
    setFilter({
      ...filter,
      sort_by: "volumn_lots",
      sort_type:
        fake?.sort_by === "volumn_lots"
          ? fake?.sort_type === "ASC"
            ? "DESC"
            : "ASC"
          : "ASC",
    });
  };

  const handleSortByUSD = () => {
    let fake = { ...filter };
    setFilter({
      ...filter,
      sort_by: "reward_usd",
      sort_type:
        fake?.sort_by === "reward_usd"
          ? fake?.sort_type === "ASC"
            ? "DESC"
            : "ASC"
          : "ASC",
    });
  };

  const handleSortByName = () => {
    let fake = { ...filter };
    setFilter({
      ...filter,
      sort_by: "name",
      sort_type:
        fake?.sort_by === "name"
          ? fake?.sort_type === "ASC"
            ? "DESC"
            : "ASC"
          : "ASC",
    });
  };

  const handleSortByEmail = () => {
    let fake = { ...filter };
    setFilter({
      ...filter,
      sort_by: "email",
      sort_type:
        fake?.sort_by === "email"
          ? fake?.sort_type === "ASC"
            ? "DESC"
            : "ASC"
          : "ASC",
    });
  };

  const handleSortByCount = () => {
    let fake = { ...filter };
    setFilter({
      ...filter,
      sort_by: "count_trade_account",
      sort_type:
        fake?.sort_by === "count_trade_account"
          ? fake?.sort_type === "ASC"
            ? "DESC"
            : "ASC"
          : "ASC",
    });
  };

  const handleSortByDate = () => {
    let fake = { ...filter };
    setFilter({
      ...filter,
      sort_by: "last_trade_at",
      sort_type:
        fake?.sort_by === "last_trade_at"
          ? fake?.sort_type === "ASC"
            ? "DESC"
            : "ASC"
          : "ASC",
    });
  };

  const getSupport = async () => {
    setLoading(true);

    try {
      let { data } = await api.get(`${API_URL}api/v1/users`, {
        status: "ACTIVE",
      });

      let groupsFake = [];
      groupsFake = [...data].map((x) => {
        return { label: x.name, value: x.id };
      });
      setSupports(groupsFake);
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getSupport();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getDataGroups = async () => {
    setLoading(true);

    try {
      let { data } = await api.get(`${API_URL}api/v1/groups`, {});
      let groupsFake = [];
      groupsFake = [...data].map((x) => {
        return { label: x.name, value: x.id };
      });
      setLoading(false);

      setGroups(groupsFake);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getDataGroups();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const onChangePaging = (page, pageSize) => {
    setFilter({
      ...filter,

      page: page,
      limit: pageSize,
    });
    setSelectedClient([]);
  };

  const handleSample = async () => {
    setLoading(true);

    let file_path = undefined;
    try {
      let { data: data_filepath } = await api.post(
        `${API_URL}api/v1/exports/sample`,
        {
          module: "ClientSample",
        }
      );
      file_path = data_filepath?.file_path;
      if (!file_path) {
        notifyError("Lỗi không có file path");
        setLoading(false);
        return;
      } else {
        try {
          let res = await api.postDownload(
            `${API_URL}api/v1/exports/download`,
            {
              file_path: file_path,
            }
          );
          exportExcel(res, "Sample.xlsx");
          setLoading(false);
        } catch (e) {
          if (e.statusCode < 500) {
            notifyWarning(e.message);
          } else {
            notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
          }
          setLoading(false);
        }
      }
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleExport();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleExport = async () => {
    setLoading(true);
    let exportFilter = JSON.stringify({ ...filter, groups: undefined })
      .replaceAll(`"`, ``)
      .replaceAll(":", "=")
      .replaceAll(",", "&")
      .replaceAll("{", "")
      .replaceAll("}", "");
    if (filter.groups) {
      exportFilter =
        exportFilter +
        (exportFilter.length === 0 ? "groups=" : "&groups=") +
        JSON.stringify({ ...filter }.groups)
          .replaceAll(`"`, ``)
          .replaceAll(":", "=")
          .replaceAll("{", "")
          .replaceAll("}", "");
    }
    let file_path = undefined;
    try {
      let { data: data_filepath } = await api.post(`${API_URL}api/v1/exports`, {
        total_parts: 1,
        module: "Client",
        query_params: exportFilter,
      });
      file_path = data_filepath?.file_path;
      if (!file_path) {
        notifyError("Lỗi không có file path");
        setLoading(false);
        return;
      } else {
        try {
          let res = await api.postDownload(
            `${API_URL}api/v1/exports/download`,
            {
              file_path: file_path,
            }
          );
          exportExcel(res, file_path.slice(file_path.indexOf("Client")));

          try {
            let { data: data_delete } = await api.post(
              `${API_URL}api/v1/exports/delete`,
              {
                file_path: file_path,
              }
            );
            setLoading(false);
          } catch (e) {
            setLoading(false);
            notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
          }
        } catch (e) {
          if (e.statusCode < 500) {
            notifyWarning(e.message);
          } else {
            notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
          }
          setLoading(false);
        }
      }
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleExport();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleExpand = (record) => {
    let fakeData = [
      ...dataSource?.map((x) =>
        x.id === record.id
          ? {
              ...x,
              expand: !x.expand,
            }
          : x
      ),
    ];
    setDataSource(fakeData);
  };
  const handleSync = async (value) => {
    if (!value && selectedClient.length === 0) {
      notifyWarning("Vui lòng chọn ít nhất một record");
      return;
    }

    setLoading(true);

    let listEmail = [];
    for (let i = 0; i < selectedClient.length; i++) {
      for (let j = 0; j < dataSource.length; j++) {
        if (selectedClient[i] === dataSource[j].id) {
          listEmail.push(dataSource[j].email);
          continue;
        }
      }
    }
    listEmail = [...new Set(listEmail)];
    try {
      let { data } = await api.post(`${API_URL}api/v1/clients/sync`, {
        emails: value ? [value] : listEmail,
      });
      notifySuccess("Đồng bộ hoàn tất");
      getData();
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleSync(value);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleCloseOpenImport = () => {
    setOpenImport(false);
    setFileTest(undefined);
    let file = document.querySelector("#importFile");
    if (file) {
      file.value = "";
    }
  };
  const onFinishImportFile = async () => {
    if (!fileTest) {
      notifyWarning("Vui lòng chọn file");
    } else {
      setLoading(true);
      try {
        let { data } = await api.postMultipartClient(
          `${API_URL}api/v1/imports`,
          {
            file: fileTest,
          }
        );
        notifySuccess("Nhập file thành công!");
        setLoading(false);
        setOpenImport(false);
        setFileTest(undefined);
        let file = document.querySelector("#importFile");
        if (file) {
          file.value = "";
        }
        getData();
      } catch (e) {
        if (e.statusCode === 401) {
          try {
            let { data } = await axios({
              method: "POST", //you can set what request you want to be
              url: `${API_URL}api/v1/auth/refresh-token`,
              headers: {
                Authorization: "Bearer " + localGetRefreshToken(),
              },
            });

            localSaveToken(data?.data?.accessToken);
            localSaveRefreshToken(data?.data?.refreshToken);
            onFinishImportFile();
          } catch (e) {
            navigate("/login");
            localRemoveRefreshToken();
            localRemoveToken();
          }
        } else if (e.statusCode === 403) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        } else {
          if (e.statusCode < 500) {
            notifyWarning(e.message);
          } else {
            notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
          }
          setLoading(false);
        }
      }
    }
  };
  const importFileCallback = () => {
    setOpenImport(true);
  };
  const handleChangeFile = (e) => {
    setFileTest(e.target.files[0]);
  };

  const rowSelection = {
    selectedRowKeys: selectedClient,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedClient(selectedRowKeys);
    },
  };

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/clients");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [localGetMenu()]);
  useEffect(() => {
    pageMenu.list && getData();
  }, [pageMenu, filter]);

  useEffect(() => {
    pageMenu.list && getDataGroups();
  }, [pageMenu]);

  useEffect(() => {
    pageMenu.list && getSupport();
  }, [pageMenu]);

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      form.submit();
    }
  };

  return (
    pageMenu.list && (
      <div
        className="clients"
        onKeyDown={handleKeyUp}
        tabIndex="0"
        style={{ outline: "none" }}
      >
        <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
        <Modal
          open={openDownloadSample}
          title={""}
          maskClosable={false}
          className="modalEditSender"
          onCancel={() => setOpenDownloadSample(false)}
          onOk={() => {
            handleSample();
            setOpenDownloadSample(false);
          }}
          width={420}
          centered
        >
          Xác nhận tải file mẫu
        </Modal>
        <Modal
          open={openDownload}
          title={""}
          maskClosable={false}
          className="modalEditSender"
          onCancel={() => setOpenDownload(false)}
          onOk={() => {
            handleExport();
            setOpenDownload(false);
          }}
          width={420}
          centered
        >
          Xác nhận xuất file excel
        </Modal>
        <Modal
          open={openImport}
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <SvgExcel /> <span style={{ marginLeft: "8px" }}>Nhập file</span>
            </div>
          }
          maskClosable={false}
          className="modalEditSender"
          onCancel={() => handleCloseOpenImport()}
          footer={null}
          width={420}
          centered
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label
              htmlFor="importFile"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                background: "#259c58",
                height: "41px",
                width: "calc(50% - 4px)",
              }}
            >
              <SvgFile />
              <span style={{ marginLeft: "8px", color: "#fff" }}>
                Chọn file
              </span>
            </label>
            <input
              type="file"
              id="importFile"
              onChange={(e) => handleChangeFile(e)}
              style={{ display: "none" }}
            />

            <div
              onClick={() => onFinishImportFile()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                background: "#27aae1",
                height: "41px",
                width: "calc(50% - 4px)",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Xác nhận
            </div>
          </div>
          {fileTest && <div>{fileTest.name}</div>}
        </Modal>
        <Modal
          open={openModal}
          onCancel={(e) => {
            setOpenModal(false);
            formCreate.resetFields();
          }}
          footer={null}
          centered
        >
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Tạo mới khách hàng
          </div>
          <Form
            layout="vertical"
            form={formCreate}
            onFinish={handleFinishFormCreate}
            style={{ marginTop: "16px" }}
            initialValues={{
              gender: "MALE",
            }}
          >
            <Form.Item
              label="Họ tên"
              name="name"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Vui lòng không bỏ trống!" }]}
            >
              <Input placeholder="Nhập họ tên" />
            </Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "-8px",
              }}
            >
              <Form.Item
                label="Giới tính"
                name="gender"
                style={{ width: "calc(50% - 16px)" }}
              >
                <Select
                  allowClear
                  options={[
                    { label: "Nam", value: "MALE" },
                    { label: "Nữ", value: "FEMALE" },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Năm sinh"
                name="dob"
                style={{ width: "calc(50% - 16px)" }}
                rules={[
                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        if (!value || (value > 1900 && value < 2023)) {
                          resolve();
                        } else {
                          reject("Vui lòng nhập đúng năm sinh");
                        }
                      });
                    },
                  },
                ]}
              >
                <Input placeholder="Nhập năm sinh" />
              </Form.Item>
            </div>
            <Form.Item
              label="Email đăng ký tài khoản Exness"
              name="email"
              style={{ width: "100%", marginTop: "-8px" }}
              rules={[
                { required: true, message: "Vui lòng không bỏ trống" },

                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      if (value && validateEmail(value.trim())) {
                        resolve();
                      } else {
                        reject("Vui lòng nhập đúng định dạng email");
                      }
                    });
                  },
                },
              ]}
            >
              <Input placeholder="Nhập email" />
            </Form.Item>

            <Form.Item
              label="Username telegram"
              name="telegram_username"
              style={{ width: "100%", marginTop: "-8px" }}
            >
              <Input placeholder="Nhập username telegram" />
            </Form.Item>
            <Form.Item
              label="Số điện thoại"
              name="phone"
              style={{ width: "100%", marginTop: "-8px" }}
              rules={[
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      if (!value || newValidate(value.trim())) {
                        resolve();
                      } else {
                        reject("Vui lòng nhập đúng định dạng số điện thoại");
                      }
                    });
                  },
                },
              ]}
            >
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
            <Form.Item
              label="Số điện thoại - Zalo"
              name="zalo_phone"
              style={{ width: "100%", marginTop: "-8px" }}
              rules={[
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      if (!value || newValidate(value.trim())) {
                        resolve();
                      } else {
                        reject("Vui lòng nhập đúng định dạng số điện thoại");
                      }
                    });
                  },
                },
              ]}
            >
              <Input placeholder="Nhập số điện thoại zalo" />
            </Form.Item>
            <Form.Item
              label="Tên zalo"
              name="zalo_name"
              style={{ width: "100%", marginTop: "-8px" }}
            >
              <Input placeholder="Nhập tên zalo" />
            </Form.Item>

            <Form.Item
              label="Nhóm"
              name="group"
              style={{ width: "100%", marginTop: "-8px" }}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                allowClear
                options={groups}
                placeholder="Chọn nhóm"
              />
            </Form.Item>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <Button
                className="clients__search__button"
                style={{
                  marginTop: "-8px",
                  background: "rgb(56, 114, 174)",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => formCreate.submit()}
              >
                <BiSave size={20} />
                &nbsp; Tạo mới
              </Button>
            </div>
          </Form>
        </Modal>
        <Form
          className="clients__search"
          style={{ display: "flex", flexWrap: "wrap" }}
          layout="vertical"
          form={form}
          onFinish={handleFinishForm}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Form.Item
              label="Tìm kiếm"
              name="q"
              style={{
                width: pageMenu.create
                  ? "calc(100% - 60% - 100px)"
                  : "calc(100% - 60% - 228px)",
              }}
            >
              <Input placeholder="Nhập họ tên, email" />
            </Form.Item>
            <Form.Item
              label="Nhóm"
              name="groups"
              style={{ width: "calc(15% + 68px)" }}
            >
              <Select
                mode="multiple"
                maxTagCount={1}
                allowClear
                options={groups}
                placeholder="Tất cả"
              />
            </Form.Item>
            <Form.Item
              label="Nhân viên chăm sóc"
              name="supporter"
              style={{ width: "15%" }}
            >
              <Select allowClear options={supports} placeholder="Tất cả" />
            </Form.Item>
            <Form.Item
              label="Email dưới link DNI"
              name="affiliation"
              style={{ width: "15%" }}
            >
              <Select
                allowClear
                options={[
                  { label: "Có", value: true },
                  { label: "Không", value: false },
                ]}
                placeholder="Tất cả"
              />
            </Form.Item>
            <Form.Item
              label="Phát sinh giao dịch"
              name="is_last_trade_at"
              style={{ width: "15%" }}
            >
              <Select
                allowClear
                options={[
                  { label: "Có", value: true },
                  { label: "Không", value: false },
                ]}
                placeholder="Tất cả"
              />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "-16px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                width: "calc(33% - 129px)",
              }}
            >
              <div style={{ width: "100%" }}>Khối lượng giao dịch</div>
              <Form.Item
                style={{ width: "calc(50% - 12px)" }}
                name="from_volumn_lots"
                rules={[
                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        if (!value || Number(value)) {
                          resolve();
                        } else {
                          reject("Vui lòng chỉ nhập số");
                        }
                      });
                    },
                  },
                ]}
              >
                <Input placeholder="Từ" />
              </Form.Item>
              <div
                style={{
                  width: "24px",
                  marginBottom: "24px",
                  textAlign: "center",
                }}
              >
                -
              </div>
              <Form.Item
                style={{ width: "calc(50% - 12px)" }}
                name="to_volumn_lots"
                rules={[
                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        if (!value || Number(value)) {
                          resolve();
                        } else {
                          reject("Vui lòng chỉ nhập số");
                        }
                      });
                    },
                  },
                ]}
              >
                <Input placeholder="đến" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                width: "calc(33% - 129px)",
              }}
            >
              <div style={{ width: "100%" }}>Tổng lợi nhuận</div>
              <Form.Item
                style={{ width: "calc(50% - 12px)" }}
                name="from_reward_usd"
                rules={[
                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        if (!value || Number(value)) {
                          resolve();
                        } else {
                          reject("Vui lòng chỉ nhập số");
                        }
                      });
                    },
                  },
                ]}
              >
                <Input placeholder="Từ" />
              </Form.Item>
              <div
                style={{
                  width: "24px",
                  marginBottom: "24px",
                  textAlign: "center",
                }}
              >
                -
              </div>
              <Form.Item
                style={{ width: "calc(50% - 12px)" }}
                name="to_reward_usd"
                rules={[
                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        if (!value || Number(value)) {
                          resolve();
                        } else {
                          reject("Vui lòng chỉ nhập số");
                        }
                      });
                    },
                  },
                ]}
              >
                <Input placeholder="đến" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                width: "calc(33% - 129px)",
              }}
            >
              <div style={{ width: "100%" }}>Lần giao dịch gần nhất</div>
              <Form.Item
                style={{ width: "calc(50% - 12px)" }}
                name="from_last_trade_at"
              >
                <DatePicker placeholder="Từ" style={{ width: "100%" }} />
              </Form.Item>
              <div
                style={{
                  width: "24px",
                  marginBottom: "24px",
                  textAlign: "center",
                }}
              >
                -
              </div>
              <Form.Item
                style={{ width: "calc(50% - 12px" }}
                name="to_last_trade_at"
              >
                <DatePicker placeholder="đến" style={{ width: "100%" }} />
              </Form.Item>
            </div>

            <Button
              className="clients__search__button"
              onClick={() => form.submit()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AiOutlineSearch size={20} />
              &nbsp;Tìm kiếm
            </Button>
            <Button
              className="clients__search__button"
              onClick={() => {
                form.resetFields();
                setFilter({
                  ...filter,
                  page: 1,
                  limit: 10,
                  q: undefined,
                  groups: undefined,
                  supporter: undefined,
                  affiliation: undefined,
                  from_volumn_lots: undefined,
                  to_volumn_lots: undefined,
                  from_last_trade_at: undefined,
                  to_last_trade_at: undefined,
                  to_reward_usd: undefined,
                  from_reward_usd: undefined,
                  is_last_trade_at: undefined,
                });
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AiOutlineReload size={20} />
              &nbsp;Refresh
            </Button>
            {pageMenu.create && (
              <Button
                className="clients__search__button"
                onClick={() => setOpenModal(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(56, 114, 174)",
                  color: "#fff",
                }}
              >
                <FiPlusCircle size={20} />
                &nbsp;Tạo mới
              </Button>
            )}
          </div>
        </Form>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            marginTop: "-12px",
            marginBottom: "8px",
          }}
        >
          <Popover
            placement="bottom"
            content={
              <div
                style={{
                  width: "120px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    padding: "2px 4px",
                  }}
                  className="hoverGray"
                  onClick={() => setOpenDownloadSample(true)}
                >
                  Tải file mẫu
                </div>
                {pageMenu.import && (
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => importFileCallback()}
                  >
                    Nhập file
                  </div>
                )}
                {pageMenu.export && (
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => setOpenDownload(true)}
                  >
                    Xuất file
                  </div>
                )}
              </div>
            }
            trigger="click"
          >
            <Button
              className="clients__search__button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: pageMenu.sync ? "8px" : "9px",
                background: "rgb(56, 114, 174)",
                color: "#fff",
              }}
            >
              Thao tác Excel
            </Button>
          </Popover>
          {pageMenu.sync && (
            <Button
              className="clients__search__button"
              onClick={() => handleSync()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "rgb(56, 114, 174)",
                color: "#fff",
              }}
            >
              Đồng bộ
            </Button>
          )}
        </div>
        <Table
          bordered
          className="custom__column1 newpadding"
          rowKey="_id"
          scroll={{ x: 1500 }}
          columns={columnsData(
            {
              handleChangeStatus,
              navigate,
              handleSortByVolume,
              handleSortByDate,
              handleSortByUSD,
              handleSortByName,
              handleSortByEmail,
              handleSortByCount,
              filter,
              handleSync,
              handleExpand,
            },
            pageMenu
          )}
          rowSelection={rowSelection}
          dataSource={dataSource}
          pagination={false}
        />
        <PanigationAntStyled
          style={{ padding: "0 0 24px 0" }}
          current={filter.page}
          pageSize={filter.limit}
          showSizeChanger
          onChange={onChangePaging}
          showTotal={() => `Tổng ${total}`}
          total={total}
        />
      </div>
    )
  );
};

export default Clients;
