import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveMenu,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { notifyError, notifyWarning } from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { DatePicker } from "antd";
import moment from "moment";
import { AiOutlineDollar } from "react-icons/ai";
const ChartTopLeft = () => {
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();

  const getData = async () => {
    setLoading(true);
    setDataSource([]);
    try {
      let { data } = await api.get(`${API_URL}api/v1/reports/reward-report`, {
        reward_date_from: value
          ? value[0].format("YYYY-MM-DD")
          : moment().add(-1, "M").format("YYYY-MM-DD"),
        reward_date_to: value
          ? value[1].format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      });
      let fakeDate = [...data.rewards].map((x) => {
        return [
          moment(x.reward_date, "YYYY-MM-DD").add(7, "h"),
          Number(x.reward).toFixed(0),
        ];
      });
      let fakeTotal = 0;

      for (let i = 0; i < data.rewards.length; i++) {
        fakeTotal = fakeTotal + Number(data.rewards[i].reward);
      }
      setLoading(false);

      setTotal(fakeTotal.toFixed(2));
      setDataSource(fakeDate);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return current && current > moment().endOf("day");
    }
    const tooLate =
      dates[0] &&
      (current.diff(dates[0], "days") > 365 || current > moment().endOf("day"));
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 365;
    return tooEarly || tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };
  useEffect(() => {
    getData();
  }, [value]);

  return (
    <div style={{ background: "#fff", padding: "8px", borderRadius: "8px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            marginLeft: "16px",
          }}
        >
          <AiOutlineDollar size={24} />
          &nbsp;Lợi nhuận
        </p>
        <DatePicker.RangePicker
          style={{ width: "240px" }}
          value={hackValue || value}
          disabledDate={disabledDate}
          placeholder={[
            moment().add(-1, "M").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ]}
          onCalendarChange={(val) => setDates(val)}
          onChange={(val) => setValue(val)}
          onOpenChange={onOpenChange}
          inputReadOnly
        />
      </div>
      <Chart
        type="area"
        width="100%"
        height={350}
        series={[{ name: "Lợi nhuận:", data: dataSource }]}
        options={{
          noData: {
            text: loading ? "Đang tải dữ liệu" : "Không có dữ liệu",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "14px",
              fontFamily: undefined,
            },
          },
          title: {
            align: "center",
            text: total + " USD",
            style: {
              fontSize: "16px",
            },
          },
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: false,
            },
            toolbar: {
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                customIcons: [],
              },
            },
            stroke: {
              show: true,

              width: 1,
            },
          },
          stroke: {
            show: true,

            width: 1,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
            style: "hollow",
          },
          xaxis: {
            type: "datetime",
            labels: {
              format: "dd/MM",
            },
          },

          tooltip: {
            x: {
              format: "dd/MM/yyyy",
            },
            y: {
              formatter: undefined,
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },
          fill: {
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100],
            },
          },
          selection: "one_month",
        }}
      />
    </div>
  );
};

export default ChartTopLeft;
