import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveMenu,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { notifyError, notifyWarning } from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { AiOutlineUser } from "react-icons/ai";
const ChartBottomRight = () => {
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    setDataSource([]);
    try {
      let { data } = await api.get(
        `${API_URL}api/v1/reports/affiliation-client-report`
      );

      setLoading(false);
      setTotal(data?.count_all);
      setDataSource([
        data?.count_affiliation || 0,
        data?.count_no_affiliation || 0,
      ]);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");

          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      style={{
        background: "#fff",
        padding: "8px",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            marginLeft: "16px",
          }}
        >
          <AiOutlineUser size={24} />
          &nbsp;Số lượng khách hàng
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          top: "60px",
          left: "24px",
          display: "flex",
          height: "calc(100% - 140px)",
          flexFlow: "column",
          justifyContent: "space-between",
          fontSize: "14px",
        }}
      >
        <div
          style={{
            fontSize: "26px",
            fontWeight: "bold",
          }}
        >
          {total}
        </div>
        <div>Dưới link:</div>

        <div
          style={{
            color: "rgb(39,99,244)",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {dataSource[0]}
        </div>
        <div>Không dưới link:</div>
        <div
          style={{
            color: "rgb(239,27,27)",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {dataSource[1]}
        </div>
      </div>
      <Chart
        style={{ marginLeft: "40px" }}
        type="donut"
        width="100%"
        height={360}
        series={dataSource}
        options={{
          colors: ["rgb(39,99,244)", "rgb(239,27,27)"],
          labels: ["Dưới link", "Không dưới link"],
          noData: {
            text: loading ? "Đang tải dữ liệu" : "Không có dữ liệu",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "26px",
              fontFamily: undefined,
            },
          },

          chart: {
            width: 380,
            type: "donut",
          },
          plotOptions: {
            pie: {
              customScale: 0.8,
              donut: {
                size: "40%",
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val.toFixed(2) + "%";
            },
          },
        }}
      />
    </div>
  );
};

export default ChartBottomRight;
