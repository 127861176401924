/* eslint-disable */

import { Switch } from "antd";
import moment from "moment";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";

export const columnsData = (
  { navigate, handleChangeStatus },
  pageMenu,
  roles
) => {
  return [
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              cursor: pageMenu.details && "pointer",
            }}
            onClick={() => navigate(`/roles/${record.id}`)}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text, record, index) => {
        return <div style={{ color: "rgb(56, 114, 174)" }}>{text}</div>;
      },
    },
    {
      title: "Chức danh",
      dataIndex: "inherited_global_role",
      key: "inherited_global_role",
      render: (text, record, index) => {
        return (
          <div>
            {
              roles?.find((x) => x.value === record?.inherited_global_role)
                ?.label
            }
          </div>
        );
      },
    },

    {
      title: "Trạng thái hoạt động",
      dataIndex: "status",
      key: "status ",
      render: (text, record, index) => {
        return (
          <Switch
            disabled={!pageMenu.update}
            checked={text === "ACTIVE" ? true : false}
            onChange={(e) => pageMenu.update && handleChangeStatus(e, record)}
          />
        );
      },
    },
    {
      title: "Người tạo",
      dataIndex: "created_by_user_info",
      key: "created_by_user_info",
      render: (text, record, index) => {
        return <div>{record?.created_by_user_info?.name}</div>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record, index) => {
        return <div>{moment(record?.created_at).format("DD-MM-YYYY")}</div>;
      },
    },
  ];
};
