/* eslint-disable */

import { Switch } from "antd";
import moment from "moment";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";

export const columnsData = ({ handleChangeStatus, navigate }, pageMenu) => {
  return [
    {
      title: "Tên biểu mẫu",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              cursor: pageMenu.details ? "pointer" : "unset",
            }}
            onClick={() =>
              pageMenu.details && navigate(`/form-details/${record.id}`)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Utm source",
      dataIndex: "utm_source",
      key: "utm_source",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Ngày chốt doanh thu",
      dataIndex: "end_form_answer_at",
      key: "end_form_answer_at",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
            }}
          >
            <div onClick={() => console.log("da push code moi nhat")}>
              {moment(record.end_form_answer_at).format("YYYY-MM-DD")}
            </div>
          </div>
        );
      },
    },
    {
      title: "Doanh thu (USD)",
      dataIndex: "total_revenue",
      key: "total_revenue",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
            }}
          >
            {text?.toFixed(2)}
          </div>
        );
      },
    },

    {
      title: "Người tạo",
      dataIndex: "created_by_user_info",
      key: "created_by_user_info",
      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)" }}>
            {record?.created_by_user_info?.name}
          </div>
        );
      },
    },
    {
      title: "Người cập nhật cuối",
      dataIndex: "updated_by_user_info",
      key: "updated_by_user_info",
      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)" }}>
            {record?.updated_by_user_info?.name}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "role",
      key: "role",
      render: (text, record, index) => {
        return (
          <div onClick={() => console.log("da push code moi nhat")}>
            {moment(record.created_at).format("YYYY-MM-DD HH:mm:ss")}
          </div>
        );
      },
    },
    {
      title: "Trạng thái hoạt động",
      dataIndex: "status",
      key: "status ",
      render: (text, record, index) => {
        return (
          <Switch
            checked={text === "ACTIVE" ? true : false}
            disabled={!pageMenu.update}
            onChange={(e) => pageMenu.update && handleChangeStatus(e, record)}
          />
        );
      },
    },
    // {
    //   title: "Thao tác",
    //   dataIndex: "status",
    //   key: "status",
    //   width: "15%",
    //   align: "center",
    //   render: (text, record, index) => {
    //     return (
    //       <PiDotsThreeOutlineVerticalBold
    //         size={24}
    //         style={{ cursor: "pointer" }}
    //       />
    //     );
    //   },
    // },
  ];
};
