/* eslint-disable */
import styled from "styled-components";
import { Pagination, PaginationProps } from "antd";

const PanigationAntStyled = ({ ...rest }) => {
  return <PaginationStyled {...rest} />;
};

export default PanigationAntStyled;

const PaginationStyled = styled(Pagination)`
  position: relative;
  width: 100%;
  text-align: right;
  display:flex;
  align-items:center;
  justify-content:right;
  .ant-pagination-total-text {
  
  }
  .ant-pagination-item-link {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`;
