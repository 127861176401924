import {
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Table,
  Modal,
  Switch,
  Popover,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import _debounce from "lodash/debounce";

import {
  columnsData,
  columnsDataAssign,
  columnsDataAssign2,
  columnsDataHistoryParticipant,
  columnsDataHistoryStatus,
  columnsDataHistorySupporter,
  columnsDataModal,
  columnsText,
  dataText,
} from "./data";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import {
  AiOutlineSearch,
  AiOutlineReload,
  AiFillCheckCircle,
} from "react-icons/ai";
import { BiSave, BiSolidErrorCircle } from "react-icons/bi";
import moment, { isMoment } from "moment";
import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveMenu,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import OverlaySpinner from "../../../components/overlaySpinner/OverlaySpinner";
import axios from "axios";
import PanigationAntStyled from "../../../components/layout/PanigationAntStyled";
import { UserContext } from "../../../context/context";
import { exportExcel } from "../../../utils/helpers/functions/textUtils";
import SvgExcel from "../../../assets/svg/SvgExcel";
import SvgFile from "../../../assets/svg/SvgFile";
const { RangePicker } = DatePicker;
const FormAnswer = () => {
  const [dataSource, setDataSource] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [openModalAssign, setOpenModalAssign] = useState(false);
  const [selectedSupporterAssign, setSelectedSupporterAssign] =
    useState(undefined);
  const [step, setStep] = useState(1);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [dataToAssign, setDataToAssign] = useState([]);
  const [selectedValueAssign, setSelectedValueAssign] = useState({
    form: undefined,
    roles: [],
  });
  const [dataRecordByEmail, setDataRecordByEmail] = useState(undefined);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const [openDownloadSample, setOpenDownloadSample] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [assignRecord, setAssignRecord] = useState(undefined);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSubmitAssign, setOpenSubmitAssign] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [form] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const [formAssign] = Form.useForm();
  const [dataSourceAssign, setDataSourceAssign] = useState([]);
  const [supports, setSupports] = useState(false);
  const [dataTargets, setDataTargets] = useState([]);
  const [calOpenModal, setCalOpenModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [dataDetails, setDataDetails] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [listTab, setListTab] = useState([]);
  const [tab, setTab] = useState(1);
  const [subTab, setSubTab] = useState(1);
  const [historyTab, setHistoryTab] = useState(1);

  const [firstData, setFirstData] = useState({
    data: undefined,
    metadata: undefined,
  });
  const [openModalToAssign, setOpenModalToAssign] = useState(false);
  const [pagingReward, setPagingReward] = useState({ page: 1, limit: 10 });
  const [metadata, setMetaData] = useState({
    total_form_answer_after_assign: 0,
    total_form_answer_before_assign: 0,
    total_form_answer_count: 0,
    total_new_form_answer: 0,
    total_supporter: 0,
  });
  const [subRewardUsd, setSubRewardUsd] = useState([]);
  const [openImport, setOpenImport] = useState(false);
  const [fileTest, setFileTest] = useState(undefined);
  const [filter, setFilter] = useState({
    q: undefined,
    form: undefined,
    supporter: undefined,
    affiliation: undefined,
    status: undefined,
    participation_status: undefined,
    from_created_at: undefined,
    to_created_at: undefined,
    from_exported_at: undefined,
    to_exported_at: undefined,
    exported_at: undefined,
    checked_ref_trade_account_at: undefined,
    ref_trade_account: undefined,
    deleted_at: "null",
    page: 1,
    limit: 10,
  });
  const [refresh, setRefresh] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [typeAssign, setTypeAssign] = useState(1);
  const getRoles = async () => {
    try {
      let { data } = await api.get(`${API_URL}api/v1/roles/global-system`, {
        status: "ACTIVE",
      });
      let rolesFake = [];
      rolesFake = [...data].map((x) => {
        return { label: x.name, value: x.key };
      });
      setRoles(rolesFake);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getRoles();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getTabs = async () => {
    setLoading(true);
    try {
      let { data } = await api.get(`${API_URL}api/v1/form-answers/tab-info`, {
        ...filter,
        current_tab:
          tab === 1 ? "ALL" : tab === 2 ? "NO_SUPPORTER" : "CURRENT_USER",
      });
      setListTab(data);
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getTabs();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getData = async () => {
    setLoading(true);

    try {
      let data = await api.get(`${API_URL}api/v1/form-answers`, {
        ...filter,
      });
      setDataSource(data?.data);
      setTotal(data?.metadata?.totalItems);
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getDataById = async (id) => {
    setLoading(true);

    try {
      let { data } = await api.get(`${API_URL}api/v1/form-answers/${id}`, {});
      setDataDetails(data);

      let arrayItems = data?.form?.items?.slice(13);
      for (let i = 0; i < arrayItems.length; i++) {
        if (arrayItems[i]?.code?.toLowerCase()) {
          formUpdate.setFieldsValue({
            [arrayItems[i]?.code?.toLowerCase()]:
              data?.[arrayItems[i]?.code?.toLowerCase()],
          });
        }
      }
      formUpdate.setFieldsValue({
        trade_account_for_contest: data?.trade_account_for_contest,
        passview: data?.passview,
        trade_app: data?.trade_app,
        trade_server: data?.trade_server,
        target_usd: data?.target_usd,
        supporter_note: data?.supporter_note,
      });
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getDataById(id);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishForm = (e) => {
    setFilter({
      ...filter,
      page: 1,
      q: e?.q?.length > 0 ? e?.q?.trim() : undefined,
      form: e?.form,
      supporter: e?.supporter,
      affiliation: e?.affiliation,
      status: e?.status,
      participation_status: e?.participation_status,
      from_created_at: e?.from_created_at
        ? e.from_created_at
        : e?.to_created_at
        ? new Date("2007-01-01")
        : undefined,
      to_created_at: e?.to_created_at
        ? e.to_created_at
        : e?.from_created_at
        ? moment()
        : undefined,
      exported_at: e?.exported_at,
      from_exported_at: e?.from_exported_at
        ? e.from_exported_at
        : e?.to_exported_at
        ? new Date("2007-01-01")
        : undefined,
      to_exported_at: e?.to_exported_at
        ? e.to_exported_at
        : e?.from_exported_at
        ? moment()
        : undefined,
      checked_ref_trade_account_at: e?.checked_ref_trade_account_at,
      ref_trade_account: e?.ref_trade_account,
      target_usd: e?.target_usd,
      trade_app: e?.trade_app,
    });
  };

  const handleFinishFormUpdate = async (e, check) => {
    setLoading(true);
    console.log("1");
    try {
      let newValue = {};
      if (openModal) {
        let arrayItems = dataDetails?.form?.items?.slice(13);
        for (let i = 0; i < arrayItems.length; i++) {
          if (
            arrayItems[i]?.code &&
            e?.hasOwnProperty([arrayItems[i]?.code?.toLowerCase()]) &&
            e?.[arrayItems[i]?.code?.toLowerCase()]
          ) {
            newValue[arrayItems[i]?.code?.toLowerCase()] =
              e?.[arrayItems[i]?.code?.toLowerCase()];
          }
        }
      }
      let { data } = await api.put(
        `${API_URL}api/v1/form-answers/${check ? check : dataDetails?.id}`,
        check
          ? { supporter: e?.support }
          : {
              ...newValue,
              trade_account_for_contest: e?.trade_account_for_contest,
              passview: e?.passview,
              trade_app: e?.trade_app,
              trade_server: e?.trade_server,
              supporter_note: e?.supporter_note,
              target_usd: e?.target_usd,
            }
      );
      console.log("5");

      if (openModal) {
        setDataDetails(data);
        let newArrayItems = data?.form?.items?.slice(13);
        for (let i = 0; i < newArrayItems.length; i++) {
          if (newArrayItems[i]?.code) {
            formUpdate.setFieldsValue({
              [newArrayItems[i]?.code?.toLowerCase()]:
                data?.[newArrayItems[i]?.code?.toLowerCase()],
            });
          }
        }
        console.log("6");

        formUpdate.setFieldsValue({
          trade_account_for_contest: data?.trade_account_for_contest,
          passview: data?.passview,
          trade_app: data?.trade_app,
          trade_server: data?.trade_server,
          target_usd: data?.target_usd,
          supporter_note: data?.supporter_note,
        });
      }

      notifySuccess("Cập nhật thông tin thành công!");
      setSelectedSupporterAssign(undefined);
      setOpenModalToAssign(false);
      setLoading(false);
      getData();
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormUpdate(e);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleSort = (value) => {
    let fake = { ...filter };
    if (value === 1) {
      setFilter({
        ...filter,
        sort_by: "name",
        sort_type:
          fake?.sort_by === "name"
            ? fake?.sort_type === "ASC"
              ? "DESC"
              : "ASC"
            : "ASC",
      });
    }
    if (value === 2) {
      setFilter({
        ...filter,
        sort_by: "email",
        sort_type:
          fake?.sort_by === "email"
            ? fake?.sort_type === "ASC"
              ? "DESC"
              : "ASC"
            : "ASC",
      });
    }
    if (value === 3) {
      setFilter({
        ...filter,
        sort_by: "form_info",
        sort_type:
          fake?.sort_by === "form_info"
            ? fake?.sort_type === "ASC"
              ? "DESC"
              : "ASC"
            : "ASC",
      });
    }
    if (value === 4) {
      setFilter({
        ...filter,
        sort_by: "created_at",
        sort_type:
          fake?.sort_by === "created_at"
            ? fake?.sort_type === "ASC"
              ? "DESC"
              : "ASC"
            : "ASC",
      });
    }
    if (value === 5) {
      setFilter({
        ...filter,
        sort_by: "status",
        sort_type:
          fake?.sort_by === "status"
            ? fake?.sort_type === "ASC"
              ? "DESC"
              : "ASC"
            : "ASC",
      });
    }
    if (value === 6) {
      setFilter({
        ...filter,
        sort_by: "supporter_info",
        sort_type:
          fake?.sort_by === "supporter_info"
            ? fake?.sort_type === "ASC"
              ? "DESC"
              : "ASC"
            : "ASC",
      });
    }
    if (value === 8) {
      setFilter({
        ...filter,
        sort_by: "exported_at",
        sort_type:
          fake?.sort_by === "exported_at"
            ? fake?.sort_type === "ASC"
              ? "DESC"
              : "ASC"
            : "ASC",
      });
    }
    if (value === 9) {
      setFilter({
        ...filter,
        sort_by: "total_reward_usd",
        sort_type:
          fake?.sort_by === "total_reward_usd"
            ? fake?.sort_type === "ASC"
              ? "DESC"
              : "ASC"
            : "ASC",
      });
    }

    if (value === 10) {
      setFilter({
        ...filter,
        sort_by: "updated_at",
        sort_type:
          fake?.sort_by === "updated_at"
            ? fake?.sort_type === "ASC"
              ? "DESC"
              : "ASC"
            : "ASC",
      });
    }
  };

  const getSupport = async () => {
    setLoading(true);

    try {
      let { data } = await api.get(`${API_URL}api/v1/users`, {
        status: "ACTIVE",
      });
      let groupsFake = [];
      groupsFake = [...data].map((x) => {
        return { label: x.name, value: x.id };
      });
      setSupports(groupsFake);

      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getSupport();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getTargets = async () => {
    setLoading(true);

    try {
      let { data } = await api.get(
        `${API_URL}api/v1/forms/items?q=target_usd`,
        {
          status: "ACTIVE",
        }
      );

      setDataTargets(
        data[0]?.options?.map((x) => {
          return { ...x, label: x.label + " USD" };
        }) || []
      );
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getTargets();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const getDataGroups = async () => {
    setLoading(true);

    try {
      let { data } = await api.get(`${API_URL}api/v1/forms`);
      let groupsFake = [];
      groupsFake = [...data].map((x) => {
        return { label: x.name, value: x.id, status: x.status };
      });
      setLoading(false);

      setGroups(groupsFake);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getDataGroups();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const onChangePaging = (page, pageSize) => {
    setFilter({
      ...filter,

      page: page,
      limit: pageSize,
    });
    setSelectedClient([]);
  };
  const getDataByRecordEmail = async (record) => {
    setLoading(true);
    try {
      let { data } = await api.get(`${API_URL}api/v1/clients/by-email/${record?.email}`, {
       
      });

      if (data) {
        setDataRecordByEmail(data);
      } else {
        setDataRecordByEmail({ ...dataRecordByEmail, email: record?.email });
      }
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getDataByRecordEmail(record);
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleOpenModal = (record, type) => {
    if (type === 5) {
    
      setOpenModalEmail(true);
      getDataByRecordEmail(record);
    } else if (type === 1) {
      setOpenModal(!openModal);
      getDataById(record?._id);
    } else {
      setCalOpenModal(true);
      setSubRewardUsd(record?.reward_usd_histories);
    }
  };

  const handleSync = async (value) => {
    if (!value && selectedClient.length === 0) {
      notifyWarning("Vui lòng chọn ít nhất một record");
      return;
    }
    setLoading(true);
    let listEmail = [];
    for (let i = 0; i < selectedClient.length; i++) {
      for (let j = 0; j < dataSource.length; j++) {
        if (selectedClient[i] === dataSource[j].id) {
          listEmail.push(dataSource[j].email);
          continue;
        }
      }
    }
    listEmail = [...new Set(listEmail)];
    try {
      let { data } = await api.post(`${API_URL}api/v1/clients/sync`, {
        emails: value ? [value] : listEmail,
      });
      notifySuccess("Đồng bộ hoàn tất");
      getData();
      setDataRecordByEmail(undefined);
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleSync(value);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleSync2 = async (value) => {
    setLoading(true);

    try {
      let { data } = await api.post(`${API_URL}api/v1/clients/sync`, {
        emails: [value],
      });
      notifySuccess("Đồng bộ hoàn tất");
      getData();

      getDataByRecordEmail({ email: value });
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleSync(value);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleExport = async () => {
    setLoading(true);
    let exportFilter = JSON.stringify({
      ...filter,
      groups: undefined,
      from_created_at: undefined,
      to_created_at: undefined,
      from_exported_at: undefined,
      to_exported_at: undefined,
    })
      .replaceAll(`"`, ``)
      .replaceAll(":", "=")
      .replaceAll(",", "&")
      .replaceAll("{", "")
      .replaceAll("}", "");
    if (filter.groups) {
      exportFilter =
        exportFilter +
        (exportFilter.length === 0 ? "groups=" : "&groups=") +
        JSON.stringify({ ...filter }.groups)
          .replaceAll(`"`, ``)
          .replaceAll(":", "=")
          .replaceAll("{", "")
          .replaceAll("}", "");
    }
    if (filter.from_created_at) {
      exportFilter =
        exportFilter +
        "&from_created_at=" +
        JSON.stringify({ ...filter }.from_created_at).replaceAll(`"`, ``);
    }
    if (filter.to_created_at) {
      exportFilter =
        exportFilter +
        "&to_created_at=" +
        JSON.stringify({ ...filter }.to_created_at).replaceAll(`"`, ``);
    }
    if (filter.from_exported_at) {
      exportFilter =
        exportFilter +
        "&from_exported_at=" +
        JSON.stringify({ ...filter }.from_exported_at).replaceAll(`"`, ``);
    }
    if (filter.to_exported_at) {
      exportFilter =
        exportFilter +
        "&to_exported_at=" +
        JSON.stringify({ ...filter }.to_exported_at).replaceAll(`"`, ``);
    }
    let file_path = undefined;
    try {
      let { data: data_filepath } = await api.post(`${API_URL}api/v1/exports`, {
        total_parts: 1,
        module: "FormAnswer",
        query_params: exportFilter,
      });
      file_path = data_filepath?.file_path;
      if (!file_path) {
        notifyError("Lỗi không có file path");
        setLoading(false);
        return;
      } else {
        try {
          let res = await api.postDownload(
            `${API_URL}api/v1/exports/download`,
            {
              file_path: file_path,
            }
          );
          exportExcel(res, file_path.slice(file_path.indexOf("FormAnswer")));

          try {
            let { data: data_delete } = await api.post(
              `${API_URL}api/v1/exports/delete`,
              {
                file_path: file_path,
              }
            );
            setLoading(false);
          } catch (e) {
            if (e.statusCode < 500) {
              notifyWarning(e.message);
            } else {
              notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
            }
            setLoading(false);
          }
        } catch (e) {
          if (e.statusCode < 500) {
            notifyWarning(e.message);
          } else {
            notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
          }
          setLoading(false);
        }
      }
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleExport();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleChangeStatusPart = async (value) => {
    try {
      let { data } = await api.put(
        `${API_URL}api/v1/form-answers/change-participation-status/${value.id}`,
        {}
      );
      notifySuccess("Chuyển trạng thái thành công");
      getData();
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleChangeStatus(value);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleChangeStatus = async (value) => {
    try {
      let { data } = await api.put(
        `${API_URL}api/v1/form-answers/change-status/${value}`,
        {}
      );
      setRefresh(!refresh);
      notifySuccess("Chuyển trạng thái thành công");
      if (dataDetails && openModal) {
        setDataDetails({
          ...dataDetails,
          status: data?.status,
          change_status_histories: data?.change_status_histories,
        });
      }
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleChangeStatus(value);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleAssign = async (type, record) => {
    console.log(record.supporter_info?.id);
    setAssignRecord(record);
    setTypeAssign(type);
    if (!record?.supporter_info?.id) {
      if (type === 1) {
        handleAssignFinish(record?.id);
      } else {
        setOpenModalToAssign(true);
      }
    } else {
      setOpenModalToAssign(true);
    }
  };

  const handleAssignFinish = async (value) => {
    try {
      let { data } = await api.put(
        `${API_URL}api/v1/form-answers/assign-to-me/${value}`,
        {}
      );
      if (openModal) {
        setDataDetails(data);
        setAssignRecord({
          ...assignRecord,
          supporter: data?.supporter,
        });
      }
      setRefresh(!refresh);

      setOpenModalToAssign(false);
      notifySuccess("Phân công cho bạn thành công");
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleAssignFinish(value);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getDataAssgin = async (value) => {
    try {
      let { data, metadata } = await api.post(
        `${API_URL}api/v1/form-answers/${value.form}/assign`,
        {
          request_type: "GET_SITUATION",
          apply_objects: value.roles,
        }
      );
      setFirstData({ data: data, metadata: metadata });

      setDataSourceAssign(data);
      setMetaData(metadata);
      setDataToAssign(data);
      setStep(2);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });
          getDataAssgin(value);
          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleFinishFormAssign = (value) => {
    if (step === 1) {
      setSelectedValueAssign(() => ({
        form: value?.form,
        roles: value?.roles,
      }));
      getDataAssgin(value);
    }
  };
  const rowSelection = {
    selectedRowKeys: selectedClient,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedClient(selectedRowKeys);
    },
  };

  const handleOpenSubmitAssign = () => {
    setOpenSubmitAssign(true);
    setOpenModalAssign(false);
  };
  const handleAssignEvenly = async (value) => {
    setLoadingAssign(true);
    let fake = [...dataSourceAssign].map((x, index) => {
      return { user_info: { name: x?.user_info?.name } };
    });
    setDataSourceAssign(fake);
    setDataToAssign(fake);
    try {
      let { data, metadata } = await api.post(
        `${API_URL}api/v1/form-answers/${selectedValueAssign.form}/assign`,
        {
          request_type: "CALCULATE_ASSIGN_EVENLY",
          apply_objects: selectedValueAssign?.roles,
        }
      );
      setDataSourceAssign(data);
      setMetaData(metadata);
      setDataToAssign(data);
      setLoadingAssign(false);
    } catch (e) {
      setLoadingAssign(false);
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });
          handleSubmitAssign(value);
          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleCloseOpenImport = () => {
    setOpenImport(false);
    setFileTest(undefined);
    let file = document.querySelector("#importFile");
    if (file) {
      file.value = "";
    }
  };

  const onChangePagingReward = (page, limit) => {
    console.log(page, limit);
    setPagingReward({
      page: page,
      limit: limit,
    });
  };
  const onFinishImportFile = async () => {
    if (!fileTest) {
      notifyWarning("Vui lòng chọn file");
    } else {
      setLoading(true);
      try {
        let { data } = await api.postMultipartFormAnswer(
          `${API_URL}api/v1/imports`,
          {
            file: fileTest,
          }
        );
        notifySuccess("Nhập file thành công!");
        setLoading(false);
        setOpenImport(false);
        setFileTest(undefined);
        let file = document.querySelector("#importFile");
        if (file) {
          file.value = "";
        }
        getData();
      } catch (e) {
        if (e.statusCode === 401) {
          try {
            let { data } = await axios({
              method: "POST", //you can set what request you want to be
              url: `${API_URL}api/v1/auth/refresh-token`,
              headers: {
                Authorization: "Bearer " + localGetRefreshToken(),
              },
            });

            localSaveToken(data?.data?.accessToken);
            localSaveRefreshToken(data?.data?.refreshToken);
            onFinishImportFile();
          } catch (e) {
            navigate("/login");
            localRemoveRefreshToken();
            localRemoveToken();
          }
        } else if (e.statusCode === 403) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        } else {
          if (e.statusCode < 500) {
            notifyWarning(e.message);
          } else {
            notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
          }
          setLoading(false);
        }
      }
    }
  };
  const importFileCallback = () => {
    setOpenImport(true);
  };
  const handleChangeFile = (e) => {
    setFileTest(e.target.files[0]);
  };

  const handleSample = async () => {
    setLoading(true);

    let file_path = undefined;
    try {
      let { data: data_filepath } = await api.post(
        `${API_URL}api/v1/exports/sample`,
        {
          module: "FormAnswerSample",
        }
      );
      file_path = data_filepath?.file_path;
      if (!file_path) {
        notifyError("Lỗi không có file path");
        setLoading(false);
        return;
      } else {
        try {
          let res = await api.postDownload(
            `${API_URL}api/v1/exports/download`,
            {
              file_path: file_path,
            }
          );
          exportExcel(res, "SampleDataList.xlsx");
          setLoading(false);
        } catch (e) {
          if (e.statusCode < 500) {
            notifyWarning(e.message);
          } else {
            notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
          }
          setLoading(false);
        }
      }
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleExport();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleSubmitAssign = async () => {
    setLoading(true);
    try {
      let { data } = await api.post(
        `${API_URL}api/v1/form-answers/${selectedValueAssign.form}/assign`,
        {
          request_type: "SUBMIT",
          apply_objects: selectedValueAssign?.roles,
          payload: dataToAssign,
        }
      );
      notifySuccess("Phân bổ thành công!");
      setDataSourceAssign([]);
      setDataToAssign([]);
      setMetaData({
        total_form_answer_after_assign: 0,
        total_form_answer_before_assign: 0,
        total_form_answer_count: 0,
        total_new_form_answer: 0,
        total_supporter: 0,
      });
      setOpenEdit(false);
      setSelectedValueAssign({ form: undefined, roles: [] });
      setOpenSubmitAssign(false);
      setStep(1);
      formAssign.resetFields();
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });
          handleSubmitAssign(value);
          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleOpenEdit = () => {
    if (openEdit) {
      setOpenEdit(false);
      setDataSourceAssign(firstData?.data);
      setDataToAssign(firstData?.data);
      setMetaData(firstData?.metadata);
      setDataToAssign(firstData?.data);
    } else setOpenEdit(true);
  };
  const calcOnChange = async (value, id, type, selected, dataSource) => {
    let fakeData = [...dataSource].map((x) =>
      x._id === id
        ? type === 1
          ? { ...x, input_assign_form_answer_count: Number(value) }
          : { ...x, input_assign_form_answer_percentage: Number(value) }
        : x
    );
    // let newData = [];
    // for (let i = 0; i < fakeData.length; i++) {
    //   newData.push(fakeData[i]);
    // }
    setDataToAssign(fakeData);
    setLoadingAssign(true);
    try {
      let { data, metadata } = await api.post(
        `${API_URL}api/v1/form-answers/${selected.form}/assign`,
        {
          request_type: "CALCULATE_ASSIGN_MANUALLY",
          apply_objects: selected?.roles,
          payload: fakeData,
        }
      );
      setMetaData(metadata);
      setDataToAssign(data);
      setDataSourceAssign(data);
      setLoadingAssign(false);
    } catch (e) {
      setLoadingAssign(false);
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });
          calcOnChange(value);
          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const debounceDropDown = useCallback(
    _debounce((value, id, type, selected, dataSource) => {
      calcOnChange(value, id, type, selected, dataSource);
    }, 500),
    []
  );

  const onChangeValue = (value, id, type, selected, dataSource) => {
    debounceDropDown(value, id, type, selected, dataSource);
  };
  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/form-answer");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);

  useEffect(() => {
    pageMenu.list && getData();
  }, [pageMenu, filter, refresh]);

  useEffect(() => {
    pageMenu.list && getTabs();
  }, [pageMenu, refresh, filter]);

  useEffect(() => {
    if (pageMenu.list) {
      getDataGroups();
      getSupport();
      getRoles();
      getTargets();
    }
  }, [pageMenu]);

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      form.submit();
    }
  };
  return (
    pageMenu.list && (
      <div
        className="clients"
        onKeyDown={handleKeyUp}
        tabIndex="0"
        style={{ outline: "none" }}
      >
        <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
        <Modal
          open={openModalToAssign}
          onCancel={(e) => {
            setOpenModalToAssign(false);
          }}
          footer={null}
          centered
          width={720}
          zIndex={3000}
        >
          {assignRecord?.supporter_info?.id ? (
            typeAssign === 1 ? (
              <div onClick={() => console.log(dataDetails, assignRecord)}>
                Biểu mẫu đang được phân công cho&nbsp;
                <span style={{ color: "red" }}>
                  {dataDetails
                    ? assignRecord?.supporter?.name
                    : assignRecord?.supporter_info?.name}
                </span>
                , bạn có muốn phân công lại cho bạn ?{" "}
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <div
                    onClick={() => handleAssignFinish(assignRecord?.id)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "5px",
                      background: "#27aae1",
                      height: "32px",
                      width: "128px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Xác nhận
                  </div>
                </div>
              </div>
            ) : (
              <div>
                Biểu mẫu đang được phân công cho&nbsp;
                <span style={{ color: "red" }}>
                  {assignRecord?.supporter_info?.name}
                </span>{" "}
                , bạn muốn phân công lại cho
                <div>
                  <Select
                    allowClear
                    options={supports}
                    placeholder="Tất cả"
                    value={selectedSupporterAssign}
                    style={{ width: "200px", marginTop: "8px" }}
                    onChange={(e) => setSelectedSupporterAssign(e)}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <div
                    onClick={() =>
                      selectedSupporterAssign
                        ? handleFinishFormUpdate(
                            { support: selectedSupporterAssign },
                            assignRecord?.id
                          )
                        : notifyWarning(
                            "Vui lòng chọn nhân viên muốn phân công"
                          )
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "5px",
                      background: "#27aae1",
                      height: "32px",
                      width: "128px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Xác nhận
                  </div>
                </div>
              </div>
            )
          ) : (
            <div>
              Bạn muốn phân công cho
              <div>
                <Select
                  allowClear
                  options={supports}
                  placeholder="Tất cả"
                  value={selectedSupporterAssign}
                  style={{ width: "200px", marginTop: "8px" }}
                  onChange={(e) => setSelectedSupporterAssign(e)}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <div
                  onClick={() =>
                    selectedSupporterAssign
                      ? handleFinishFormUpdate(
                          { support: selectedSupporterAssign },
                          assignRecord?.id
                        )
                      : notifyWarning("Vui lòng chọn nhân viên muốn phân công")
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                    background: "#27aae1",
                    height: "32px",
                    width: "128px",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Xác nhận
                </div>
              </div>
            </div>
          )}
        </Modal>
        <Modal
          open={openModalEmail}
          onCancel={(e) => {
            setOpenModalEmail(false);
            setDataRecordByEmail(undefined);
          }}
          footer={null}
          centered
          width={480}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "16px 0 0 0",
            }}
          >
            <h4>Thông tin giao dịch</h4>
            {pageMenu.sync && (
              <Button
                className="clients__search__button"
                onClick={() => handleSync2(dataRecordByEmail?.email)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(56, 114, 174)",
                  color: "#fff",
                }}
              >
                Đồng bộ
              </Button>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid rgb(213,213,213)",
                marginTop: "8px",
              }}
            >
              <div>Email:</div>
              <div>{dataRecordByEmail?.email}</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid rgb(213,213,213)",
                marginTop: "8px",
              }}
            >
              <div>Tổng khối lượng giao dịch (lots):</div>
              <div>{dataRecordByEmail?.volumn_lots || "0"} </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid rgb(213,213,213)",
                marginTop: "8px",
              }}
            >
              <div>Tổng lợi nhuận (USD):</div>
              <div>{dataRecordByEmail?.reward_usd || "0"}</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid rgb(213,213,213)",
                marginTop: "8px",
              }}
            >
              <div>Lần giao dịch gần nhất:</div>
              <div>
                {dataRecordByEmail?.last_trade_at
                  ? moment(dataRecordByEmail?.last_trade_at).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  : "-"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid rgb(213,213,213)",
                marginTop: "8px",
              }}
            >
              <div>Tài khoản giao dịch gần nhất:</div>
              <div>{dataRecordByEmail?.last_trade_client_account || "-"}</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid rgb(213,213,213)",
                marginTop: "8px",
              }}
            >
              <div>Số lượng tài khoản giao dịch:</div>
              <div>{dataRecordByEmail?.count_trade_account || "0"}</div>
            </div>
          </div>
        </Modal>
        <Modal
          open={calOpenModal}
          onCancel={(e) => {
            setCalOpenModal(false);
            setPagingReward({ page: 1, limit: 10 });
          }}
          footer={null}
          centered
          width={720}
        >
          <Table
            bordered
            className="newpadding"
            rowKey="_id"
            columns={columnsDataModal({})}
            dataSource={subRewardUsd?.slice(
              (pagingReward.page - 1) * pagingReward.limit,
              pagingReward.limit * pagingReward.page
            )}
            pagination={false}
          />
          <PanigationAntStyled
            style={{ padding: "0 0 24px 0" }}
            current={pagingReward.page}
            pageSize={pagingReward.limit}
            showSizeChanger
            onChange={onChangePagingReward}
            showTotal={() => `Tổng ${subRewardUsd?.length}`}
            total={subRewardUsd?.length}
          />
        </Modal>
        <Modal
          open={openDownloadSample}
          title={""}
          maskClosable={false}
          className="modalEditSender"
          onCancel={() => setOpenDownloadSample(false)}
          onOk={() => {
            handleSample();
            setOpenDownloadSample(false);
          }}
          width={420}
          centered
        >
          Xác nhận tải file mẫu
        </Modal>
        <Modal
          open={openDownload}
          title={""}
          maskClosable={false}
          className="modalEditSender"
          onCancel={() => setOpenDownload(false)}
          onOk={() => {
            handleExport();
            setOpenDownload(false);
          }}
          width={420}
          centered
        >
          Xác nhận xuất file excel
        </Modal>
        <Modal
          open={openImport}
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <SvgExcel /> <span style={{ marginLeft: "8px" }}>Nhập file</span>
            </div>
          }
          maskClosable={false}
          className="modalEditSender"
          onCancel={() => handleCloseOpenImport()}
          footer={null}
          width={420}
          centered
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label
              htmlFor="importFile"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                background: "#259c58",
                height: "41px",
                width: "calc(50% - 4px)",
              }}
            >
              <SvgFile />
              <span style={{ marginLeft: "8px", color: "#fff" }}>
                Chọn file
              </span>
            </label>
            <input
              type="file"
              id="importFile"
              onChange={(e) => handleChangeFile(e)}
              style={{ display: "none" }}
            />

            <div
              onClick={() => onFinishImportFile()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                background: "#27aae1",
                height: "41px",
                width: "calc(50% - 4px)",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Xác nhận
            </div>
          </div>
          {fileTest && <div>{fileTest.name}</div>}
        </Modal>

        <Modal
          title={<div>Xác nhận phân bổ</div>}
          open={openSubmitAssign}
          onCancel={(e) => {
            setOpenSubmitAssign(false);
            setOpenModalAssign(true);
          }}
          width={600}
          footer={null}
          centered
        >
          <div
            className="checkCustomer__content__submit"
            style={{ marginTop: "0", justifyContent: "right" }}
          >
            <button
              onClick={() => {
                setOpenSubmitAssign(false);
                setOpenModalAssign(true);
              }}
              style={{
                background: "#fff",
                color: "#000",
                marginRight: "16px",
              }}
            >
              Hủy
            </button>

            <button
              onClick={() => handleSubmitAssign()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "rgb(56, 114, 174)",
                color: "#fff",
              }}
            >
              Đồng ý
            </button>
          </div>
        </Modal>
        <Modal
          title={step === 1 ? "Chọn biểu mẫu phân bổ" : ""}
          open={openModalAssign}
          onCancel={(e) => {
            setOpenModalAssign(false);
            setStep(1);
            formAssign.resetFields();
            setOpenEdit(false);
          }}
          width={step === 1 ? 600 : 960}
          footer={null}
          centered
        >
          {step === 1 && (
            <Form
              layout="vertical"
              form={formAssign}
              onFinish={handleFinishFormAssign}
              initialValues={{
                roles: ["SALE"],
                form: groups[0]?.value,
              }}
            >
              <Form.Item label="Chọn biểu mẫu" name="form" required>
                <Select
                  placeholder="Chọn biểu mẫu"
                  options={groups}
                />
              </Form.Item>
              <Form.Item
                label="Chọn vai trò được phân bổ"
                name="roles"
                required
              >
                <Select
                  placeholder="Chọn vai trò được phân bổ"
                  options={roles}
                  mode="multiple"
                />
              </Form.Item>
              <div
                className="checkCustomer__content__submit"
                style={{ marginTop: "0", justifyContent: "right" }}
              >
                <button
                  onClick={() => formAssign.submit()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "rgb(56, 114, 174)",
                    color: "#fff",
                  }}
                >
                  Tiếp tục
                </button>
              </div>
            </Form>
          )}
          {step === 2 && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "16px",
                  color: "rgb(56,114,174)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "calc(50% - 36px)",
                  }}
                >
                  <div>Tổng số lượng nhân viên chăm sóc:</div>
                  <div>{metadata?.total_supporter}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "calc(50% + 24px)",
                  }}
                >
                  <div>Tổng số mẫu điền form theo form id:</div>
                  <div>{metadata?.total_form_answer_count}</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "rgb(56,114,174)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "calc(50% - 36px)",
                  }}
                >
                  <div>Số lượng mẫu điền form đã được phân bổ trước đó:</div>
                  <div>{metadata?.total_form_answer_before_assign}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "calc(50% + 24px)",
                  }}
                >
                  <div>
                    Số lượng mẫu điền form sau khi được điều chỉnh ở thời điểm
                    hiện tại:
                  </div>
                  <div>{metadata?.total_form_answer_after_assign}</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "rgb(56,114,174)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "calc(50% - 36px)",
                  }}
                >
                  <div>Tổng số mẫu điền form mới:</div>
                  <div>{metadata?.total_new_form_answer}</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "rgb(56,114,174)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "calc(50% - 36px)",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    width: "calc(50% + 24px)",
                  }}
                >
                  <Button
                    className="clients__search__button"
                    onClick={() => handleOpenEdit()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "rgb(56, 114, 174)",
                      color: "#fff",
                    }}
                  >
                    {openEdit ? "Hủy chỉnh sửa" : "Chỉnh sửa"}
                  </Button>
                </div>
              </div>

              <Table
                bordered
                className="custom__column1 newpadding"
                loading={loadingAssign}
                rowKey="_id"
                columns={
                  openEdit
                    ? columnsDataAssign(
                        { onChangeValue },
                        selectedValueAssign,
                        dataSourceAssign
                      )
                    : columnsDataAssign2(
                        { onChangeValue },
                        selectedValueAssign,
                        dataSourceAssign
                      )
                }
                dataSource={dataSourceAssign}
                pagination={false}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "8px",
                }}
              >
                <div
                  className="checkCustomer__content__submit"
                  style={{ marginTop: "0", justifyContent: "right" }}
                >
                  {openEdit && (
                    <button
                      onClick={() => handleAssignEvenly()}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgb(56, 114, 174)",
                        color: "#fff",
                      }}
                    >
                      Phân bổ đều
                    </button>
                  )}
                </div>
                <div
                  className="checkCustomer__content__submit"
                  style={{ marginTop: "0", justifyContent: "right" }}
                >
                  <button
                    onClick={() => {
                      setStep(1);

                      setSelectedValueAssign({
                        form: undefined,
                        roles: undefined,
                      });
                    }}
                    style={{
                      background: "#fff",
                      color: "#000",
                      marginRight: "16px",
                    }}
                  >
                    Quay lại
                  </button>
                  {openEdit && (
                    <button
                      onClick={() => handleOpenSubmitAssign()}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgb(56, 114, 174)",
                        color: "#fff",
                      }}
                    >
                      Phân bổ
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </Modal>
        <Modal
          title=""
          open={openModal}
          onCancel={(e) => {
            setOpenModal(false);
            setDataDetails(undefined);
            setSubTab(1);
            setHistoryTab(1);
          }}
          width={1080}
          footer={null}
          centered
        >
          <div style={{ minHeight: "540px" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                borderBottom: "1px solid rgb(117, 218, 239)",
                fontSize: "14px",
                marginTop: "16px",
                position: "relative",
              }}
            >
              <div
                key={1}
                style={
                  subTab === 1
                    ? {

                  

                    
                        transform: "translateY(0.5px)",
                        height: "32px",
                        border: "solid 1px rgb(117, 218, 239)",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0 4px",
                        borderBottom: "solid 1px #fff",
                      }
                    : { padding: "2px 4px" }
                }
              >
                <div
                  style={
                    subTab !== 1
                      ? {

                        background: "rgb(56, 114, 174)",
                        color:"#fff",
                       
                          borderRadius: "3px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0 8px",
                          cursor: "pointer",
                        }
                      : { padding: "0 8px" }
                  }
                  onClick={() => {
                    setSubTab(1);
                  }}
                >
                  Thông tin điền form
                </div>
              </div>
              <div
                key={3}
                style={
                  subTab === 3
                    ? {
                        transform: "translateY(0.5px)",
                        height: "32px",
                        border: "solid 1px rgb(117, 218, 239)",
                      
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0 4px",
                        borderBottom: "solid 1px #fff",
                      }
                    : { padding: "2px 4px" }
                }
              >
                <div
                  style={
                    subTab !== 3
                      ? {
                          
                          background: "rgb(56, 114, 174)",
                          color:"#fff",
                          borderRadius: "3px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0 8px",
                          cursor: "pointer",
                        }
                      : { padding: "0 8px" }
                  }
                  onClick={() => {
                    setSubTab(3);
                  }}
                >
                  Thao tác biểu mẫu
                </div>
              </div>
              <div
                key={2}
                style={
                  subTab === 2
                    ? {
                        transform: "translateY(0.5px)",
                        height: "32px",
                        border: "solid 1px rgb(117, 218, 239)",
                      
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0 4px",
                        borderBottom: "solid 1px #fff",
                      }
                    : { padding: "2px 4px" }
                }
              >
                <div
                  style={
                    subTab !== 2
                      ? {
                         
                          background: "rgb(56, 114, 174)",
                          color:"#fff",
                          borderRadius: "3px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0 8px",
                          cursor: "pointer",
                        }
                      : { padding: "0 8px" }
                  }
                  onClick={() => {
                    setSubTab(2);
                  }}
                >
                  Lịch sử thao tác
                </div>
              </div>
              {pageMenu.update && subTab === 1 && (
                <div
                  className="checkCustomer__content__submit"
                  style={{ marginTop: "0", position: "absolute", right: "0" }}
                >
                  <button
                    onClick={() => formUpdate.submit()}
                    style={{ height: "30px", width: "120px" }}
                  >
                    Lưu
                  </button>
                </div>
              )}
            </div>
            {subTab === 1 && (
              <>
              <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%",   marginTop: "6px",}}>
                <span style={{background:"#fff",width:"140px",fontWeight:"bold"}}>Thông tin chung</span>
                <div style={{border:"solid 1px rgb(212,212,212)",width:"100%"}}></div>
              </div>
                <Form
                  layout="vertical"
                  form={formUpdate}
                  onFinish={handleFinishFormUpdate}
                >

                  
                  <div
                    style={{
                   
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "calc(50% - 8px)" }}>
                      <span>Họ tên</span>
                      <div
                        style={{
                          width: "100%",
                          border: "solid 1px rgb(202,202,202)",
                          borderRadius: "4px",
                          padding: "4px 8px",
                        }}
                      >
                        {dataDetails?.name}&nbsp;
                      </div>
                    </div>
                    <div style={{ width: "calc(50% - 8px)" }}>
                      <span>Email đăng ký Exness</span>
                      <div
                        style={{
                          width: "100%",
                          border: "solid 1px rgb(202,202,202)",
                          borderRadius: "4px",
                          padding: "4px 8px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {dataDetails?.email}&nbsp;
                        {dataDetails?.affiliation ? (
                          <AiFillCheckCircle
                            color="rgb(57,174,145)"
                            size={24}
                          />
                        ) : (
                          <BiSolidErrorCircle color="red" size={24} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "calc(50% - 8px)" }}>
                      <span>Tên Zalo</span>
                      <div
                        style={{
                          width: "100%",
                          border: "solid 1px rgb(202,202,202)",
                          borderRadius: "4px",
                          padding: "4px 8px",
                        }}
                      >
                        {dataDetails?.zalo_name}&nbsp;
                      </div>
                    </div>
                    <div style={{ width: "calc(50% - 8px)" }}>
                      <span>Username telegram</span>
                      <div
                        style={{
                          width: "100%",
                          border: "solid 1px rgb(202,202,202)",
                          borderRadius: "4px",
                          padding: "4px 8px",
                        }}
                      >
                        {dataDetails?.telegram_username}&nbsp;
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "calc(25% - (48px/4))" }}>
                      <span>Số điện thoại</span>
                      <div
                        style={{
                          width: "100%",
                          border: "solid 1px rgb(202,202,202)",
                          borderRadius: "4px",
                          padding: "4px 8px",
                        }}
                      >
                        {dataDetails?.phone}&nbsp;
                      </div>
                    </div>
                    <div style={{ width: "calc(25% - (48px/4))" }}>
                      <span>Số điện thoại Zalo</span>
                      <div
                        style={{
                          width: "100%",
                          border: "solid 1px rgb(202,202,202)",
                          borderRadius: "4px",
                          padding: "4px 8px",
                        }}
                      >
                        {dataDetails?.zalo_phone}&nbsp;
                      </div>
                    </div>
                    <div style={{ width: "calc(25% - (48px/4))" }}>
                      <span>Giới tính</span>
                      <div
                        style={{
                          width: "100%",
                          border: "solid 1px rgb(202,202,202)",
                          borderRadius: "4px",
                          padding: "4px 8px",
                        }}
                      >
                        {dataDetails?.gender}&nbsp;
                      </div>
                    </div>
                    <div style={{ width: "calc(25% - (48px/4))" }}>
                      <span>Năm sinh</span>
                      <div
                        style={{
                          width: "100%",
                          border: "solid 1px rgb(202,202,202)",
                          borderRadius: "4px",
                          padding: "4px 8px",
                        }}
                      >
                        {dataDetails?.dob}&nbsp;
                      </div>
                    </div>
                  </div>
                  <Form.Item
                    label="Ghi chú"
                    name="supporter_note"
                    style={{ width: "100%", marginTop: "4px" }}
                  >
                    <Input placeholder="Ghi chú" />
                  </Form.Item>
                  <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%",   marginTop: "-6px",}}>
                <span style={{background:"#fff",width:"160px",fontWeight:"bold"}}>Thông tin giao dịch</span>
                <div style={{border:"solid 1px rgb(212,212,212)",width:"100%"}}></div>
              </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                 
                      
                    }}
                  >
                    <Form.Item
                      label="ID giao dịch"
                      name="trade_account_for_contest"
                      style={{ width: "calc(25% - 12px" }}
                    >
                      <Input placeholder="ID tài khoản đăng kí thi" />
                    </Form.Item>
                    <Form.Item
                      label="Mật khẩu"
                      name="passview"
                      style={{ width: "calc(25% - 12px" }}
                    >
                      <Input placeholder="Mật khẩu" />
                    </Form.Item>
                    <Form.Item
                      label="Nền tảng giao dịch"
                      name="trade_app"
                      style={{ width: "calc(25% - 12px" }}
                    >
                      <Input placeholder="Nền tảng giao dịch" />
                    </Form.Item>
                    <Form.Item
                      label="Máy chủ giao dịch"
                      name="trade_server"
                      style={{ width: "calc(25% - 12px" }}
                    >
                      <Input placeholder="Máy chủ giao dịch" />
                    </Form.Item>
                  </div>

                  <div
                    style={{
                      marginTop: "-18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      label="Mục tiêu mong muốn trong ngày (%)"
                      name="target_usd"
                      style={{ width: "100%" }}
                    >
                      <Input
                        placeholder="Mục tiêu mong muốn trong ngày"
                        type="number"
                      />
                    </Form.Item>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    {dataDetails?.form?.items?.slice(13)?.map(
                      (x) =>
                        x?.code &&
                        x?.status === "ACTIVE" && (
                          <Form.Item
                            label={x?.name}
                            name={x?.code?.toLowerCase()}
                            style={{
                              width: "calc(50% - 8px)",
                              marginTop: "-18px",
                            }}
                          >
                            {x?.type === "select" ? (
                              <Select options={x?.options} />
                            ) : (
                              <Input placeholder={x?.tooltip} />
                            )}
                          </Form.Item>
                        )
                    )}
                  </div>
                </Form>
              </>
            )}
            {subTab === 2 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    borderBottom: "1px solid rgb(166,166,166)",
                    fontSize: "14px",
                    marginTop: "8px",
                    position: "relative",
                  }}
                >
                  <div
                    key={1}
                    style={
                      historyTab === 1
                        ? {
                            transform: "translateY(0.5px)",
                            height: "32px",
                            border: "1px solid rgb(22,44,66)",
                            borderTopLeftRadius: "4px",
                            borderTopRightRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0 4px",
                            borderBottom: "solid 1px #fff",
                          }
                        : { padding: "2px 4px" }
                    }
                  >
                    <div
                      style={
                        historyTab !== 1
                          ? {
                              border: "1px solid rgb(42,42,42)",
                              background: "rgb(243,243,243)",
                              borderRadius: "3px",
                              height: "28px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0 8px",
                              cursor: "pointer",
                            }
                          : { padding: "0 8px" }
                      }
                      onClick={() => {
                        setHistoryTab(1);
                      }}
                    >
                      Trạng thái tham gia
                    </div>
                  </div>
                  <div
                    key={2}
                    style={
                      historyTab === 2
                        ? {
                            transform: "translateY(0.5px)",
                            height: "32px",
                            border: "1px solid rgb(22,44,66)",
                            borderTopLeftRadius: "4px",
                            borderTopRightRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0 4px",
                            borderBottom: "solid 1px #fff",
                          }
                        : { padding: "2px 4px" }
                    }
                  >
                    <div
                      style={
                        historyTab !== 2
                          ? {
                              border: "1px solid rgb(42,42,42)",
                              background: "rgb(243,243,243)",
                              borderRadius: "3px",
                              height: "28px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0 8px",
                              cursor: "pointer",
                            }
                          : { padding: "0 8px" }
                      }
                      onClick={() => {
                        setHistoryTab(2);
                      }}
                    >
                      Trạng thái xử lý
                    </div>
                  </div>
                  <div
                    key={1}
                    style={
                      historyTab === 3
                        ? {
                            transform: "translateY(0.5px)",
                            height: "32px",
                            border: "1px solid rgb(22,44,66)",
                            borderTopLeftRadius: "4px",
                            borderTopRightRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0 4px",
                            borderBottom: "solid 1px #fff",
                          }
                        : { padding: "2px 4px" }
                    }
                  >
                    <div
                      style={
                        historyTab !== 3
                          ? {
                              border: "1px solid rgb(42,42,42)",
                              background: "rgb(243,243,243)",
                              borderRadius: "3px",
                              height: "28px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0 8px",
                              cursor: "pointer",
                            }
                          : { padding: "0 8px" }
                      }
                      onClick={() => {
                        setHistoryTab(3);
                      }}
                    >
                      Nhân viên chăm sóc
                    </div>
                  </div>
                </div>
                <div style={{ height: "380px", overflowY: "auto" }}>
                  <Table
                    bordered
                    className="custom__column1 newpadding"
                    rowKey="_id"
                    columns={
                      historyTab === 1
                        ? columnsDataHistoryParticipant({})
                        : historyTab === 2
                        ? columnsDataHistoryStatus({})
                        : columnsDataHistorySupporter({})
                    }
                    dataSource={
                      historyTab === 1
                        ? dataDetails?.change_participant_status_histories
                        : historyTab === 2
                        ? dataDetails?.change_status_histories
                        : historyTab === 3
                        ? dataDetails?.change_supporter_histories
                        : []
                    }
                    pagination={false}
                  />
                </div>
              </div>
            )}{" "}
            {subTab === 3 && (
              <div style={{ marginTop: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ color: "rgb(59,115,172)" }}>Biểu mẫu:</p>
                  <div
                    style={{
                      width: "calc(100% - 160px)",
                      border: "solid 1px rgb(202,202,202)",
                      borderRadius: "4px",
                      padding: "4px 8px",
                    }}
                  >
                    {dataDetails?.form_info?.name}&nbsp;
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginTop: "6px",
                  }}
                >
                  <p style={{ color: "rgb(59,115,172)", width: "160px" }}>
                    Trạng thái xử lý:
                  </p>
                  <div
                    style={{
                      width: "120px",
                      height: "32px",
                      background:
                        dataDetails?.status === "NEW"
                          ? "rgb(188, 188, 188)"
                          : dataDetails?.status === "PROCESSING"
                          ? "rgb(117, 218, 239)"
                          : "rgb(35, 216, 53)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "16px",
                      color: "#fff",
                    }}
                  >
                    {dataDetails?.status === "NEW"
                      ? "Mới"
                      : dataDetails?.status === "PROCESSING"
                      ? "Đang xử lí"
                      : "Hoàn thành"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "6px",
                  }}
                >
                  <p style={{ color: "rgb(59,115,172)" }}>
                    Nhân viên chăm sóc:
                  </p>
                  <div
                    style={{
                      width: "calc(100% - 160px)",
                      border: "solid 1px rgb(202,202,202)",
                      borderRadius: "4px",
                      padding: "4px 8px",
                    }}
                  >
                    {dataDetails?.supporter?.name}&nbsp;
                  </div>
                </div>

                {pageMenu.update && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                      marginTop: "8px",
                    }}
                  >
                    {state?.user?._id === dataDetails?.supporter?._id &&
                      dataDetails?.supporter?.name && (
                        <div
                        style={{
                          padding: "4px 8px",
                          border: "solid 1px rgb(59,115,172)",
                          borderRadius: "6px",
                          background: "rgb(56, 114, 174)",
                          cursor:
                          
                              "pointer",
                          color: "#fff",
  
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight:"8px"
                        }}
                          onClick={() => handleChangeStatus(dataDetails?._id)}
                        >
                          Chuyển trạng thái
                        </div>
                      )}
                    <div
                      style={{
                        padding: "4px 8px",
                        border: "solid 1px rgb(59,115,172)",
                        borderRadius: "6px",
                        background: "rgb(56, 114, 174)",
                        cursor:
                          state?.user?._id === dataDetails?.supporter?._id
                            ? "not-allowed"
                            : "pointer",
                        color: "#fff",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() =>
                        state?.user?._id !== dataDetails?.supporter?._id &&
                        handleAssign(1, dataDetails)
                      }
                    >
                      Phân công cho tôi
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal>
        <Form
          className="clients__search"
          style={{ display: "flex", flexWrap: "wrap" }}
          layout="vertical"
          form={form}
          onFinish={handleFinishForm}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Form.Item
                label="Tìm kiếm"
                name="q"
                style={{ width: "calc(28% - 6px)" }}
              >
                <Input placeholder="Nhập họ tên, email" />
              </Form.Item>
              <Form.Item
                label="Biểu mẫu đăng kí"
                name="form"
                style={{ width: "calc(39% - 6px)" }}
              >
                <Select
                  maxTagTextLength={12}
                  maxTagCount={1}
                  allowClear
                  options={groups}
                  placeholder="Tất cả"
                />
              </Form.Item>
              <Form.Item
                label="Nhân viên chăm sóc"
                name="supporter"
                style={{ width: "calc(16% - 6px)" }}
              >
                <Select
                  allowClear
                  options={
                    supports?.length >= 0 && [
                      ...supports,
                      { label: "Chưa phân công", value: "null" },
                    ]
                  }
                  placeholder="Tất cả"
                  disabled={tab !== 1}
                />
              </Form.Item>
              <Form.Item
                label="Trạng thái xử lí"
                name="status"
                style={{ width: "calc(17% - 6px)" }}
              >
                <Select
                  allowClear
                  options={[
                    { label: "Mới", value: "NEW" },
                    { label: "Đang xử lý", value: "PROCESSING" },
                    { label: "Hoàn thành", value: "COMPLETE" },
                  ]}
                  placeholder="Tất cả"
                  disabled={tab === 2}
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "-16px",
              }}
            >
              <Form.Item
                label="Mục tiêu mong muốn"
                name="target_usd"
                style={{ width: "calc(18% - 8px)" }}
              >
                <Select allowClear options={dataTargets} placeholder="Tất cả" />
              </Form.Item>
              <Form.Item
                label="Email dưới link DNI"
                name="affiliation"
                style={{ width: "calc(17% - 4px)" }}
              >
                <Select
                  allowClear
                  options={[
                    { label: "Có", value: true },
                    { label: "Không", value: false },
                  ]}
                  placeholder="Tất cả"
                />
              </Form.Item>
              <Form.Item
                label="ID dưới link DNI"
                name="ref_trade_account"
                style={{ width: "calc(16% - 4px)" }}
              >
                <Select
                  allowClear
                  options={[
                    { label: "Có", value: true },
                    { label: "Không", value: false },
                  ]}
                  placeholder="Tất cả"
                />
              </Form.Item>
              <Form.Item
                label="Đã check ID"
                name="checked_ref_trade_account_at"
                style={{ width: "calc(16% - 4px)" }}
              >
                <Select
                  allowClear
                  options={[
                    { label: "Đã check", value: true },
                    { label: "Chưa check", value: false },
                  ]}
                  placeholder="Tất cả"
                />
              </Form.Item>

              <Form.Item
                label="Nền tảng giao dịch"
                name="trade_app"
                style={{ width: "calc(16% - 4px)" }}
              >
                <Select
                  allowClear
                  options={[
                    { label: "MT4", value: "MT4" },
                    { label: "MT5", value: "MT5" },
                  ]}
                  placeholder="Tất cả"
                  disabled={tab === 2}
                />
              </Form.Item>
              <Form.Item
                label="Trạng thái tham gia"
                name="participation_status"
                style={{ width: "calc(17% - 4px)" }}
              >
                <Select
                  allowClear
                  options={[
                    { label: "Tham gia", value: "ACTIVE" },
                    { label: "Ngưng tham gia", value: "INACTIVE" },
                  ]}
                  placeholder="Tất cả"
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "-16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  width: "calc(50% - 208px)",
                }}
              >
                <div style={{ width: "100%" }}>Ngày điền</div>
                <Form.Item
                  style={{ width: "calc(50% - 12px)" }}
                  name="from_created_at"
                >
                  <DatePicker
                    placeholder="Từ"
                    style={{ width: "100%" }}
                    showTime
                  />
                </Form.Item>
                <div
                  style={{
                    width: "24px",
                    marginBottom: "24px",
                    textAlign: "center",
                  }}
                >
                  -
                </div>
                <Form.Item
                  style={{ width: "calc(50% - 12px" }}
                  name="to_created_at"
                >
                  <DatePicker
                    showTime
                    placeholder="đến"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  width: "calc(50% - 208px)",
                }}
              >
                <div style={{ width: "100%" }}>Ngày xuất Excel</div>
                <Form.Item
                  style={{ width: "calc(50% - 12px)" }}
                  name="from_exported_at"
                >
                  <DatePicker
                    placeholder="Từ"
                    style={{ width: "100%" }}
                    showTime
                  />
                </Form.Item>
                <div
                  style={{
                    width: "24px",
                    marginBottom: "24px",
                    textAlign: "center",
                  }}
                >
                  -
                </div>
                <Form.Item
                  style={{ width: "calc(50% - 12px" }}
                  name="to_exported_at"
                >
                  <DatePicker
                    placeholder="đến"
                    showTime
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>

              <Form.Item
                label="Đã xuất excel"
                name="exported_at"
                style={{ width: "140px" }}
              >
                <Select
                  allowClear
                  options={[
                    { label: "Chưa xuất", value: false },
                    { label: "Đã xuất", value: true },
                  ]}
                  placeholder="Tất cả"
                />
              </Form.Item>
              <Button
                className="clients__search__button"
                onClick={() => form.submit()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiOutlineSearch size={20} />
                &nbsp;Tìm kiếm
              </Button>
              <Button
                className="clients__search__button"
                onClick={() => {
                  form.resetFields();
                  form.setFieldsValue({
                    supporter:
                      tab === 2
                        ? "null"
                        : tab === 3
                        ? state?.user?._id
                        : undefined,
                    status: tab === 2 ? "NEW" : undefined,
                  });
                  setFilter({
                    q: undefined,
                    form: undefined,
                    supporter:
                      tab === 2
                        ? "null"
                        : tab === 3
                        ? state?.user?._id
                        : undefined,
                    affiliation: undefined,
                    status: tab === 2 ? "NEW" : undefined,
                    participation_status: undefined,
                    from_created_at: undefined,
                    to_created_at: undefined,
                    from_exported_at: undefined,
                    to_exported_at: undefined,
                    exported_at: undefined,
                    checked_ref_trade_account_at: undefined,
                    ref_trade_account: undefined,
                    page: 1,
                    limit: 10,
                  });
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AiOutlineReload size={20} />
                &nbsp;Refresh
              </Button>
            </div>
          </div>
        </Form>
        <div
          style={{
            display: "flex",
            alignItems: "center",

            justifyContent: "space-between",
            marginTop: "-12px",
            marginBottom: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {pageMenu.assign && (
              <Button
                className="clients__search__button"
                onClick={() => setOpenModalAssign(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(56, 114, 174)",
                  color: "#fff",
                }}
              >
                Phân bổ
              </Button>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Popover
              placement="bottom"
              content={
                <div
                  style={{
                    width: "120px",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <div
                style={{
                  width: "100%",
                  cursor: "pointer",
                  padding: "2px 4px",
                }}
                className="hoverGray"
                onClick={() => handleSample()}
              >
                Tải file mẫu
              </div>
              <div
                style={{
                  width: "100%",
                  cursor: "pointer",
                  padding: "2px 4px",
                }}
                className="hoverGray"
                onClick={() => importFileCallback()}
              >
                Nhập file
              </div> */}
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => setOpenDownloadSample(true)}
                  >
                    Tải file mẫu
                  </div>
                  {pageMenu.import && (
                    <div
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        padding: "2px 4px",
                      }}
                      className="hoverGray"
                      onClick={() => importFileCallback()}
                    >
                      Nhập file
                    </div>
                  )}
                  {pageMenu.export && (
                    <div
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        padding: "2px 4px",
                      }}
                      className="hoverGray"
                      onClick={() => setOpenDownload(true)}
                    >
                      Xuất file
                    </div>
                  )}
                </div>
              }
              trigger="click"
            >
              <Button
                className="clients__search__button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "8px",
                  background: "rgb(56, 114, 174)",
                  color: "#fff",
                }}
              >
                Thao tác Excel
              </Button>
            </Popover>
            {pageMenu.sync && (
              <Button
                className="clients__search__button"
                onClick={() => handleSync()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(56, 114, 174)",
                  color: "#fff",
                }}
              >
                Đồng bộ
              </Button>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            borderBottom: "1px solid rgb(117, 218, 239)",
            fontSize: "14px",
          }}
        >
          {listTab.map((x, index) => (
            <div
              key={index}
              style={
                tab === x?.position
                  ? {
                      transform: "translateY(0.5px)",
                      height: "32px",
                      border: "solid 1px rgb(117, 218, 239)",
                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 4px",
                      borderBottom: "solid 1px #fff",
                    }
                  : { padding: "2px 4px" }
              }
            >
              <div
                style={
                  tab !== x?.position
                    ? {
                  


                        border: "1px solid rgb(189,189,189)",
                        background: "rgb(243,243,243)",
                        borderRadius: "3px",
                        height: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0 8px",
                        cursor: "pointer",
                      }
                    : { padding: "0 8px" }
                }
                onClick={() => {
                  if (tab !== 1 && x?.position === 1) {
                    setTab(1);
                    setFilter({
                      ...filter,
                      status: undefined,
                      supporter: undefined,
                      page: 1,
                    });
                    form.setFieldsValue({
                      status: undefined,
                      supporter: undefined,
                    });
                  }
                  if (tab !== 2 && x?.position === 2) {
                    setTab(2);
                    setFilter({
                      ...filter,
                      status: "NEW",
                      supporter: "null",
                      page: 1,
                    });
                    form.setFieldsValue({
                      status: "NEW",
                      supporter: "null",
                    });
                  }
                  if (tab !== 3 && x?.position === 3) {
                    setTab(3);
                    setFilter({
                      ...filter,
                      supporter: state?.user?._id,
                      status: undefined,
                      page: 1,
                    });
                    form.setFieldsValue({
                      status: undefined,
                      supporter: state?.user?._id,
                    });
                  }
                }}
              >
                {x?.tab_name}&nbsp;({x?.tab_count})
              </div>
            </div>
          ))}
        </div>
        <Table
          bordered
          scroll={{ x: 1500 }}
          className="custom__column1 newpadding "
          rowKey="_id"
          columns={columnsData(
            {
              state,
              handleSync,
              handleChangeStatus,
              handleChangeStatusPart,
              handleAssign,
              handleOpenModal,
              handleSort,
              filter,
            },
            pageMenu
          )}
          rowSelection={rowSelection}
          dataSource={dataSource}
          pagination={false}
        />
        <PanigationAntStyled
          style={{ padding: "0 0 24px 0" }}
          current={filter.page}
          pageSize={filter.limit}
          showSizeChanger
          onChange={onChangePaging}
          showTotal={() => `Tổng ${total}`}
          total={total}
        />
      </div>
    )
  );
};

export default FormAnswer;
