/* eslint-disable */

import { Popover, Switch } from "antd";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";

export const columnsData = ({ handleChangeStatus, handleEdit }, pageMenu) => {
  return [
    {
      title: "Tên người dùng",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)", fontWeight: "bold" }}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record, index) => {
        return <div style={{ color: "rgb(56, 114, 174)" }}>{text}</div>;
      },
    },
    {
      title: "Vai trò",
      dataIndex: "role",
      key: "role",

      render: (text, record, index) => {
        return (
          <span
            style={{
              height: "32px",
              background: "rgb(56, 114, 174)",
              padding: "6px 12px",
              borderRadius: "16px",
              color: "#fff",
            }}
          >
            {record?.role_info?.name}
          </span>
        );
      },
    },
    {
      title: "Trạng thái hoạt động",
      dataIndex: "status",
      key: "status ",
      render: (text, record, index) => {
        return (
          <Switch
            checked={text === "ACTIVE" ? true : false}
            disabled={!pageMenu.update}
            onChange={(e) => pageMenu.update && handleChangeStatus(e, record)}
          />
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record, index) => {
        return (
          <Popover
            placement="bottom"
            content={
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {pageMenu.update && (
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => handleEdit(1, record)}
                  >
                    Chỉnh sửa thông tin
                  </div>
                )}
                {pageMenu.update && (
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => handleEdit(2, record)}
                  >
                    Cấp lại mật khẩu
                  </div>
                )}
                {pageMenu.update && (
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => handleEdit(3, record)}
                  >
                    Lịch sử thao tác
                  </div>
                )}

                {/* <div
                style={{
                  width: "100%",
                  cursor: "pointer",
                  padding: "2px 4px",
                }}
                className="hoverGray"
                onClick={() => handleSync(record.id)}
              >
                Đồng bộ
              </div> */}
              </div>
            }
            trigger="click"
          >
            <PiDotsThreeOutlineVerticalBold
              size={24}
              style={{ cursor: "pointer" }}
            />
          </Popover>
        );
      },
    },
  ];
};
