/* eslint-disable */

import { Popover, Switch, Tooltip } from "antd";
import moment from "moment";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  BiSolidError,
  BiSolidErrorCircle,
  BiSolidDownArrow,
  BiSolidUpArrow,
} from "react-icons/bi";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
import { FiMinus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";

export const columnsData = (
  {
    handleChangeStatus,
    navigate,
    handleSortByVolume,
    handleSortByDate,
    handleSortByUSD,
    handleSortByName,
    handleSortByEmail,
    handleSortByCount,
    filter,
    handleSync,
    handleExpand,
  },
  pageMenu
) => {
  return [
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Họ tên</div>
          <div style={{ width: "20px" }} onClick={() => handleSortByName()}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "name" && filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "name" && filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 200,
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              cursor: pageMenu.details ? "pointer" : "unset",
            }}
            onClick={() =>
              pageMenu.details && navigate(`/clients/${record.id}`)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Email đăng ký Exness</div>
          <div style={{ width: "20px" }} onClick={() => handleSortByEmail()}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "email" && filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "email" && filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "email",
      key: "email",
      width: 300,
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}&nbsp;&nbsp;
            {record?.affiliation ? (
              <AiFillCheckCircle color="rgb(57,174,145)" size={24} />
            ) : (
              <BiSolidErrorCircle color="red" size={24} />
            )}
          </div>
        );
      },
    },
    {
      title: "Số điện thoại - Zalo",
      dataIndex: "email",
      key: "email",
      width: 140,
      render: (text, record, index) => {
        return (
          <>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record?.phone ? record?.phone : "-"}
            </div>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record?.zalo_phone ? record?.zalo_phone : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Tổng khối lượng giao dịch (lots)</div>
          <div style={{ width: "20px" }} onClick={() => handleSortByVolume()}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "volumn_lots" &&
                  filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "volumn_lots" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "volumn_lots",
      key: "volumn_lots",
      align: "right",
      width: 140,
      render: (text, record, index) => {
        return (
          <>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record?.volumn_lots ? record?.volumn_lots : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Tổng lợi nhuận (USD)</div>
          <div style={{ width: "20px" }} onClick={() => handleSortByUSD()}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "reward_usd" &&
                  filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "reward_usd" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "reward_usd",
      key: "reward_usd",
      width: 130,
      align: "right",
      render: (text, record, index) => {
        return (
          <>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record?.reward_usd ? record?.reward_usd : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Số lượng tài khoản giao dịch</div>
          <div style={{ width: "20px" }} onClick={() => handleSortByCount()}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "count_trade_account" &&
                  filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "count_trade_account" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "count_trade_account",
      key: "count_trade_account",
      width: 130,
      align: "right",
      render: (text, record, index) => {
        return (
          <>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record?.count_trade_account ? record?.count_trade_account : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Lần giao dịch gần nhất</div>
          <div style={{ width: "20px" }} onClick={() => handleSortByDate()}>
            <BiSolidUpArrow
              style={{
                marginBottom: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "last_trade_at" &&
                  filter?.sort_type === "ASC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
            <BiSolidDownArrow
              style={{
                marginTop: "-8px",
                cursor: "pointer",
                color:
                  filter?.sort_by === "last_trade_at" &&
                  filter?.sort_type === "DESC"
                    ? "rgb(0,0,0)"
                    : "rgb(147,147,147)",
              }}
            />
          </div>
        </div>
      ),
      dataIndex: "last_trade_at",
      key: "last_trade_at",
      align: "right",
      width: 130,

      render: (text, record, index) => {
        return (
          <>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record?.last_trade_at ? moment(text)?.format("DD-MM-YYYY") : "-"}
            </div>
            <div style={{ color: "rgb(56, 114, 174)" }}>
              {record?.last_trade_client_account
                ? "(" + record?.last_trade_client_account + ")"
                : "-"}
            </div>
          </>
        );
      },
    },
    {
      title: "Nhóm",
      dataIndex: "supporter_info",
      width: 250,
      key: "supporter_info",
      render: (text, record, index) => {
        if (record?.groups_info?.length <= 2) {
          return (
            <div
              style={{
                display: "flex",
                position: "relative",

                width: "100%",
              }}
            >
              <div
                style={{
                  color: "rgb(56, 114, 174)",
                  paddingRight: "30px",
                  width: "100%",
                }}
              >
                {record?.groups_info?.map((x, index) => (
                  <Tooltip title={x?.name}>
                    <span
                      key={index}
                      style={{
                        border: "solid 1px rgb(56, 114, 174)",
                        borderRadius: "4px",
                        background: "rgb(56, 114, 174,0.2)",
                        padding: "2px 4px",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        margin: "3px 3px 0 0",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                      }}
                    >
                      {x?.name}
                    </span>
                  </Tooltip>
                ))}
              </div>
            </div>
          );
        } else {
          return (
            <div style={{ display: "flex", position: "relative" }}>
              {record.expand ? (
                <div
                  style={{
                    color: "rgb(56, 114, 174)",
                    paddingRight: "30px",
                    width: "100%",
                  }}
                >
                  {record?.groups_info?.map((x, index) => (
                    <Tooltip title={x?.name}>
                      <span
                        key={index}
                        style={{
                          border: "solid 1px rgb(56, 114, 174)",
                          borderRadius: "4px",
                          background: "rgb(56, 114, 174,0.2)",
                          padding: "2px 4px",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          margin: "3px 3px 0 0",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "100%",
                        }}
                      >
                        {x?.name}
                      </span>
                    </Tooltip>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    color: "rgb(56, 114, 174)",
                    paddingRight: "30px",
                    width: "100%",
                  }}
                >
                  {record?.groups_info?.slice(0, 2).map((x, index) => (
                    <Tooltip title={x?.name}>
                      <span
                        key={index}
                        style={{
                          border: "solid 1px rgb(56, 114, 174)",
                          borderRadius: "4px",
                          background: "rgb(56, 114, 174,0.2)",
                          padding: "2px 4px",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          margin: "3px 3px 0 0",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "100%",
                        }}
                      >
                        {x?.name}
                      </span>
                    </Tooltip>
                  ))}
                </div>
              )}
              <div
                style={{
                  border: "dotted 1px rgb(56, 114, 174)",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "20px",
                  height: "20px",
                  position: "absolute",
                  left: "calc(90% + 5px)",
                  bottom: "6px",
                  cursor: "pointer",
                }}
                onClick={() => handleExpand(record)}
              >
                {record.expand ? <FiMinus size={16} /> : <FaPlus size={12} />}
              </div>
            </div>
          );
        }
      },
    },
    // {
    //   title: "Vai trò",
    //   dataIndex: "role",
    //   key: "role",
    //   width: "15%",
    //   render: (text, record, index) => {
    //     return (
    //       <div
    //         style={{
    //           width: "80px",
    //           height: "32px",
    //           background: "rgb(56, 114, 174)",
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //           borderRadius: "16px",
    //           color: "#fff",
    //         }}
    //       >
    //         {text?.charAt(0) + text?.slice(1)?.toLowerCase()}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Trạng thái hoạt động",
    //   dataIndex: "status",
    //   key: "status ",
    //   width: "20%",
    //   render: (text, record, index) => {
    //     return (
    //       <Switch
    //         checked={text === "ACTIVE" ? true : false}
    //         onChange={(e) => handleChangeStatus(e, record)}
    //       />
    //     );
    //   },
    // },
    {
      title: "Thao tác",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      width: 80,
      align: "center",
      render: (text, record, index) => {
        return (
          <Popover
            placement="bottom"
            content={
              <div
                style={{
                  width: "120px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {pageMenu.sync && (
                  <div
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      padding: "2px 4px",
                    }}
                    className="hoverGray"
                    onClick={() => handleSync(record.email)}
                  >
                    Đồng bộ
                  </div>
                )}
              </div>
            }
            trigger="click"
          >
            <PiDotsThreeOutlineVerticalBold
              size={24}
              style={{ cursor: "pointer" }}
            />
          </Popover>
        );
      },
    },
  ];
};
