import {
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Table,
  Modal,
  Switch,
  Checkbox,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { AiOutlineSearch } from "react-icons/ai";
import { PiUserCirclePlusFill } from "react-icons/pi";
import { BiSave } from "react-icons/bi";
import moment from "moment";
import {
  localGetMenu,
  localRemoveMenu,
  localGetRefreshToken,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useNavigate, useParams } from "react-router-dom";
import OverlaySpinner from "../../../components/overlaySpinner/OverlaySpinner";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import { UserContext } from "../../../context/context";

const RoleDetails = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [form] = useForm();
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  const getData = async () => {
    setLoading(true);

    try {
      let { data } = await api.get(`${API_URL}api/v1/roles/${params?.id}`, {});
      setDataSource(data);
      form.setFieldsValue({
        name: data?.name,
        code: data?.code,
        status: data?.status,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    pageMenu.details && getData();
  }, [pageMenu.details]);

  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/clients");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);

  const handleChangeStatus = (value, parent, children) => {
    let fake = [...dataSource?.menu];
    for (let i = 0; i < fake?.length; i++) {
      if (fake[i]?.code === parent?.code) {
        fake[i].permissions.map((x) =>
          x.permission === children.permission
            ? (x.status = value ? "ACTIVE" : "DEACTIVE")
            : x
        );
        break;
      }
      for (let j = 0; j < fake[i]?.children?.length; j++) {
        if (fake[i]?.children[j]?.code === parent?.code) {
          fake[i]?.children[j]?.permissions.map((x) =>
            x.permission === children.permission
              ? (x.status = value ? "ACTIVE" : "DEACTIVE")
              : x
          );

          break;
        }
        for (let k = 0; k < fake[i]?.children[j]?.children?.length; k++) {
          if (fake[i]?.children[j]?.children[k]?.code === parent?.code) {
            fake[i]?.children[j]?.children[k]?.permissions.map((x) =>
              x.permission === children.permission
                ? (x.status = value ? "ACTIVE" : "DEACTIVE")
                : x
            );

            break;
          }
          for (
            let l = 0;
            l < fake[i]?.children[j]?.children[k]?.children?.length;
            l++
          ) {
            if (
              fake[i]?.children[j]?.children[k]?.children[l]?.code ===
              parent?.code
            ) {
              fake[i]?.children[j]?.children[k]?.children[l]?.permissions.map(
                (x) =>
                  x.permission === children.permission
                    ? (x.status = value ? "ACTIVE" : "DEACTIVE")
                    : x
              );

              break;
            }
          }
        }
      }
    }
    setDataSource({ ...dataSource, menu: fake });
  };

  const handleFinishForm = async (value) => {
    setLoading(true);
    try {
      let { data } = await api.put(`${API_URL}api/v1/roles/${params?.id}`, {
        name: value?.name,
        status: value?.status,
        menu: [...dataSource.menu],
      });

      setLoading(false);

      notifySuccess("Đổi thông tin thành công!");
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishForm(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  return (
    pageMenu.details && (
      <div className="userSystem" style={{ fontSize: "14px" }}>
        <OverlaySpinner text="Vui lòng đợi ..." open={loading} />

        <Form
          className="userSystem__search"
          layout="vertical"
          form={form}
          onFinish={handleFinishForm}
        >
          <Form.Item
            label="Tên vai trò"
            name="name"
            className="userSystem__search__input"
            style={{ width: "calc((100% - 144px) /3)" }}
          >
            <Input disabled={!pageMenu.update} />
          </Form.Item>
          <Form.Item
            label="Code"
            name="code"
            className="userSystem__search__input"
            style={{ width: "calc((100% - 144px) /3)" }}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Trạng thái"
            name="status"
            className="userSystem__search__select"
            style={{ width: "calc((100% - 144px) /3)" }}
          >
            <Select
              disabled={!pageMenu.update}
              options={[
                { value: "ACTIVE", label: "Hoạt động" },
                { value: "INACTIVE", label: "Dừng hoạt động" },
              ]}
            />
          </Form.Item>
          {pageMenu.update ? (
            <Button
              className="userSystem__search__button"
              onClick={() => form.submit()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BiSave size={20} />
              &nbsp;Lưu
            </Button>
          ) : (
            <div style={{ width: "120px" }}></div>
          )}
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {dataSource?.menu?.length > 0 &&
            dataSource?.menu?.map((x, index) => (
              <div
                style={{
                  borderLeft: index !== 0 && "solid 1px #000",
                  width: `calc(100% / ${dataSource?.menu?.length})`,
                  paddingLeft: "24px",
                  color: "#000",
                }}
                key={index}
              >
                <div style={{ fontWeight: "bold" }}>{x.name}</div>
                <div style={{ marginLeft: "8px" }}>
                  {x?.permissions?.map((xx, indexXX) => (
                    <Checkbox
                      disabled={!pageMenu.update}
                      style={{ width: "100%", marginTop: "4px", color: "#000" }}
                      checked={xx.status === "ACTIVE" ? true : false}
                      onChange={(e) =>
                        handleChangeStatus(e.target.checked, x, xx)
                      }
                      key={indexXX}
                    >
                      {xx.name}
                    </Checkbox>
                  ))}
                </div>
                <div style={{ marginLeft: "16px" }}>
                  {x?.children?.map((y, indexY) => (
                    <div style={{ marginTop: "8px" }} key={indexY}>
                      <div style={{ fontWeight: "bold" }}>{y?.name}</div>
                      <div>
                        {y?.permissions?.map((yy, indexYY) => (
                          <Checkbox
                            disabled={!pageMenu.update}
                            style={{
                              width: "100%",
                              marginTop: "4px",
                              color: "#000",
                            }}
                            checked={yy.status === "ACTIVE" ? true : false}
                            onChange={(e) =>
                              handleChangeStatus(e.target.checked, y, yy)
                            }
                            key={indexYY}
                          >
                            {yy.name}
                          </Checkbox>
                        ))}
                      </div>
                      <div style={{ marginLeft: "16px" }}>
                        {y?.children?.map((z, indexZ) => (
                          <div style={{ marginTop: "8px" }} key={indexZ}>
                            <div style={{ fontWeight: "bold" }}>{z?.name}</div>
                            <div>
                              {z?.permissions?.map((zz, indexZZ) => (
                                <Checkbox
                                  disabled={!pageMenu.update}
                                  style={{
                                    width: "100%",
                                    marginTop: "4px",
                                    color: "#000",
                                  }}
                                  checked={
                                    zz.status === "ACTIVE" ? true : false
                                  }
                                  onChange={(e) =>
                                    handleChangeStatus(e.target.checked, z, zz)
                                  }
                                  key={indexZZ}
                                >
                                  {zz.name}
                                </Checkbox>
                              ))}
                            </div>
                            <div style={{ marginLeft: "16px" }}>
                              {z?.children?.map((i, indexI) => (
                                <div style={{ marginTop: "8px" }} key={indexI}>
                                  <div style={{ fontWeight: "bold" }}>
                                    {i?.name}
                                  </div>
                                  <div>
                                    {i?.permissions?.map((ii, indexII) => (
                                      <Checkbox
                                        disabled={!pageMenu.update}
                                        style={{
                                          width: "100%",
                                          marginTop: "4px",
                                          color: "#000",
                                        }}
                                        checked={
                                          ii.status === "ACTIVE" ? true : false
                                        }
                                        onChange={(e) =>
                                          handleChangeStatus(
                                            e.target.checked,
                                            i,
                                            ii
                                          )
                                        }
                                        key={indexII}
                                      >
                                        {ii.name}
                                      </Checkbox>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  );
};

export default RoleDetails;
