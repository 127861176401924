import {
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Table,
  Modal,
  Switch,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { columnsData } from "./data";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../components/notification";
import { api } from "../../services/api/api.index";
import { API_URL } from "../../services/api/config";
import { AiOutlineReload, AiOutlineSearch } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import {
  newValidate,
  validateEmail,
} from "../../utils/helpers/functions/textUtils.js";

import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import OverlaySpinner from "../../components/overlaySpinner/OverlaySpinner";
import axios from "axios";
import { UserContext } from "../../context/context";
const { RangePicker } = DatePicker;
const UserSystem = () => {
  const [dataSource, setDataSource] = useState([]);
  const [formEditInfo] = Form.useForm();
  const [formEditPassword] = Form.useForm();

  const [openModal, setOpenModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [form] = Form.useForm();
  const [status, setStatus] = useState(false);
  const [statusEdit, setStatusEdit] = useState(false);

  const [total, setTotal] = useState(10);
  const [formCreate] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [openModalEditInfo, setOpenModalEditInfo] = useState(false);
  const [openModalEditPassword, setOpenModalEditPassword] = useState(false);

  const [recordEdit, setRecordEdit] = useState(undefined);
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    q: undefined,
    status: undefined,
    role: undefined,
    from_date: undefined,
    to_date: undefined,
    page: 1,
    limit: 1000,
  });
  const getData = async () => {
    setLoading(true);
    try {
      let data = await api.get(`${API_URL}api/v1/users`, {
        ...filter,
      });
      setTotal(data?.metadata?.totalItems);
      setDataSource(data?.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const getRoles = async () => {
    try {
      let { data } = await api.get(`${API_URL}api/v1/roles`);
      setRoles(
        data.map((x) => {
          return {
            value: x?.id,
            label: x?.name,
          };
        })
      );
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getRoles();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishForm = (e) => {
    setFilter({
      ...filter,
      q: e?.q?.length > 0 ? e?.q?.trim() : undefined,
      status: e?.status,
      role: e?.role,
      from_date: e?.date ? e.date[0].format("YYYY-MM-DD") : undefined,
      to_date: e?.date ? e.date[1].format("YYYY-MM-DD") : undefined,
    });
  };

  const handleFinishFormCreate = async (value) => {
    try {
      let { data } = await api.post(`${API_URL}api/v1/users`, {
        name: value?.name,
        email: value?.email,
        phone: value?.phone,
        password: value?.password,
        role: value?.role,
        status: status ? "ACTIVE" : "INACTIVE",
      });
      setOpenModal(false);
      formCreate.resetFields();
      notifySuccess("Tạo tài khoản mới thành công!");
      getData();
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormCreate(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleFinishFormEditInfo = async (value) => {
    try {
      let { data } = await api.put(`${API_URL}api/v1/users/${recordEdit._id}`, {
        name: value?.name,
        email: value?.email,
        phone: value?.phone,
        role: value?.role,
        status: statusEdit ? "ACTIVE" : "INACTIVE",
      });
      formEditInfo.resetFields();
      setRecordEdit(undefined);
      getData();
      notifySuccess("Thay đổi thông tin thành công!");
      setOpenModalEditInfo(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormEdit(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishFormEditPassword = async (value) => {
    if (value?.password !== value?.rePassword) {
      notifyWarning("Mật khẩu xác nhận không chính xác!");
      return;
    }
    try {
      let { data } = await api.put(
        `${API_URL}api/v1/users/${recordEdit._id}/set-password`,
        {
          password: value?.password,
        }
      );
      formEditPassword.resetFields();
      setRecordEdit(undefined);
      getData();
      notifySuccess("Cấp lại mật khẩu thành công!");
      setOpenModalEditPassword(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormEditPassword(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleChangeStatus = async (value, record) => {
    setLoading(true);
    try {
      let { data } = await api.put(`${API_URL}api/v1/users/${record.id}`, {
        status: value ? "ACTIVE" : "INACTIVE",
      });
      let newList = [...dataSource].map((x) =>
        x.id === record.id ? { ...x, status: value ? "ACTIVE" : "INACTIVE" } : x
      );
      setDataSource(newList);
      notifySuccess("Đổi trạng thái thành công!");
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleChangeStatus(value, record);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13 || event.key == "Enter") {
      form.submit();
    }
  };

  const onChangePaging = (page, pageSize) => {
    setFilter({
      ...filter,

      page: page,
      limit: pageSize,
    });
  };

  const { state } = useContext(UserContext);

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/clients");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);
  useEffect(() => {
    getData();
  }, [filter]);
  useEffect(() => {
    getRoles();
  }, []);

  const handleEdit = (type, record) => {
    console.log(record);
    setRecordEdit(record);
    if (type === 1) {
      setOpenModalEditPassword(false);
      setStatusEdit(record?.status === "ACTIVE" ? true : false);
      setOpenModalEditInfo(true);
      formEditInfo.setFieldsValue({
        name: record?.name,
        phone: record?.phone,
        email: record?.email,
        role: record?.role,
      });
    } else {
      setOpenModalEditInfo(false);

      setOpenModalEditPassword(true);
    }
  };
  return (
    <div
      className="userSystem"
      onKeyDown={handleKeyUp}
      tabIndex="0"
      style={{ outline: "none" }}
    >
      <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
      <Modal
        open={openModalEditInfo}
        title={"Thông tin người dùng hệ thống"}
        onCancel={(e) => {
          setOpenModalEditInfo(false);
          setRecordEdit(undefined);
        }}
        footer={null}
        centered
      >
        <Form
          layout="vertical"
          form={formEditInfo}
          onFinish={handleFinishFormEditInfo}
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Form.Item
            label="Họ tên:"
            name="name"
            className="userSystem__search__input"
            style={{ width: "100%", marginBottom: "16px" }}
          >
            <Input placeholder="Nhập họ tên" />
          </Form.Item>

          <Form.Item
            label="Số điện thoại:"
            name="phone"
            className="userSystem__search__input"
            style={{ width: "100%", marginBottom: "16px" }}
            rules={[
              { required: true, message: "Vui lòng không bỏ trống" },

              {
                validator(rule, value) {
                  return new Promise((resolve, reject) => {
                    if (!value || newValidate(value.trim())) {
                      resolve();
                    } else {
                      reject("Vui lòng nhập đúng định dạng số điện thoại1");
                    }
                  });
                },
              },
            ]}
          >
            <Input placeholder="Nhập số điện thoại" />
          </Form.Item>

          <Form.Item
            label="Email:"
            name="email"
            className="userSystem__search__input"
            style={{ width: "100%", marginBottom: "16px" }}
            rules={[
              { required: true, message: "Vui lòng không bỏ trống" },

              {
                validator(rule, value) {
                  return new Promise((resolve, reject) => {
                    if (value && validateEmail(value.trim())) {
                      resolve();
                    } else {
                      reject("Vui lòng nhập đúng định dạng email");
                    }
                  });
                },
              },
            ]}
          >
            <Input placeholder="Nhập email" />
          </Form.Item>
          <Form.Item
            label="Vai trò"
            name="role"
            style={{ width: "100%", marginBottom: "16px" }}
          >
            <Select allowClear options={roles} placeholder="Tất cả" />
          </Form.Item>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "8px",
            }}
          >
            <div style={{ width: "100%", marginTop: "12px" }}>
              <span style={{ fontSize: "14px", marginRight: "12px" }}>
                Trạng thái hoạt động:
              </span>
              <Switch checked={statusEdit} onChange={(e) => setStatusEdit(e)} />
            </div>
            <Button
              className="userSystem__search__button"
              style={{
                background: "rgb(56, 114, 174)",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "120px",
              }}
              onClick={() => formEditInfo.submit()}
            >
              <BiSave size={20} />
              &nbsp; Lưu
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={openModalEditPassword}
        title={"Cấp lại mật khẩu"}
        onCancel={(e) => {
          setOpenModalEditPassword(false);
          setRecordEdit(undefined);
        }}
        footer={null}
        centered
      >
        <Form
          layout="vertical"
          form={formEditPassword}
          onFinish={handleFinishFormEditPassword}
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Form.Item
              required
              label="Mật khẩu mới:"
              name="password"
              className="userSystem__search__input"
              style={{ width: "100%", margin: "6px 0" }}
            >
              <Input.Password placeholder="Nhập mật khẩu mới" />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Form.Item
              required
              label="Nhập lại mật khẩu mới:"
              name="rePassword"
              className="userSystem__search__input"
              style={{ width: "100%", margin: "6px 0" }}
            >
              <Input.Password placeholder="Nhập lại mật khẩu mới" />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              width: "100%",
              marginTop: "8px",
            }}
          >
            <Button
              className="userSystem__search__button"
              style={{
                background: "rgb(56, 114, 174)",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => formEditPassword.submit()}
            >
              <BiSave size={20} />
              &nbsp; Lưu
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={openModal}
        onCancel={(e) => {
          setOpenModal(false);
          formCreate.resetFields();
        }}
        footer={null}
        centered
      >
        <div
          style={{
            color: "rgb(56, 114, 174)",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Tạo mới người dùng hệ thống
        </div>
        <Form
          layout="vertical"
          form={formCreate}
          onFinish={handleFinishFormCreate}
          style={{ marginTop: "16px" }}
          initialValues={{ status: true }}
        >
          <Form.Item
            label="Họ tên"
            name="name"
            placeholder="Nhập họ tên"
            style={{ width: "100%" }}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Số điện thoại"
            name="phone"
            required
            placeholder="Nhập số điện thoại"
            style={{ width: "100%" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            type="email"
            placeholder="Nhập email"
            style={{ width: "100%" }}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Mật khẩu"
            name="password"
            placeholder="Nhập mật khẩu"
            style={{ width: "100%" }}
            required
          >
            <Input />
          </Form.Item>

          <Form.Item label="Vai trò" name="role" style={{ width: "100%" }}>
            <Select allowClear options={roles} placeholder="Tất cả" />
          </Form.Item>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Form.Item name="status" style={{ width: "100%" }}>
              <Switch checked={status} onChange={(e) => setStatus(e)} />
              &nbsp;Trạng thái hoạt động
            </Form.Item>
            <Button
              className="userSystem__search__button"
              style={{
                marginTop: "-24px",
                background: "rgb(56, 114, 174)",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => formCreate.submit()}
            >
              <BiSave size={20} />
              &nbsp; Tạo mới
            </Button>
          </div>
        </Form>
      </Modal>
      <Form
        className="userSystem__search"
        layout="vertical"
        form={form}
        onFinish={handleFinishForm}
      >
        <Form.Item
          label="Tìm kiếm"
          name="q"
          className="userSystem__search__input"
          style={{
            width: pageMenu.create
              ? "calc(100% - 878px)"
              : "calc(100% - 750px)",
          }}
        >
          <Input placeholder="Nhập họ tên, email" />
        </Form.Item>
        <Form.Item
          label="Vai trò"
          name="role"
          className="userSystem__search__select"
        >
          <Select allowClear options={roles} placeholder="Tất cả" />
        </Form.Item>
        <Form.Item
          label="Trạng thái"
          name="status"
          className="userSystem__search__select"
        >
          <Select
            allowClear
            options={[
              { value: "ACTIVE", label: "Hoạt động" },
              { value: "INACTIVE", label: "Dừng hoạt động" },
            ]}
            placeholder="Tất cả"
          />
        </Form.Item>

        <Form.Item
          label="Ngày tạo"
          name="date"
          className="userSystem__search__date"
        >
          <RangePicker />
        </Form.Item>

        <Button
          className="userSystem__search__button"
          onClick={() => form.submit()}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AiOutlineSearch size={20} />
          &nbsp;Tìm kiếm
        </Button>
        <Button
          className="clients__search__button"
          onClick={() => {
            form.resetFields();
            setFilter({
              ...filter,
              q: undefined,
              status: undefined,
              role: undefined,
              from_date: undefined,
              to_date: undefined,
              page: 1,
              limit: 1000,
            });
          }}
        >
          <AiOutlineReload size={20} />
          &nbsp;Refresh
        </Button>
        {pageMenu.create && (
          <Button
            className="userSystem__search__button"
            onClick={() => setOpenModal(true)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FiPlusCircle size={20} />
            &nbsp;Tạo mới
          </Button>
        )}
      </Form>
      <Table
        bordered
        className="newpadding"
        rowKey="id"
        columns={columnsData({ handleChangeStatus, handleEdit }, pageMenu)}
        dataSource={dataSource}
        pagination={false}
      />
      {/* <PanigationAntStyled
        style={{ padding: "24px 0 24px 0" }}
        current={filter.page}
        pageSize={filter.limit}
        showSizeChanger
        onChange={onChangePaging}
        showTotal={() => `Tổng ${total}`}
        total={total}
      /> */}
    </div>
  );
};

export default UserSystem;
