import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../services/api/config";
import { api } from "../../services/api/api.index.js";
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiSolidError } from "react-icons/bi";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../components/notification";
import OverlaySpinner from "../../components/overlaySpinner/OverlaySpinner";
import {
  localGetMenu,
  localRemoveMenu,
  localGetRefreshToken,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../utils/localStorage";
import axios from "axios";
const CheckDiscount = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState(1);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataSelect, setDataSelect] = useState([]);
  const [selectedRebate, setSelectedRebate] = useState(undefined);
  const [dataUser, setDataUser] = useState({
    client_account: null,
    client_account_type: "",
    processed_rebate_usd: 0,
    rebate_percent: 0,
    reg_date: null,
    reward_usd: 0,
    volume_lots: 0,
    checked: false,
  });
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const openModalRebate = async (e) => {
    if (!(selectedRebate >= 0)) {
      notifyError("Vui lòng chọn mức chiết khấu");
      return;
    }
    setOpenModal(true);
  };
  const setRebate = async () => {
    setLoading(true);
    try {
      let { data } = await api.patch(
        `${API_URL}api/v1/partner-affiliation/set-rebate`,
        {
          account: dataUser?.client_account.toString(),
          rebate_percent: Number(selectedRebate),
        }
      );
      setDataUser({
        ...dataUser,
        rebate_percent: selectedRebate,
      });
      form.setFieldsValue({
        rebate_percent: selectedRebate,
      });
      setLoading(false);
      setOpenModal(false);
      setSelectedRebate(undefined);
      notifySuccess("Chỉ định khiết khấu thành công");
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          setRebate();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleFinishCheck = async (value) => {
    setLoading(true);
    try {
      let { data } = await api.post(
        `${API_URL}api/v1/partner-affiliation/check-rebate`,
        {
          account: value.login,
        }
      );
      setDataUser({
        client_account: data?.client_account,
        client_account_type: data?.client_account_type,
        processed_rebate_usd: data?.processed_rebate_usd,
        rebate_percent: data?.rebate_percent,
        reg_date: data?.reg_date,
        reward_usd: data?.reward_usd,
        volume_lots: data?.volume_lots,
        checked: true,
      });
      form.setFieldsValue({
        processed_rebate_usd: data?.processed_rebate_usd,
        rebate_percent: data?.rebate_percent + "%",
        reward_usd: data?.reward_usd,
        volume_lots: data?.volume_lots,
      });
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishCheck(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getData = async () => {
    try {
      let { data } = await api.get(`${API_URL}api/v1/rebate-levels`, {
        status: "ACTIVE",
      });
      let fakeData = [];
      for (let i = 0; i < data.length; i++) {
        fakeData.push({
          label: data[i].level + "%" + " - " + data[i].name,
          value: data[i].level,
        });
      }
      setDataSelect(fakeData);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="checkCustomer">
      <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
      <Modal
        open={openModal}
        onCancel={(e) => setOpenModal(false)}
        footer={null}
        centered
      >
        <div>Bạn chắc chắc thực hiện thao tác này?</div>
        <div
          style={{
            marginTop: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => setRebate()}
            style={{ width: "120px", marginRight: "8px" }}
          >
            Đồng ý
          </Button>
          <Button
            onClick={() => setOpenModal(false)}
            style={{ width: "120px", marginLeft: "8px" }}
          >
            Huỷ
          </Button>
        </div>
      </Modal>
      <div className="checkCustomer__content">
        {!dataUser.checked ? (
          <>
            <h4 className="checkCustomer__content__header">
              Kiểm tra % chiết khấu hoa hồng
            </h4>
            <Form
              form={form}
              onFinish={handleFinishCheck}
              className="checkCustomer__content__form"
            >
              <Form.Item
                className=""
                rules={[
                  { required: true, message: "Vui lòng không bỏ trống" },
                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        if (Number(value)) {
                          resolve();
                        } else {
                          reject("Vui lòng chỉ nhập ký tự số");
                        }
                      });
                    },
                  },
                ]}
                name="login"
                style={{ width: "100%" }}
              >
                <Input placeholder="Nhập id tài khoản giao dịch" />
              </Form.Item>

              <div
                className="checkCustomer__content__submit"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  loading={loading}
                  style={{ width: "100%" }}
                  onClick={() => form.submit()}
                >
                  Kiểm tra
                </Button>
              </div>
            </Form>
          </>
        ) : dataUser.client_account ? (
          <div
            style={{
              padding: "0 24px",
            }}
          >
            <h4 className="checkCustomer__content__header">
              Tài khoản giao dịch:{" "}
              <span
                style={{
                  fontStyle: "italic",
                  textDecoration: "underline",
                }}
              >
                {dataUser?.client_account}
              </span>
            </h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "8px",
              }}
            >
              <span style={{ fontSize: "14px" }}>Ngày đăng kí tài khoản</span>
              <span style={{ fontSize: "14px", color: "#fff" }}>
                {dataUser?.reg_date}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "24px",
              }}
            >
              <span style={{ fontSize: "14px" }}>Loại tài khoản</span>
              <span style={{ fontSize: "14px", color: "#fff" }}>
                {dataUser?.client_account_type}
              </span>
            </div>
            <Form form={form} className="checkCustomer__content__form">
              <span style={{ fontSize: "14px", color: "#fff" }}>
                Khối lượng giao dịch (lots)
              </span>
              <Form.Item
                className=""
                name="volume_lots"
                style={{ width: "100%", margin: "4px 0 8px 0" }}
              >
                <Input
                  disabled
                  style={{ background: "#fff", color: "rgb(54,113,173)" }}
                />
              </Form.Item>
              <span style={{ fontSize: "14px", color: "#fff" }}>
                Hoa hồng nhận được từ khách
              </span>
              <Form.Item
                className=""
                name="reward_usd"
                style={{ width: "100%", margin: "4px 0 8px 0" }}
              >
                <Input
                  disabled
                  style={{ background: "#fff", color: "rgb(54,113,173)" }}
                />
              </Form.Item>{" "}
              <span style={{ fontSize: "14px", color: "#fff" }}>
                Hoa hồng chiết khấu cho khách
              </span>
              <Form.Item
                className=""
                name="processed_rebate_usd"
                style={{ width: "100%", margin: "4px 0 8px 0" }}
              >
                <Input
                  disabled
                  style={{ background: "#fff", color: "rgb(54,113,173)" }}
                />
              </Form.Item>{" "}
              <span style={{ fontSize: "14px", color: "#fff" }}>
                % chiết khấu cho khách
              </span>
              <Form.Item
                className=""
                name="rebate_percent"
                style={{ width: "100%", margin: "4px 0 8px 0" }}
              >
                <Input
                  disabled
                  style={{ background: "#fff", color: "rgb(54,113,173)" }}
                />
              </Form.Item>
              <div style={{ width: "100%", margin: "32px 0 0 0" }}>
                <span style={{ fontSize: "14px", color: "#fff" }}>
                  Chọn mức chiết khấu cho khách
                </span>
                <Select
                  options={dataSelect}
                  style={{ width: "100%" }}
                  value={selectedRebate}
                  onChange={(e) => setSelectedRebate(e)}
                />
              </div>
              <div
                className="checkCustomer__content__submit"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "16px",
                }}
              >
                <Button
                  style={{ width: "100%" }}
                  onClick={() => {
                    openModalRebate();
                  }}
                >
                  Chỉ định chiết khấu
                </Button>
              </div>
              <div
                style={{ width: "100%", marginTop: "12px" }}
                className="checkCustomer__content__submit"
              >
                <Button
                  loading={loading}
                  className=""
                  style={{
                    width: "100%",
                    fontWeight: "700",
                  }}
                  onClick={() => {
                    setDataUser({
                      client_account: null,
                      client_account_type: "",
                      processed_rebate_usd: 0,
                      rebate_percent: 0,
                      reg_date: null,
                      reward_usd: 0,
                      volume_lots: 0,
                      checked: false,
                    });
                    setSelectedRebate(undefined);
                    form.setFieldValue("login", null);
                  }}
                >
                  Quay lại
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              padding: "0 24px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  background: "#fff",
                  width: "10px",
                  height: "30px",
                  top: "15px",
                  left: "25px",
                  zIndex: "0",
                }}
              />
              <div style={{ position: "absolute", zIndex: "1" }}>
                <BiSolidError color="red" size={60} />
              </div>
            </div>
            <div
              style={{
                marginTop: "24px",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Xin lỗi, tài khoản giao dịch này chưa phát sinh giao dịch hoặc
              không thuộc DNI nên không thể gán chiết khấu %.
            </div>
            <div
              style={{ width: "100%" }}
              className="checkCustomer__content__submit"
            >
              <Button
                loading={loading}
                className=""
                style={{
                  marginTop: "24px",
                  width: "100%",
                  fontWeight: "700",
                }}
                onClick={() => {
                  setDataUser({
                    client_account: null,
                    client_account_type: "",
                    processed_rebate_usd: 0,
                    rebate_percent: 0,
                    reg_date: null,
                    reward_usd: 0,
                    volume_lots: 0,
                    checked: false,
                  });
                  setSelectedRebate(undefined);
                  form.setFieldValue("login", null);
                }}
              >
                Quay lại
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckDiscount;
