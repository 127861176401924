/* eslint-disable */

import { Button, Checkbox, Input, Select, Switch } from "antd";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
import { RiDeleteBin2Fill } from "react-icons/ri";

export const columnsData = ({ handleChange }) => {
  return [
    {
      key: "sort",
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      width: "calc(70% - 46px)",

      render: (text, record, index) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              {record?.label}
              {/* <Input
                defaultValue={record?.label}
                onMouseLeave={(e) => handleChange(1, record.id, e.target.value)}
              /> */}
            </div>

            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "4px",
              }}
            >
              Code:&nbsp;{" "}
              <Input
                defaultValue={record?.code}
                onMouseLeave={(e) => handleChange(9, record.id, e.target.value)}
              />
            </div> */}
          </>
        );
      },
    },

    // {
    //   title: "Kiểu",
    //   dataIndex: "type",
    //   key: "type",
    //   width: "calc(35% - 46px)",
    //   render: (text, record, index) => {
    //     return (
    //       <>
    //         <Select
    //           style={{ width: "100%" }}
    //           value={record.type}
    //           onChange={(e) => handleChange(2, record.id, e)}
    //           options={[
    //             { value: "text", label: "Kiểu chuỗi" },
    //             { value: "number", label: "Kiểu số" },
    //             { value: "email", label: "Email" },
    //             { value: "phone", label: "Số điện thoại" },
    //             { value: "select", label: "Chọn" },
    //           ]}
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      title: "Bắt buộc",
      dataIndex: "required",
      key: "required",
      width: "15%",
      align: "center",
      render: (text, record, index) => {
        return (
          <Checkbox
            onChange={(e) => handleChange(3, record.code, e.target.checked)}
            className="checkbox-size"
            style={{ marginRight: "4px" }}
            checked={record.required}
          />
        );
      },
    },
    {
      title: "Hiển thị",
      dataIndex: "status",
      key: "status",
      width: "15%",
      align: "center",
      render: (text, record, index) => {
        return (
          <Checkbox
            onChange={(e) => handleChange(4, record.code, e.target.checked)}
            className="checkbox-size"
            style={{ marginRight: "4px" }}
            checked={record.status === "ACTIVE" ? true : false}
          />
        );
      },
    },
    // {
    //   title: "Thao tác",
    //   dataIndex: "status",
    //   key: "status",
    //   width: "10%",
    //   align: "center",
    //   render: (text, record, index) => {
    //     return (
    //       <div
    //         style={{
    //           width: "32px",
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //         onClick={() => handleChange(8, record.id, null)}
    //       >
    //         <RiDeleteBin2Fill
    //           style={{ cursor: "pointer" }}
    //           color="red"
    //           size={22}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];
};
