import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { MdTableRows } from "react-icons/md";
import { IconName } from "react-icons/vsc";
import brandImg1 from "../../assets/images/brand1.jpg";
import userImg from "../../assets/images/userSvg.png";
import { PiUserCircleLight } from "react-icons/pi";
import {
  newValidate,
  validateEmail,
} from "../../utils/helpers/functions/textUtils.js";
import SvgExness from "../../assets/images/SvgExness";
import { Button, Form, Input, Modal, Popover, Tooltip } from "antd";
import {
  localGetMenu,
  localRemoveMenu,
  localGetRefreshToken,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../utils/localStorage";
import { UserContext } from "../../context/context";
import { api } from "../../services/api/api.index";
import { API_URL } from "../../services/api/config";
import axios from "axios";
import { notifyError, notifySuccess, notifyWarning } from "../notification";
import { BiSave } from "react-icons/bi";
import OverlaySpinner from "../overlaySpinner/OverlaySpinner";
import CustomLink from "./CustomLinks.jsx";
const Main = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [openModalEditInfo, setOpenModalEditInfo] = useState(false);
  const [openModalEditPassword, setOpenModalEditPassword] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formEditInfo] = Form.useForm();
  const [formEditPassword] = Form.useForm();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(UserContext);

  const getInfo = async () => {
    try {
      let { data } = await api.get(`${API_URL}api/v1/users/profile`, {});
      dispatch({
        type: "LOGIN",
        payload: {
          email: data?.email,
          name: data?.name,
          _id: data?._id,
          id: data?.id,
          menu: data?.menu,
          avatar: data?.avatar,
          role: data?.role_info?.name,
          phone: data?.phone,
        },
      });
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getInfo();
        } catch (e) {
          navigate("/login");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishFormEditInfo = async (value) => {
    try {
      let { data } = await api.put(`${API_URL}api/v1/users/profile`, {
        name: value?.name,
        email: value?.email,
        phone: value?.phone,
      });

      dispatch({
        type: "CHANGE_INFO",
        payload: {
          email: value?.email,
          name: value?.name,
          phone: value?.phone,
        },
      });
      formEditInfo.resetFields();
      notifySuccess("Thay đổi thông tin thành công!");
      setOpenModalEditInfo(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormEdit(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishFormEditPassword = async (value) => {
    if (value?.newPassword !== value?.reNewPassword) {
      notifyWarning("Mật khẩu xác nhận không chính xác!");
      return;
    }
    try {
      let { data } = await api.put(
        `${API_URL}api/v1/users/profile/update-password`,
        {
          current_password: value?.currentPassword,
          new_password: value?.newPassword,
        }
      );
      formEditPassword.resetFields();
      notifySuccess("Đổi mật khẩu thành công!");
      setOpenModalEditPassword(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormEditPassword(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleEdit = (type) => {
    if (type === 1) {
      setOpenModalEditPassword(false);

      setOpenModalEditInfo(true);
      formEditInfo.setFieldsValue({
        name: state?.user?.name,
        email: state?.user?.email,

        phone: state?.user?.phone,
      });
    } else {
      setOpenModalEditInfo(false);
      setOpenModalEditPassword(true);
    }
  };
  return (
    <div className="mainPage">
      <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
      <Modal
        open={openModalEditInfo}
        title={"Thông tin cá nhân"}
        onCancel={(e) => {
          setOpenModalEditInfo(false);
        }}
        footer={null}
        centered
      >
        <Form
          layout="vertical"
          form={formEditInfo}
          onFinish={handleFinishFormEditInfo}
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={state.user.avatar}
              width={80}
              height={80}
              className="rounded-full"
              alt="profile"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "20%" }}>Họ tên:</div>
            <Form.Item
              label=""
              name="name"
              className="userSystem__search__input"
              style={{ width: "80%", margin: "6px 0" }}
            >
              <Input placeholder="Nhập họ tên" />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "20%" }}>Số điện thoại:</div>
            <Form.Item
              label=""
              name="phone"
              className="userSystem__search__input"
              style={{ width: "80%", margin: "6px 0" }}
              rules={[
                { required: true, message: "Vui lòng không bỏ trống" },

                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      if (!value || newValidate(value.trim())) {
                        resolve();
                      } else {
                        reject("Vui lòng nhập đúng định dạng số điện thoại1");
                      }
                    });
                  },
                },
              ]}
            >
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "20%" }}>Email:</div>
            <Form.Item
              label=""
              name="email"
              className="userSystem__search__input"
              style={{ width: "80%", margin: "6px 0" }}
              rules={[
                { required: true, message: "Vui lòng không bỏ trống" },

                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      if (value && validateEmail(value.trim())) {
                        resolve();
                      } else {
                        reject("Vui lòng nhập đúng định dạng email");
                      }
                    });
                  },
                },
              ]}
            >
              <Input placeholder="Nhập email" />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "8px",
            }}
          >
            <Button
              className="userSystem__search__button"
              style={{
                background: "rgb(56, 114, 174)",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
              onClick={() => formEditInfo.submit()}
            >
              <BiSave size={20} />
              &nbsp; Cập nhật thông tin
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        open={openModalEditPassword}
        title={"Thay đổi mật khẩu"}
        onCancel={(e) => {
          formEditPassword.resetFields();
          setOpenModalEditPassword(false);
        }}
        footer={null}
        centered
      >
        <Form
          layout="vertical"
          form={formEditPassword}
          onFinish={handleFinishFormEditPassword}
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "20%" }}>Mật khẩu cũ:</div>
            <Form.Item
              label=""
              name="currentPassword"
              className="userSystem__search__input"
              style={{ width: "80%", margin: "6px 0" }}
            >
              <Input.Password placeholder="Nhập mật khẩu cũ" />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "20%" }}>Mật khẩu mới:</div>
            <Form.Item
              label=""
              name="newPassword"
              className="userSystem__search__input"
              style={{ width: "80%", margin: "6px 0" }}
            >
              <Input.Password placeholder="Nhập mật khẩu mới" />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "20%" }}>Nhập lại mật khẩu mới:</div>
            <Form.Item
              label=""
              name="reNewPassword"
              className="userSystem__search__input"
              style={{ width: "80%", margin: "6px 0" }}
            >
              <Input.Password placeholder="Nhập lại mật khẩu mới" />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "8px",
            }}
          >
            <Button
              className="userSystem__search__button"
              style={{
                background: "rgb(56, 114, 174)",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
              onClick={() => formEditPassword.submit()}
            >
              <BiSave size={20} />
              &nbsp; Đổi mật khẩu
            </Button>
          </div>
        </Form>
      </Modal>
      <div
        className="navbar"
        style={{
          background:
            process.env.REACT_APP_LINK_URL == 1
              ? "rgb(54, 113, 173)"
              : "rgb(34,177,76)",
        }}
      >
        <div className="openTab" onClick={() => setOpen(!open)}>
          <MdTableRows color="#fff" size={30} />
        </div>
        <div
          className="img"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        >
          <img src={brandImg1} alt="" />
        </div>

        <div
          className="profileTool"
          style={{
            position: "absolute",
            right: "20px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <Popover
            placement="bottom"
            content={
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    padding: "2px 4px",
                  }}
                  className="hoverGray"
                  onClick={() => handleEdit(1)}
                >
                  Thông tin cá nhân
                </div>

                <div
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    padding: "2px 4px",
                  }}
                  className="hoverGray"
                  onClick={() => handleEdit(2)}
                >
                  Đổi mật khẩu
                </div>

                <div
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    padding: "2px 4px",
                  }}
                  className="hoverGray"
                  onClick={() => {
                    localRemoveToken();
                    localRemoveRefreshToken();
                    localRemoveMenu();
                    navigate("/login");
                  }}
                >
                  Đăng xuất
                </div>

                {/* <div
                style={{
                  width: "100%",
                  cursor: "pointer",
                  padding: "2px 4px",
                }}
                className="hoverGray"
                onClick={() => handleSync(record.id)}
              >
                Đồng bộ
              </div> */}
              </div>
            }
            trigger="click"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#fff",
                fontSize: "14px",
              }}
            >
              <img
                src={state.user.avatar}
                width={37}
                height={37}
                className="rounded-full"
                alt="profile"
              />
              <div
                style={{ marginLeft: "8px" }}
                className="displayNoneInMobile"
              >
                <div style={{ fontSize: "14px" }}>{state.user.name}</div>
                <div
                  style={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    marginTop: "4px",
                  }}
                >
                  {state.user.role}
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div
        onClick={() => setOpen(false)}
        className={`navLayer ${open ? "navLayer--open" : "navLayer--close"}`}
      ></div>
      <div className={`slider ${open ? "slider--open" : "slider--close"}`}>
        <div className="menu">
          {state.menu &&
            state.menu.length > 0 &&
            state.menu.map(
              (x, index) =>
                x?.display_status && (
                  <div key={index}>
                    <div className="tab-admin">{x.name}</div>
                    {x.children &&
                      x.children.map(
                        (y, key) =>
                          y?.display_status && (
                            <CustomLink
                              key={key}
                              className={
                                "/" + location.pathname.split("/")[1] ===
                                y?.fe_route
                                  ? "tab--active"
                                  : "tab"
                              }
                              to={y.fe_route}
                            >
                              {y?.name}
                            </CustomLink>
                          )
                      )}
                  </div>
                )
            )}

          {/* <div
            className={
              location.pathname.split("/")[1] === "user-system"
                ? "tab--active"
                : "tab"
            }
            onClick={() =>
              location.pathname.split("/")[1] !== "user-system" &&
              navigate("/user-system")
            }
          >
            Người dùng hệ thống
          </div>
          <div
            className={
              location.pathname.split("/")[1] === "forms"
                ? "tab--active"
                : "tab"
            }
            onClick={() =>
              location.pathname.split("/")[1] !== "forms" && navigate("/forms")
            }
          >
            Quản lí biểu mẫu
          </div>
          <div
            className={
              location.pathname.split("/")[1] === "groups"
                ? "tab--active"
                : "tab"
            }
            onClick={() =>
              (location.pathname.split("/")[1] !== "groups" ||
                (location.pathname.split("/")[1] === "groups" &&
                  location.pathname.split("/")[2])) &&
              navigate("/groups")
            }
          >
            Quản lí nhóm
          </div>
          <div className="tab-admin">SALE</div>
          <div
            className={
              location.pathname.split("/")[1] === "clients"
                ? "tab--active"
                : "tab"
            }
            onClick={() =>
              (location.pathname.split("/")[1] !== "clients" ||
                (location.pathname.split("/")[1] === "clients" &&
                  location.pathname.split("/")[2])) &&
              navigate("/clients")
            }
          >
            Quản lí khách hàng
          </div>
          <div
            className={
              location.pathname.split("/")[1] === "form-answers"
                ? "tab--active"
                : "tab"
            }
            onClick={() =>
              (location.pathname.split("/")[1] !== "form-answers" ||
                (location.pathname.split("/")[1] === "form-answers" &&
                  location.pathname.split("/")[2])) &&
              navigate("/form-answers")
            }
          >
            Quản lí câu trả lời biểu mẫu
          </div>
          <div
            className={
              location.pathname.split("/")[1] === "check-email"
                ? "tab--active"
                : "tab"
            }
            onClick={() =>
              location.pathname.split("/")[1] !== "check-email" &&
              navigate("/check-email")
            }
          >
            Kiểm tra phân bổ khách hàng
          </div>
          <div
            className={
              location.pathname.split("/")[1] === "check-account"
                ? "tab--active"
                : "tab"
            }
            onClick={() =>
              location.pathname.split("/")[1] !== "check-account" &&
              navigate("/check-account")
            }
          >
            Kiểm tra tài khoản giao dịch
          </div>
          <div
            className={
              location.pathname.split("/")[1] === "check-rebate"
                ? "tab--active"
                : "tab"
            }
            onClick={() =>
              location.pathname.split("/")[1] !== "check-rebate" &&
              navigate("/check-rebate")
            }
          >
            Kiểm tra % chiết khấu hoa hồng
          </div> */}
        </div>
      </div>
      <div
        className={`children-page ${
          open ? "children-page--open" : "children-page--close"
        }`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
