/* eslint-disable */

import { Popover, Switch } from "antd";
import moment from "moment";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  BiSolidError,
  BiSolidErrorCircle,
  BiSolidDownArrow,
  BiSolidUpArrow,
} from "react-icons/bi";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";

export const columnsData = ({}) => {
  return [
    {
      title: "Nhân viên chăm sóc",
      dataIndex: "name",
      key: "name",
      width: "40%",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              //   cursor: "pointer",
            }}
            // onClick={() => navigate(`/clients/${record.id}`)}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Mới",
      dataIndex: "form_answer_new",
      key: "form_answer_new",
      width: "15%",

      render: (text, record, index) => {
        return (
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}
          </div>
        );
      },
      sorter: (a, b) => a.form_answer_new - b.form_answer_new,
    },
    {
      title: "Đang xử lý",
      dataIndex: "form_answer_processing",
      key: "form_answer_processing",
      width: "15%",

      render: (text, record, index) => {
        return (
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}
          </div>
        );
      },
      sorter: (a, b) => a.form_answer_processing - b.form_answer_processing,
    },
    {
      title: "Hoàn thành",
      dataIndex: "form_answer_complete",
      key: "form_answer_complete",
      width: "15%",

      render: (text, record, index) => {
        return (
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}
          </div>
        );
      },
      sorter: (a, b) => a.form_answer_complete - b.form_answer_complete,
    },
    {
      title: "Tổng cộng",
      dataIndex: "form_answer_total",
      key: "form_answer_total",
      width: "15%",

      render: (text, record, index) => {
        return (
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}
          </div>
        );
      },
      defaultSortOrder: "descend",
      sorter: (a, b) => a.form_answer_total - b.form_answer_total,
    },
  ];
};
