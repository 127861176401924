/* eslint-disable */

import { Switch } from "antd";
import moment from "moment";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiSolidError, BiSolidErrorCircle } from "react-icons/bi";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";

export const columnsData = ({ handleChangeStatus, handleOpenModal }) => {
  return [
    {
      title: "Tài khoản giao dịch",
      dataIndex: "client_account",
      key: "client_account",
      width: "18%",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal(record)}
          >
            {text}&nbsp;&nbsp;
          </div>
        );
      },
    },

    {
      title: <div>Khối lượng giao dịch (lots)</div>,
      dataIndex: "volumn_lots",
      key: "volumn_lots",
      width: "20%",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Lợi nhuận (USD)",
      dataIndex: "reward_usd",
      key: "reward_usd",
      width: "18%",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Lần giao dịch gần nhất",
      dataIndex: "last_trade_at",
      key: "last_trade_at",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {record?.last_trade_at
              ? moment(record?.last_trade_at).format("DD-MM-YYYY")
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Lần giao dịch gần nhất cách đây",
      dataIndex: "last_trade_duration_from_now",
      key: "last_trade_duration_from_now",
      render: (text, record, index) => {
        return Number(record.last_trade_duration_from_now) ||
          record.last_trade_duration_from_now === 0 ? (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {text}&nbsp;ngày
          </div>
        ) : (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            -
          </div>
        );
      },
    },
    // {
    //   title: "Vai trò",
    //   dataIndex: "role",
    //   key: "role",
    //   width: "15%",
    //   render: (text, record, index) => {
    //     return (
    //       <div
    //         style={{
    //           width: "80px",
    //           height: "32px",
    //           background: "rgb(56, 114, 174)",
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //           borderRadius: "16px",
    //           color: "#fff",
    //         }}
    //       >
    //         {text?.charAt(0) + text?.slice(1)?.toLowerCase()}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Trạng thái hoạt động",
    //   dataIndex: "status",
    //   key: "status ",
    //   width: "20%",
    //   render: (text, record, index) => {
    //     return (
    //       <Switch
    //         checked={text === "ACTIVE" ? true : false}
    //         onChange={(e) => handleChangeStatus(e, record)}
    //       />
    //     );
    //   },
    // },
  ];
};

export const columnsDataModal = ({ handleChangeStatus, handleOpenModal }) => {
  return [
    {
      title: "Ngày giao dịch",
      dataIndex: "date",
      key: "date",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {record?.date ? moment(record?.date).format("DD-MM-YYYY") : "-"}
          </div>
        );
      },
    },
    {
      title: "Khối lượng giao dịch (lots)",
      dataIndex: "volumn_lots",
      key: "volumn_lots",
      align: "right",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "Lợi nhuận (USD)",
      dataIndex: "reward_usd",
      key: "reward_usd",
      align: "right",
      render: (text, record, index) => {
        return (
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontWeight: "bold",
            }}
          >
            {text}
          </div>
        );
      },
    },
  ];
};
