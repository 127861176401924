import { useContext, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Main from "./components/layout/Main";
import { UserContext, UserContextProvider } from "./context/context";
import CheckCustomer from "./pages/checkCustomer/CheckCustomer";
import CheckDiscount from "./pages/checkDiscount/CheckDiscount";
import CheckTradeID from "./pages/checkTradeID/CheckTradeID";
import CreateForm from "./pages/formManager/create/CreateForm";
import FormDetails from "./pages/formManager/details/DetailsForm";

import ListForm from "./pages/formManager/list/ListForm";
import SignIn from "./pages/login/SignIn";
import UserSystem from "./pages/userSystem/UserSystem";
import GroupManager from "./pages/groupManager/list/GroupManager";
import RebateManager from "./pages/rebateManager/list/RebateManager";

import Clients from "./pages/cilents/list/UserSystem";
import ClientDetails from "./pages/cilents/details/ClientDetails";
import FormAnswer from "./pages/formAnswer/list/FormAnswer";
import {
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "./utils/localStorage";
import { notifyError } from "./components/notification";
import { API_URL } from "./services/api/config";
import { api } from "./services/api/api.index";
import ListRoles from "./pages/roles/list/ListRoles";
import RoleDetails from "./pages/roles/details/RoleDetails";
import DashboardCilents from "./pages/dashboard/DashboardCilents";
import DashboardUsers from "./pages/dashboard/DashboardUsers";
import Partner from "./pages/partner/Partner";
import ActionHistories from "./pages/actionHistories/ActionHistories";
import Bang from "./pages/bang/Bang";
import EmptyPage from "./pages/empty/EmptyPage";

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/bang" element={<Bang />} />
        <Route element={<Main />}>
          <Route path="/" index element={<div></div>} />
          <Route path="/roles" element={<ListRoles />} />
          <Route path="/roles/:id" element={<RoleDetails />} />
          <Route path="/report-clients" element={<DashboardCilents />} />
          <Route path="refresh" element={<EmptyPage />} />
          <Route path="/report-users" element={<DashboardUsers />} />
          <Route path="/system-users" element={<UserSystem />} />
          <Route path="/form-create" element={<CreateForm />} />
          <Route path="/forms" element={<ListForm />} />
          <Route path="/groups" element={<GroupManager />} />
          <Route path="/rebate-level" element={<RebateManager />} />
          <Route path="/action-histories" element={<ActionHistories />} />

          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/:id" element={<ClientDetails />} />
          <Route path="/form-answer" element={<FormAnswer />} />

          <Route path="/form-details/:id" element={<FormDetails />} />
          <Route path="/partner-affiliation" element={<Partner />} />
        </Route>
      </Routes>
    </UserContextProvider>
  );
}

export default App;
