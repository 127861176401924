import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../services/api/config";
import { api } from "../../services/api/api.index.js";
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiSolidError } from "react-icons/bi";
import { notifyError, notifySuccess } from "../../components/notification";
import OverlaySpinner from "../../components/overlaySpinner/OverlaySpinner";
import { validateEmail } from "../../utils/helpers/functions/textUtils";
import {
  localGetMenu,
  localRemoveMenu,
  localGetRefreshToken,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../utils/localStorage";
import axios from "axios";
const CheckCustomer = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState(1);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    affiliation: false,
    accounts: [],
    count: 0,
    checked: false,
  });
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();

  const handleFinishCheck = async (value) => {
    setLoading(true);
    try {
      let { data } = await api.post(
        `${API_URL}api/v1/partner-affiliation/check-email`,
        {
          email: value.login?.trim(),
        }
      );
      setData({
        affiliation: data?.affiliation,
        accounts: data?.accounts,
        checked: true,
        count: data?.count_acount,
        email: value.login?.trim(),
      });

      form.setFieldsValue({
        email: value.login?.trim(),
        reward_usd: data?.trade_info?.reward_usd,
        volume_lots: data?.trade_info?.volume_lots,
        last_trade_at:
          data?.trade_info?.last_trade_at !== "Invalid date"
            ? data?.trade_info?.last_trade_at +
              " ( Tài khoản: " +
              data?.trade_info?.last_trade_client_account +
              " ) "
            : "Không có dữ liệu",
      });
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishCheck(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  return (
    <div className="checkCustomer new">
      <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
      <div className="checkCustomer__content new">
        {!data.checked ? (
          <div style={{ padding: "12px 24px" }}>
            <h4 className="checkCustomer__content__header new">
              Kiểm tra phân bổ khách hàng
              <div className="checkCustomer__content__subheader new">
                Tại đây, bạn có thể kiểm tra xem khách hàng có được phân bổ cho
                bạn theo đường liên kết đối tác của bạn hay không. Để xác minh
                khách hàng, vui lòng nhập địa chỉ email của họ và nhấn tiếp tục
              </div>
            </h4>
            <Form
              form={form}
              onFinish={handleFinishCheck}
              className="checkCustomer__content__form new"
            >
              <Form.Item
                className=""
                rules={[
                  { required: true, message: "Vui lòng không bỏ trống" },

                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        if (validateEmail(value.trim())) {
                          resolve();
                        } else {
                          reject("Vui lòng nhập đúng định dạng email");
                        }
                      });
                    },
                  },
                ]}
                name="login"
                style={{ width: "100%" }}
              >
                <Input placeholder="Nhập email khách hàng" />
              </Form.Item>

              <div
                className="checkCustomer__content__submit"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  loading={loading}
                  style={{ width: "100%" }}
                  onClick={() => form.submit()}
                >
                  Kiểm tra
                </Button>
              </div>
            </Form>
          </div>
        ) : data.affiliation ? (
          data.accounts.length > 0 ? (
            <div className="newStyle">
              <div className="subNewStyleLeft">
                <div
                  style={{
                    position: "relative",
                    transform: "translate(-10px,-10px)",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      background: "#fff",
                      width: "20px",
                      height: "20px",
                      top: "10px",
                      left: "10px",
                      zIndex: "0",
                    }}
                  />
                  <div style={{ position: "absolute", zIndex: "1" }}>
                    <AiFillCheckCircle color="rgb(45,245,51)" size={40} />
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      fontSize: "18px",
                      marginTop: "38px",
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Email:</span>&nbsp;
                    <span
                      style={{
                        fontStyle: "italic",
                        textDecorationLine: "underline",
                      }}
                    >
                      {data?.email}
                    </span>
                  </div>
                  <p
                    style={{
                      marginTop: "18px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Khách hàng này được phân bổ cho bạn, đây là danh sách tài
                    khoản của khách hàng:
                  </p>
                </div>
                <div
                  style={{
                    padding: "0 24px",
                    fontSize: "16px",
                    marginTop: "24px",
                    color: "rgb(255,159,70)",
                    maxHeight: "200px",
                    overflowY: data.count > 10 ? "scroll" : "hidden",
                  }}
                >
                  {data.accounts.map((x, index) => (
                    <span key={index}>
                      {index !== 0 && <span>, </span>}
                      {x}
                    </span>
                  ))}
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "16px",
                    marginTop: "24px",
                  }}
                >
                  Số lượng tài khoản: {data.count}
                </div>
                <div
                  style={{ width: "100%" }}
                  className="checkCustomer__content__submit"
                >
                  <Button
                    loading={loading}
                    style={{
                      width: "100%",
                      fontWeight: "700",
                    }}
                    onClick={() => {
                      setData({
                        affiliation: false,
                        accounts: [],
                        checked: false,
                        count: 0,
                      });
                      form.resetFields();
                    }}
                  >
                    Quay lại
                  </Button>
                </div>
              </div>
              <div className="subNewStyleRight">
                <h4
                  className="checkCustomer__content__header"
                  style={{ marginTop: "38px" }}
                >
                  Thông tin giao dịch
                </h4>
                <Form
                  style={{ marginTop: "19px" }}
                  form={form}
                  className="checkCustomer__content__form"
                >
                  <span style={{ fontSize: "14px", color: "#fff" }}>
                    Khối lượng giao dịch (lots)
                  </span>
                  <Form.Item
                    className=""
                    name="volume_lots"
                    style={{ width: "100%", margin: "4px 0 16px" }}
                  >
                    <Input
                      disabled
                      style={{ background: "#fff", color: "rgb(54,113,173)" }}
                    />
                  </Form.Item>{" "}
                  <span style={{ fontSize: "14px", color: "#fff" }}>
                    Lợi nhuận (USD)
                  </span>
                  <Form.Item
                    className=""
                    name="reward_usd"
                    style={{ width: "100%", margin: "4px 0 16px" }}
                  >
                    <Input
                      disabled
                      style={{ background: "#fff", color: "rgb(54,113,173)" }}
                    />
                  </Form.Item>{" "}
                  <span style={{ fontSize: "14px", color: "#fff" }}>
                    Lần giao dịch gần nhất
                  </span>
                  <Form.Item
                    className=""
                    name="last_trade_at"
                    style={{ width: "100%", margin: "4px 0 16px" }}
                  >
                    <Input
                      disabled
                      placeholder="Không có dữ liệu"
                      style={{ background: "#fff", color: "rgb(54,113,173)" }}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                padding: "12px 24px",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  transform: "translateX(-20px)",
                  top: "0",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    background: "#fff",
                    width: "20px",
                    height: "20px",
                    top: "10px",
                    left: "10px",
                    zIndex: "0",
                  }}
                />
                <div style={{ position: "absolute", zIndex: "1" }}>
                  <AiFillCheckCircle color="rgb(45,245,51)" size={40} />
                </div>
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginTop: "38px",
                  textAlign: "center",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Email:</span>&nbsp;
                <span
                  style={{
                    fontStyle: "italic",
                    textDecorationLine: "underline",
                  }}
                >
                  {data?.email}
                </span>
              </div>
              <p
                style={{
                  marginTop: "16px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Khách hàng này được phân bổ cho bạn, nhưng chưa tạo tài khoản.
              </p>

              <div
                style={{
                  padding: "0 24px",
                  fontSize: "16px",
                  marginTop: "24px",
                  color: "rgb(255,159,70)",
                }}
              >
                Khách hàng phải có tài khoản thì bạn mới được nhận thưởng.
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  marginTop: "24px",
                }}
              >
                Số lượng tài khoản: {data.count}
              </div>
              <div
                style={{ width: "100%" }}
                className="checkCustomer__content__submit"
              >
                <Button
                  loading={loading}
                  style={{
                    width: "100%",
                    fontWeight: "700",
                  }}
                  onClick={() => {
                    setData({
                      affiliation: false,
                      accounts: [],
                      checked: false,
                      count: 0,
                    });
                    form.resetFields();
                  }}
                >
                  Quay lại
                </Button>
              </div>
            </div>
          )
        ) : data.accounts.length > 0 ? (
          <div className="newStyleFail">
            <div className="subNewStyleLeft">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  transform: "translateY(10px)",
                }}
              >
                <div
                  style={{
                    background: "#fff",
                    width: "10px",
                    height: "20px",
                    top: "15px",
                    left: "30px",
                    zIndex: "0",
                  }}
                />
                <div style={{ position: "absolute", zIndex: "1" }}>
                  <BiSolidError color="red" size={40} />
                </div>
              </div>
              <div
                style={{
                  fontSize: "18px",
                  marginTop: "30px",
                  textAlign: "center",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Email:</span>&nbsp;
                <span
                  style={{
                    fontStyle: "italic",
                    textDecorationLine: "underline",
                  }}
                >
                  {data?.email}
                </span>
              </div>
              <p
                style={{
                  marginTop: "16px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Khách hàng này không được phân bổ cho bạn, đây là danh sách tài
                khoản của khách hàng:
              </p>
              <p
                style={{
                  marginTop: "24px",
                  fontSize: "16px",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Tuy nhiên bạn vẫn sẽ nhận được tiền thưởng tử các tài khoản sau:
              </p>
              <div
                style={{
                  padding: "0 24px",
                  fontSize: "16px",
                  marginTop: "24px",
                  color: "rgb(255,159,70)",
                  maxHeight: "200px",
                  overflowY: data.count > 10 ? "scroll" : "hidden",
                }}
              >
                {data.accounts.map((x, index) => (
                  <>
                    <span key={index}>
                      {index !== 0 && <span>, </span>}
                      {x}
                    </span>
                  </>
                ))}
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  marginTop: "24px",
                }}
              >
                Số lượng tài khoản: {data.count}
              </div>
              <div
                style={{ width: "100%" }}
                className="checkCustomer__content__submit"
              >
                <Button
                  loading={loading}
                  style={{
                    width: "100%",
                    fontWeight: "700",
                  }}
                  onClick={() => {
                    setData({
                      affiliation: false,
                      accounts: [],
                      checked: false,
                      count: 0,
                    });
                    form.resetFields();
                  }}
                >
                  Quay lại
                </Button>
              </div>
            </div>
            <div className="subNewStyleRight">
              <h4
                className="checkCustomer__content__header"
                style={{ marginTop: "49px" }}
              >
                Thông tin giao dịch
              </h4>
              <Form
                style={{ marginTop: "18px" }}
                form={form}
                className="checkCustomer__content__form"
              >
                <span style={{ fontSize: "14px", color: "#fff" }}>
                  Khối lượng giao dịch (lots)
                </span>
                <Form.Item
                  className=""
                  name="volume_lots"
                  style={{ width: "100%", marginTop: "4px" }}
                >
                  <Input
                    disabled
                    style={{ background: "#fff", color: "rgb(54,113,173)" }}
                  />
                </Form.Item>{" "}
                <span style={{ fontSize: "14px", color: "#fff" }}>
                  Lợi nhuận (USD)
                </span>
                <Form.Item
                  className=""
                  name="reward_usd"
                  style={{ width: "100%", marginTop: "4px" }}
                >
                  <Input
                    disabled
                    style={{ background: "#fff", color: "rgb(54,113,173)" }}
                  />
                </Form.Item>{" "}
                <span style={{ fontSize: "14px", color: "#fff" }}>
                  Lần giao dịch gần nhất
                </span>
                <Form.Item
                  className=""
                  name="last_trade_at"
                  style={{ width: "100%", marginTop: "4px" }}
                >
                  <Input
                    disabled
                    placeholder="Không có dữ liệu"
                    style={{ background: "#fff", color: "rgb(54,113,173)" }}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              padding: "0 24px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  background: "#fff",
                  width: "10px",
                  height: "20px",
                  top: "10px",
                  left: "12px",
                  zIndex: "0",
                }}
              />
              <div style={{ position: "absolute", zIndex: "1" }}>
                <BiSolidError color="red" size={40} />
              </div>
            </div>
            <div
              style={{
                fontSize: "18px",
                marginTop: "16px",
                textAlign: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Email:</span>&nbsp;
              <span
                style={{
                  fontStyle: "italic",
                  textDecorationLine: "underline",
                }}
              >
                {data?.email}
              </span>
            </div>
            <div
              style={{
                marginTop: "16px",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Xin lỗi, tài khoản giao dịch này không thuộc DNI.
            </div>
            <div
              style={{ width: "100%" }}
              className="checkCustomer__content__submit"
            >
              <Button
                loading={loading}
                style={{
                  width: "100%",
                  fontWeight: "700",
                }}
                onClick={() => {
                  setData({
                    affiliation: false,
                    accounts: [],
                    checked: false,
                    count: 0,
                  });
                  form.resetFields();
                }}
              >
                Quay lại
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckCustomer;
