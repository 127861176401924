import { DatePicker, Button, Form, Input, Select, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { columnsData } from "./data";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { AiOutlineReload, AiOutlineSearch } from "react-icons/ai";
import { LuFilePlus2 } from "react-icons/lu";
import moment from "moment";
import { FiPlusCircle } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import OverlaySpinner from "../../../components/overlaySpinner/OverlaySpinner";
import {
  localGetMenu,
  localGetRefreshToken,
  localSaveToken,
  localSaveRefreshToken,
  localRemoveRefreshToken,
  localRemoveToken,
  localRemoveMenu,
} from "../../../utils/localStorage";
import axios from "axios";
import { UserContext } from "../../../context/context";
import PanigationAntStyled from "../../../components/layout/PanigationAntStyled";
const { RangePicker } = DatePicker;
const ListForm = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const [form] = Form.useForm();
  let navigate = useNavigate();
  const [filter, setFilter] = useState({
    q: undefined,
    status: undefined,
    role: undefined,
    from_date: undefined,
    to_date: undefined,
    page: 1,
    limit: 10,
  });
  const onChangePaging = (page, pageSize) => {
    setFilter({
      ...filter,

      page: page,
      limit: pageSize,
    });
  };

  const getData = async () => {
    setLoading(true);

    try {
      let data = await api.get(`${API_URL}api/v1/forms`, {
        ...filter,
      });
      setDataSource(data?.data);
      setTotal(data?.metadata?.totalItems);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishForm = (e) => {
    setFilter({
      ...filter,
      page: 1,
      q: e?.q?.length > 0 ? e?.q?.trim() : undefined,
      status: e?.status,
      role: e?.role,
      from_date: e?.date ? moment(e.date[0]).format("YYYY-MM-DD") : undefined,
      to_date: e?.date ? moment(e.date[1]).format("YYYY-MM-DD") : undefined,
    });
  };

  const handleChangeStatus = async (e, record) => {
    setLoading(true);
    try {
      let { data } = await api.put(
        `${API_URL}api/v1/forms/${record.id}/update-status`,
        {
          status: e ? "ACTIVE" : "INACTIVE",
        }
      );
      let newList = [...dataSource].map((x) =>
        x.id === record.id ? { ...x, status: e ? "ACTIVE" : "INACTIVE" } : x
      );
      setDataSource(newList);
      setLoading(false);

      notifySuccess("Đổi trạng thái thành công!");
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleChangeStatus();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const { state, dispatch } = useContext(UserContext);

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/forms");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);

  useEffect(() => {
    pageMenu.list && getData();
  }, [pageMenu, filter]);

  return (
    pageMenu.list && (
      <div className="formCreate">
        <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
        <Form
          className="formCreate__search"
          layout="vertical"
          form={form}
          onFinish={handleFinishForm}
        >
          <Form.Item
            label="Tìm kiếm"
            name="q"
            className="formCreate__search__input"
            style={{
              width: pageMenu.create
                ? "calc(100% - 878px)"
                : "calc(100% - 750px)",
            }}
          >
            <Input placeholder="Nhập tên biểu mẫu" />
          </Form.Item>

          <Form.Item
            label="Trạng thái"
            name="status"
            className="formCreate__search__select"
          >
            <Select
              allowClear
              options={[
                { value: "ACTIVE", label: "Hoạt động" },
                { value: "INACTIVE", label: "Dừng hoạt động" },
              ]}
              placeholder="Tất cả"
            />
          </Form.Item>

          <Form.Item
            label="Ngày tạo"
            name="date"
            className="formCreate__search__date"
          >
            <RangePicker />
          </Form.Item>

          <Button
            className="formCreate__search__button"
            onClick={() => form.submit()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AiOutlineSearch size={20} />
            &nbsp;Tìm kiếm
          </Button>
          <Button
            className="clients__search__button"
            onClick={() => {
              form.resetFields();
              setFilter({
                ...filter,
                q: undefined,
                status: undefined,
                role: undefined,
                from_date: undefined,
                to_date: undefined,
                page: 1,
                limit: 10,
              });
            }}
          >
            <AiOutlineReload size={20} />
            &nbsp;Refresh
          </Button>
          {pageMenu.create && (
            <Button
              className="formCreate__search__button"
              onClick={() => navigate("/form-create")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FiPlusCircle size={20} />
              &nbsp;Tạo mới
            </Button>
          )}
        </Form>
        <Table
          bordered
          className="newpadding"
          rowKey="_id"
          columns={columnsData({ handleChangeStatus, navigate }, pageMenu)}
          dataSource={dataSource?.slice(
            (filter?.page - 1) * filter?.limit,
            filter?.page * filter?.limit
          )}
          pagination={false}
        />
        <PanigationAntStyled
          style={{ padding: "0 0 24px 0" }}
          current={filter.page}
          pageSize={filter.limit}
          showSizeChanger
          onChange={onChangePaging}
          showTotal={() => `Tổng ${total}`}
          total={total}
        />
      </div>
    )
  );
};

export default ListForm;
