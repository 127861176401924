import {
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Table,
  Modal,
  Switch,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { columnsData } from "./data";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../components/notification";
import { api } from "../../../services/api/api.index";
import { API_URL } from "../../../services/api/config";
import { AiOutlineReload, AiOutlineSearch } from "react-icons/ai";
import { FiPlusCircle } from "react-icons/fi";
import { BiSave } from "react-icons/bi";
import moment from "moment";
import {
  localRemoveMenu,
  localGetMenu,
  localGetRefreshToken,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../../utils/localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import OverlaySpinner from "../../../components/overlaySpinner/OverlaySpinner";
import axios from "axios";
import { UserContext } from "../../../context/context";
const { RangePicker } = DatePicker;
const ListRoles = () => {
  const [dataSource, setDataSource] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [form] = Form.useForm();
  const [status, setStatus] = useState(false);
  const [selectedRole, setSelectedRole] = useState(undefined);
  const [dataMenu, setDataMenu] = useState([]);
  const [formCreate] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    q: undefined,
    status: undefined,
    inherited_global_role: undefined,
    from_date: undefined,
    to_date: undefined,
  });
  const { state, dispatch } = useContext(UserContext);
  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });

  const getData = async () => {
    setLoading(true);

    try {
      let { data } = await api.get(`${API_URL}api/v1/roles`, {
        ...filter,
      });
      setDataSource(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const getRoles = async () => {
    try {
      let { data } = await api.get(`${API_URL}api/v1/roles/global-system`);
      setRoles(
        data.map((x) => {
          return {
            value: x?.key,
            label: x?.name,
          };
        })
      );
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getRoles();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishForm = (e) => {
    setFilter({
      q: e?.q?.length > 0 ? e?.q?.trim() : undefined,
      status: e?.status,
      inherited_global_role: e?.inherited_global_role,
      from_date: e?.date ? moment(e.date[0]).format("YYYY-MM-DD") : undefined,
      to_date: e?.date ? moment(e.date[1]).format("YYYY-MM-DD") : undefined,
    });
  };

  const handleFinishFormCreate = async (value) => {
    try {
      let { data } = await api.post(`${API_URL}api/v1/roles`, {
        name: value?.name,
        code: value?.code,
        description: value?.description,
        inherited_global_role: selectedRole,
        status: status ? "ACTIVE" : "INACTIVE",
        menu: dataMenu,
      });
      setOpenModal(false);
      formCreate.resetFields();
      notifySuccess("Tạo vai trò mới thành công!");
      getData();
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishFormCreate(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getMenu = async () => {
    try {
      let { data } = await api.get(`${API_URL}api/v1/menu-functions`, {
        inherited_global_roles: selectedRole,
      });
      setDataMenu(data);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getMenu();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleChangeStatus = async (value, record) => {
    setLoading(true);
    try {
      let { data } = await api.put(`${API_URL}api/v1/roles/${record?.id}`, {
        status: value ? "ACTIVE" : "INACTIVE",
      });

      notifySuccess("Đổi trạng thái thành công!");
      setLoading(false);
      getData();
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleChangeStatus(value, record);
        } catch (error) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  const handleKeyUp = (event) => {
    if (event.keyCode === 13 || event.key == "Enter") {
      form.submit();
    }
  };

  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/roles");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);
  useEffect(() => {
    pageMenu.list && getData();
  }, [filter, pageMenu.list]);
  useEffect(() => {
    pageMenu.list && getRoles();
  }, [pageMenu.list]);

  useEffect(() => {
    if (selectedRole) {
      getMenu();
    }
  }, [selectedRole]);

  return (
    pageMenu.list && (
      <div
        className="userSystem"
        onKeyDown={handleKeyUp}
        tabIndex="0"
        style={{ outline: "none" }}
      >
        <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
        <Modal
          open={openModal}
          onCancel={(e) => {
            setOpenModal(false);
            formCreate.resetFields();
          }}
          footer={null}
          centered
        >
          <div
            style={{
              color: "rgb(56, 114, 174)",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Tạo mới vai trò người dùng
          </div>
          <Form
            layout="vertical"
            form={formCreate}
            onFinish={handleFinishFormCreate}
            style={{ marginTop: "16px" }}
            initialValues={{ status: true }}
          >
            <Form.Item
              label="Tên vai trò"
              name="name"
              placeholder="Nhập tên vai trò"
              style={{ width: "100%" }}
              required
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mã vai trò"
              name="code"
              placeholder="Nhập mã vai trò"
              style={{ width: "100%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Mô tả"
              name="description"
              placeholder="Nhập mô tả"
              style={{ width: "100%" }}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Chức danh" name="role" style={{ width: "100%" }}>
              <Select
                allowClear
                options={roles}
                placeholder="Tất cả"
                onChange={(e) => setSelectedRole(e)}
              />
            </Form.Item>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Form.Item name="status" style={{ width: "100%" }}>
                <Switch checked={status} onChange={(e) => setStatus(e)} />
                &nbsp;Trạng thái hoạt động
              </Form.Item>

              <Button
                className="userSystem__search__button"
                style={{
                  marginTop: "-24px",
                  background: "rgb(56, 114, 174)",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => formCreate.submit()}
              >
                <BiSave size={20} />
                &nbsp; Tạo mới
              </Button>
            </div>
          </Form>
        </Modal>
        <Form
          className="userSystem__search"
          layout="vertical"
          form={form}
          onFinish={handleFinishForm}
        >
          <Form.Item
            label="Tìm kiếm"
            name="q"
            className="userSystem__search__input"
            style={{
              width: pageMenu.create
                ? "calc(100% - 880px)"
                : "calc(100% - 752px)",
            }}
          >
            <Input placeholder="Nhập họ tên, email" />
          </Form.Item>
          <Form.Item
            label="Chức danh"
            name="inherited_global_role"
            className="userSystem__search__select"
            style={{ width: "240px" }}
          >
            <Select allowClear options={roles} placeholder="Tất cả" />
          </Form.Item>
          <Form.Item
            label="Trạng thái"
            name="status"
            className="userSystem__search__select"
            style={{ width: "240px" }}
          >
            <Select
              allowClear
              options={[
                { value: "ACTIVE", label: "Hoạt động" },
                { value: "INACTIVE", label: "Dừng hoạt động" },
              ]}
              placeholder="Tất cả"
            />
          </Form.Item>

          <Button
            className="userSystem__search__button"
            onClick={() => form.submit()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AiOutlineSearch size={20} />
            &nbsp;Tìm kiếm
          </Button>
          <Button
            className="clients__search__button"
            onClick={() => {
              form.resetFields();
              setFilter({
                ...filter,
                q: undefined,
                status: undefined,
                inherited_global_role: undefined,
                from_date: undefined,
                to_date: undefined,
              });
            }}
          >
            <AiOutlineReload size={20} />
            &nbsp;Refresh
          </Button>
          {pageMenu.create && (
            <Button
              className="userSystem__search__button"
              onClick={() => setOpenModal(true)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FiPlusCircle size={20} />
              &nbsp;Tạo mới
            </Button>
          )}
        </Form>
        <Table
          bordered
          className="newpadding"
          rowKey="_id"
          columns={columnsData(
            { navigate, handleChangeStatus },
            pageMenu,
            roles
          )}
          dataSource={dataSource}
          pagination={{
            pageSize: 20,
            showTotal: (total, range) => `Tổng ${total}`,
          }}
        />
      </div>
    )
  );
};

export default ListRoles;
