/* eslint-disable */

import { Switch } from "antd";
import moment from "moment";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";

export const columnsData = () => {
  return [
    {
      title: "Họ tên",
      dataIndex: "created_by_user_info",
      key: "created_by_user_info",
      width:180,
      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)", fontWeight: "bold" }}>
            {record?.created_by_user_info?.name}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "created_by_user_info",
      key: "created_by_user_info",
      render: (text, record, index) => {
        return (
          <div style={{ color: "rgb(56, 114, 174)", fontWeight: "bold" }}>
            {record?.created_by_user_info?.email}
          </div>
        );
      },
    },

    {
      title: "Thao tác",
      dataIndex: "description",
      key: "description",

      render: (text, record, index) => {
        return <span>{record?.description}</span>;
      },
    },
    {
      title: "Thời gian",
      dataIndex: "updated_at",
      key: "updated_at",
      width:150,
      render: (text, record, index) => {
        return (
          <span>
            {" "}
            {record?.updated_at
              ? moment(record?.updated_at).format("HH:mm:ss DD-MM-YYYY")
              : "-"}
          </span>
        );
      },
    },
  ];
};
