import * as React from "react";

const SvgExcel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#ECEFF1"
      d="M22.281 19.407H12.22a.719.719 0 0 1-.719-.719V4.313a.72.72 0 0 1 .719-.719h10.06a.72.72 0 0 1 .719.719v14.375a.719.719 0 0 1-.719.719z"
    />
    <path
      fill="#388E3C"
      d="M15.094 7.907h-2.875a.719.719 0 0 1 0-1.438h2.875a.719.719 0 0 1 0 1.438zm0 2.875h-2.875a.719.719 0 0 1 0-1.438h2.875a.719.719 0 0 1 0 1.438zm0 2.875h-2.875a.719.719 0 0 1 0-1.438h2.875a.719.719 0 0 1 0 1.438zm0 2.875h-2.875a.719.719 0 0 1 0-1.438h2.875a.719.719 0 0 1 0 1.438zm4.312-8.625H17.97a.719.719 0 0 1 0-1.438h1.437a.719.719 0 0 1 0 1.438zm0 2.875H17.97a.719.719 0 0 1 0-1.438h1.437a.719.719 0 0 1 0 1.438zm0 2.875H17.97a.719.719 0 0 1 0-1.438h1.437a.719.719 0 0 1 0 1.438zm0 2.875H17.97a.719.719 0 0 1 0-1.438h1.437a.719.719 0 0 1 0 1.438z"
    />
    <path
      fill="#2E7D32"
      d="M12.677.885a.706.706 0 0 0-.59-.153L.586 2.888A.717.717 0 0 0 0 3.594v15.813c0 .345.246.642.587.706l11.5 2.156a.716.716 0 0 0 .851-.706V1.438a.717.717 0 0 0-.26-.553z"
    />
    <path
      fill="#FAFAFA"
      d="m9.884 13.903-2.272-2.598L9.91 8.35a.72.72 0 0 0-1.134-.885l-2.129 2.738-1.794-2.05a.717.717 0 1 0-1.081.946l1.977 2.26-2.004 2.575a.72.72 0 1 0 1.135.883l1.833-2.358 2.09 2.388a.713.713 0 0 0 1.014.069.717.717 0 0 0 .067-1.013z"
    />
  </svg>
);

export default SvgExcel;
