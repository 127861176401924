import React, { Dispatch, createContext, useReducer } from "react";
import { notifySuccess } from "../components/notification";
import { localGetAccount, localSaveMenu } from "../utils/localStorage";
// const initialState = {
//   cart: Cookies.get("user")
//     ? JSON.parse(Cookies.get("user"))
//     : { username: "", email: "" },
// };

const initialState = {
  user: { username: "", email: "", phone: "", menu: [], newMenu: [] },
};
function reducer(state, action) {
  switch (action.type) {
    case "LOGIN": {
      let fakeMenu = [];
      for (let i = 0; i < action?.payload?.menu?.length; i++) {
        for (let j = 0; j < action?.payload?.menu[i]?.children?.length; j++) {
          fakeMenu.push(action?.payload?.menu[i]?.children[j]);
        }
      }
      localSaveMenu(fakeMenu);
      return {
        ...state,
        user: {
          email: action?.payload?.email,
          name: action?.payload?.name,
          id: action?.payload?.id,
          avatar: action?.payload?.avatar,
          role: action?.payload?.role,
          phone: action?.payload?.phone,
          _id: action?.payload?._id,
        },
        menu: action?.payload?.menu,
        newMenu: fakeMenu,
      };
    }

    case "CHANGE_INFO": {
      return {
        ...state,
        user: {
          ...state.user,
          email: action?.payload?.email,
          name: action?.payload?.name,

          phone: action?.payload?.phone,
        },
      };
    }
    default:
      return state;
  }
}
export const UserContext = createContext({
  state: initialState,
  dispatch: (type, product) => null,
});

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
