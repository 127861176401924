import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../services/api/config";
import { api } from "../../services/api/api.index.js";
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiSolidError } from "react-icons/bi";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../components/notification";
import OverlaySpinner from "../../components/overlaySpinner/OverlaySpinner";
import {
  localGetRefreshToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../utils/localStorage";
import axios from "axios";
const CheckTradeID = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState(1);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState({
    client_account: null,
    volume_lots: 0,
    reward_usd: 0,
    last_trade_at: null,
    reg_date: null,
    checked: false,
  });
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();

  const handleFinishCheck = async (value) => {
    setLoading(true);
    try {
      let { data } = await api.post(
        `${API_URL}api/v1/partner-affiliation/check-account`,
        {
          account: value.login,
        }
      );
      setDataUser({
        client_account: data?.client_account,
        volume_lots: data?.volume_lots,
        reward_usd: data?.reward_usd,
        last_trade_at:
          data?.last_trade_at !== "Invalid date"
            ? data?.last_trade_at
            : "Không có dữ liệu",
        reg_date: data?.reg_date ? data?.reg_date : "Không có dữ liệu",
        checked: true,
      });
      form.setFieldsValue({
        client_account: data?.client_account,
        volume_lots: data?.volume_lots,
        reward_usd: data?.reward_usd,
        last_trade_at:
          data?.last_trade_at !== "Invalid date"
            ? data?.last_trade_at
            : "Không có dữ liệu",
        reg_date: data?.reg_date ? data?.reg_date : "Không có dữ liệu",
      });
      setLoading(false);
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          handleFinishCheck(value);
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };
  return (
    <div className="checkCustomer">
      <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
      <div className="checkCustomer__content">
        {!dataUser.checked ? (
          <>
            <h4 className="checkCustomer__content__header">
              Kiểm tra tài khoản giao dịch
            </h4>
            <Form
              form={form}
              onFinish={handleFinishCheck}
              className="checkCustomer__content__form"
            >
              <Form.Item
                className=""
                rules={[
                  { required: true, message: "Vui lòng không bỏ trống" },
                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        if (Number(value)) {
                          resolve();
                        } else {
                          reject("Vui lòng chỉ nhập ký tự số");
                        }
                      });
                    },
                  },
                ]}
                name="login"
                style={{ width: "100%" }}
              >
                <Input placeholder="Nhập id tài khoản giao dịch" />
              </Form.Item>

              <div
                className="checkCustomer__content__submit"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  loading={loading}
                  style={{ width: "100%" }}
                  onClick={() => form.submit()}
                >
                  Kiểm tra
                </Button>
              </div>
            </Form>
          </>
        ) : dataUser.client_account ? (
          <div
            style={{
              padding: "0 24px",
            }}
          >
            <h4 className="checkCustomer__content__header">
              Tài khoản giao dịch:{" "}
              <span
                style={{
                  fontStyle: "italic",
                  textDecoration: "underline",
                }}
              >
                {dataUser?.client_account}
              </span>
            </h4>
            <Form
              style={{ marginTop: "16px" }}
              form={form}
              className="checkCustomer__content__form"
            >
              <span style={{ fontSize: "14px", color: "#fff" }}>
                Ngày tạo tài khoản:
              </span>
              <Form.Item
                className=""
                name="reg_date"
                style={{ width: "100%", marginTop: "4px" }}
              >
                <Input
                  disabled
                  placeholder="Không có dữ liệu"
                  style={{ background: "#fff", color: "rgb(54,113,173)" }}
                />
              </Form.Item>
              <span style={{ fontSize: "14px", color: "#fff" }}>
                Khối lượng giao dịch (lots)
              </span>
              <Form.Item
                className=""
                name="volume_lots"
                style={{ width: "100%", marginTop: "4px" }}
              >
                <Input
                  disabled
                  style={{ background: "#fff", color: "rgb(54,113,173)" }}
                />
              </Form.Item>{" "}
              <span style={{ fontSize: "14px", color: "#fff" }}>
                Lợi nhuận (USD)
              </span>
              <Form.Item
                className=""
                name="reward_usd"
                style={{ width: "100%", marginTop: "4px" }}
              >
                <Input
                  disabled
                  style={{ background: "#fff", color: "rgb(54,113,173)" }}
                />
              </Form.Item>{" "}
              <span style={{ fontSize: "14px", color: "#fff" }}>
                Lần giao dịch gần nhất
              </span>
              <Form.Item
                className=""
                name="last_trade_at"
                style={{ width: "100%", marginTop: "4px" }}
              >
                <Input
                  disabled
                  placeholder="Không có dữ liệu"
                  style={{ background: "#fff", color: "rgb(54,113,173)" }}
                />
              </Form.Item>
              <div
                className="checkCustomer__content__submit"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  style={{ width: "100%" }}
                  onClick={() => {
                    setDataUser({
                      affiliation: false,
                      accounts: [],
                      checked: false,
                    });
                    form.setFieldValue("login", null);
                  }}
                >
                  Quay lại
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              padding: "0 24px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  background: "#fff",
                  width: "10px",
                  height: "30px",
                  top: "15px",
                  left: "25px",
                  zIndex: "0",
                }}
              />
              <div style={{ position: "absolute", zIndex: "1" }}>
                <BiSolidError color="red" size={60} />
              </div>
            </div>
            <div
              style={{
                marginTop: "24px",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Xin lỗi, tài khoản giao dịch này không thuộc DNI.
            </div>
            <div
              style={{ width: "100%" }}
              className="checkCustomer__content__submit"
            >
              <Button
                loading={loading}
                style={{
                  marginTop: "24px",
                  width: "100%",
                  fontWeight: "700",
                }}
                onClick={() => {
                  setDataUser({
                    client_account: null,
                    volume_lots: 0,
                    reward_usd: 0,
                    last_trade_at: null,
                    checked: false,
                  });
                  form.setFieldValue("login", null);
                }}
              >
                Quay lại
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckTradeID;
