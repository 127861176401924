import {
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Table,
  Modal,
  Switch,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { columnsData } from "./data";
import { notifyError, notifySuccess } from "../../components/notification";
import { api } from "../../services/api/api.index";
import { API_URL } from "../../services/api/config";
import { AiOutlineReload, AiOutlineSearch } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import moment from "moment";
import {
  localGetMenu,
  localGetRefreshToken,
  localRemoveMenu,
  localRemoveRefreshToken,
  localRemoveToken,
  localSaveRefreshToken,
  localSaveToken,
} from "../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import OverlaySpinner from "../../components/overlaySpinner/OverlaySpinner";
import axios from "axios";
import { UserContext } from "../../context/context";
import PanigationAntStyled from "../../components/layout/PanigationAntStyled";
const { RangePicker } = DatePicker;
const ActionHistories = () => {
  const [dataSource, setDataSource] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    q: undefined,
    created_by_user: undefined,
    from_date: undefined,
    to_date: undefined,
    page: 1,
    limit: 10,
  });

  const getUser = async () => {
    try {
      let { data } = await api.get(`${API_URL}api/v1/users`, {
        status: "ACTIVE",
      });
      setUsers(
        data.map((x) => {
          return {
            value: x?.id,
            label: x?.name,
          };
        })
      );
    } catch (e) {
      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      let data = await api.get(`${API_URL}api/v1/action-histories`, {
        ...filter,
      });
      setTotal(data?.metadata[0]?.totalItems || 0);
      setDataSource(data?.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.statusCode === 401) {
        try {
          let { data } = await axios({
            method: "POST", //you can set what request you want to be
            url: `${API_URL}api/v1/auth/refresh-token`,
            headers: {
              Authorization: "Bearer " + localGetRefreshToken(),
            },
          });

          localSaveToken(data?.data?.accessToken);
          localSaveRefreshToken(data?.data?.refreshToken);
          getData();
        } catch (e) {
          navigate("/login");
          notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
          localRemoveRefreshToken();
          localRemoveToken();
          localRemoveMenu();
        }
      } else if (e.statusCode === 403) {
        navigate("/login");
        notifyError("Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
        localRemoveRefreshToken();
        localRemoveToken();
        localRemoveMenu();
      } else {
        if (e.statusCode < 500) {
          notifyWarning(e.message);
        } else {
          notifyError("Chức năng đang bảo trì vui lòng thử lại sau.");
        }
        setLoading(false);
      }
    }
  };

  const handleFinishForm = (e) => {
    setFilter({
      ...filter,
      page: 1,
      created_by_user: e?.user,
      q: e?.q?.length > 0 ? e?.q?.trim() : undefined,
      from_date: e?.date ? e.date[0].format("YYYY-MM-DD") : undefined,
      to_date: e?.date ? e.date[1].format("YYYY-MM-DD") : undefined,
    });
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13 || event.key == "Enter") {
      form.submit();
    }
  };

  const onChangePaging = (page, pageSize) => {
    setFilter({
      ...filter,

      page: page,
      limit: pageSize,
    });
  };

  const { state } = useContext(UserContext);

  const [pageMenu, setPageMenu] = useState({
    list: false,
    details: false,
    create: false,
    update: false,
    assign: false,
    sync: false,
    import: false,
    export: false,
  });
  useEffect(() => {
    let localMenu = JSON.parse(localGetMenu());
    let fakePageMenu = { ...pageMenu };
    let fakeNewMenu = localMenu || [];
    let checkAll = fakeNewMenu.find((x) => x?.fe_route === "/action-histories");
    if (!checkAll) {
      navigate("/");
    } else {
      let checkView = checkAll?.permissions?.find(
        (y) => y?.permission === "VIEW_LIST"
      );
      if (checkView?.status === "INACTIVE") {
        navigate("/");
      } else {
        fakePageMenu = { ...fakePageMenu, list: true };
      }

      fakePageMenu = {
        ...fakePageMenu,
        create:
          checkAll?.permissions?.find((y) => y?.permission === "CREATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        update:
          checkAll?.permissions?.find((y) => y?.permission === "UPDATE")
            ?.status === "ACTIVE"
            ? true
            : false,
        details:
          checkAll?.permissions?.find((y) => y?.permission === "VIEW_DETAIL")
            ?.status === "ACTIVE"
            ? true
            : false,
        assign:
          checkAll?.permissions?.find((y) => y?.permission === "ASSIGN")
            ?.status === "ACTIVE"
            ? true
            : false,
        sync:
          checkAll?.permissions?.find((y) => y?.permission === "SYNC")
            ?.status === "ACTIVE"
            ? true
            : false,
        import:
          checkAll?.permissions?.find((y) => y?.permission === "IMPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
        export:
          checkAll?.permissions?.find((y) => y?.permission === "EXPORT")
            ?.status === "ACTIVE"
            ? true
            : false,
      };

      setPageMenu(fakePageMenu);
    }
  }, [state]);
  useEffect(() => {
    getData();
  }, [filter]);
  useEffect(() => {
    getUser();
  }, []);

  return (
    <div
      className="userSystem"
      onKeyDown={handleKeyUp}
      tabIndex="0"
      style={{ outline: "none" }}
    >
      <OverlaySpinner text="Vui lòng đợi ..." open={loading} />
      <Form
        className="userSystem__search"
        layout="vertical"
        form={form}
        onFinish={handleFinishForm}
      >
        <Form.Item
          label="Tìm kiếm"
          name="q"
          className="userSystem__search__input"
          style={{
            width: "calc(100% - 714px)",
          }}
        >
          <Input placeholder="Nhập thao tác" />
        </Form.Item>
        <Form.Item
          label="Người thực hiện"
          name="user"
          className="userSystem__search__date"
          style={{ width: "200px" }}
        >
          <Select
            allowClear
            options={users}
            placeholder="Tất cả"
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          label="Ngày thao tác"
          name="date"
          className="userSystem__search__date"
          style={{ width: "250px" }}
        >
          <RangePicker />
        </Form.Item>

        <Button
          className="userSystem__search__button"
          onClick={() => form.submit()}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AiOutlineSearch size={20} />
          &nbsp;Tìm kiếm
        </Button>
        <Button
          className="clients__search__button"
          onClick={() => {
            form.resetFields();
            setFilter({
              ...filter,
              q: undefined,
              created_by_user: undefined,
              from_date: undefined,
              to_date: undefined,
              page: 1,
              limit: 10,
            });
          }}
        >
          <AiOutlineReload size={20} />
          &nbsp;Refresh
        </Button>
      </Form>
      <Table
        bordered
        className="newpadding"
        rowKey="id"
        columns={columnsData()}
        dataSource={dataSource}
        pagination={false}
      />
      <PanigationAntStyled
        style={{ padding: "0 0 24px 0" }}
        current={filter.page}
        pageSize={filter.limit}
        showSizeChanger
        onChange={onChangePaging}
        showTotal={() => `Tổng ${total}`}
        total={total}
      />
    </div>
  );
};

export default ActionHistories;
